import { UserService } from 'src/app/core/user.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CheckinService } from '../core/checkin.service';
import { CheckInHistory } from '../data/checkin-history';


/**
 * 查詢我的報到紀錄
 * 帶過來的參數如下：
 * https://checkin.tp.edu.tw:4200/mobile?
 *  school_type=%E9%AB%98%E4%B8%AD
 *  &school_code=000000
 *  &role_type=parent
 *  &uuid=a3eca13a-d26f-1038-822e-7b37af40d5d8
 *  &access_token=f96d40c2c63e48dfa35b6231169df31c
 *
 * 要以 role_type 判斷是家長或學生
 * 如果是學生，則 uuid 代表學生本人，直接呼叫即可。
 * 如果是家長，則 要呼叫 userInfo 取得小孩清單。
 * uuid 參數代表目前畫面上選擇的小孩。此參數只有家長才有。
 */
@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit {

  currentUrl = '';

  currentSchoolCode: string;
  currentSchoolType: string;
  currentRoleType: string;
  currentUUID: string;
  currentAccessToken: string;

  currentHistory: CheckInHistory = {} as CheckInHistory;

  currentUserInfo: any = {};

  histories: CheckInHistory[] = [];

  currentMode: 'list' | 'detail' = 'list';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private checkinService: CheckinService,
    private userService: UserService
  ) { }

  async ngOnInit(): Promise<void> {
    // // console.log(this.router.url);
    // this.currentUrl = this.router.url;
    // this.router.events.subscribe(event => {
    //   // console.log(event);
    //   if (event instanceof NavigationEnd) {
    //     console.log(event);
    //     this.currentUrl = event.url;
    //   }
    // }
    // );

    // const { school_type, school_code, role_type, uuid, access_token } = this.route.snapshot.queryParams;
    // this.currentSchoolCode = school_code;
    // this.currentSchoolType = school_type;
    // this.currentRoleType = role_type;
    // this.currentUUID = uuid;
    // this.currentAccessToken = access_token;
    // console.log(this.currentSchoolCode, this.currentSchoolType, this.currentRoleType, this.currentUUID);


    // // 如果是從 mobile webview 開啟
    // if (this.currentAccessToken) {
    //     await this.reloadCheckinHistoriesByAccessToken();
    //     this.converRoleText();
    // } else {
    //   await this.reloadMyCheckinHistories();
    // }
  }


  // /** 讀取指定 access token 的使用的報到紀錄 */
  // async reloadCheckinHistoriesByAccessToken() {
  //   try {
  //     this.histories = await this.checkinService.getCheckinHistory(this.currentAccessToken).toPromise();
  //     this.converRoleText();
  //   } catch (error) {

  //   }
  // }

  // converRoleText() {
  //   this.histories.forEach(his => {
  //     his.role_type_text = this.userService.convertRoleText(his.role_type);
  //   });
  // }

  // /** 讀取指定 access token 的使用的報到紀錄 */
  // async reloadMyCheckinHistories() {
  //   try {
  //     this.histories = await this.checkinService.getMyCheckinHistory().toPromise();
  //   } catch (error) {
  //     // 尚未登入，顯示訊息後，轉到登入頁面
  //     window.alert(error.error.error);
  //     window.location.href = '/auth/login_cooc';  // 不能使用 this.router.navigateByUrl[]
  //   }
  // }

  // showScanner() {
  //   console.log('open scanner!');
  //   this.router.navigateByUrl('https://tp-cooc-url-command.web.app/commands/qrcode.html');
  // }

  // showHistory(his: CheckInHistory) {
  //   console.log(his);
  //   this.currentMode = 'detail';
  //   this.currentHistory = his;
  // }

  // back() {
  //   this.currentMode = 'list';
  // }

  // convertRoleType(role_type: string) {
  //   if (role_type === 'teacher') { return '教師'; }
  //   if (role_type === 'parent') { return '家長'; }
  //   if (role_type === 'student') { return '學生'; }
  // }

}
