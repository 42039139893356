import { INSCService } from './../../core/insc';
import { ActivityRecord, ActivityPeriod } from './../../core/activity.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActivityService } from 'src/app/core/activity.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as moment from 'moment';

@Component({
  selector: 'app-upload-insc',
  templateUrl: './upload-insc.component.html',
  styleUrls: ['./upload-insc.component.scss']
})
export class UploadInscComponent implements OnInit {

  currentActivity: ActivityRecord ;
  currentDate: string ;
  currentPeriod: ActivityPeriod ;
  currentWeekday: string;
  inscCourseCode = '';

  teachers = [];
  isLoading = false ;
  lastUploadRec;

  currentIndex = 0;
  failCount = 0;

  isShowError = false ;
  isWrongCourseNo = false ;
  errorMsg = '';
  errorTeachers = '';

  constructor(
    private actService: ActivityService,
    private modalRef: BsModalRef,
    private snackBar: MatSnackBar,
    private inscService: INSCService
  ) { }

  ngOnInit(): void {

    // 只有處室建立的活動才能上傳教研網
    const tempDate = moment(this.currentPeriod.act_date_string);
    this.currentPeriod.weekday_string = this.actService.getWeekdayString(tempDate.day());

    this.refreshUploadInfo().then();
  }

  /** 取得最後一次上傳資訊 */
  async refreshUploadInfo() {
    this.isShowError = false ;
    this.lastUploadRec = await this.inscService.lastRec(this.currentActivity.act_uuid).toPromise();
  }

  // 開始上傳
  async uploadLearnRecords() {

    this.isShowError = false ;

    if (!this.currentPeriod.insc_code) {
      this.snackBar.open(`提醒`, `請輸入研習編號。`, {
        duration: 2000,
      });
      return ;
    }

    this.isLoading = true ;

    // 0. 查詢此活動有獲得時數的教師人數
    const teacherCountRec =  await this.inscService.teacherCount(this.currentActivity.act_uuid, this.currentPeriod.id).toPromise();
    if (teacherCountRec.issued_teacher_count < 1) {
      this.snackBar.open(`提醒`, `尚未有教師取得研習時數，上傳取消。`, {
        duration: 2000,
      });
      this.isLoading = false ;
      return ;
    }

    // 1. 建立上傳紀錄，並回傳 batch_uuid, 與 teachers
    const batch = await this.inscService.createUploadBatch(this.currentActivity.act_uuid,
                                                            this.currentPeriod.insc_code, this.currentPeriod.id).toPromise();
    const batch_uuid = batch.batch_uuid ;
    this.teachers = batch.teachers ;

    // 2. 對於每一筆，一筆一筆送出去
    this.currentIndex = 0;
    this.failCount = 0;
    for (const tea of this.teachers) {
      tea.insc_code = this.currentPeriod.insc_code ;
      this.currentIndex += 1;
      try {
        const contents = await this.inscService.send(batch_uuid, tea).toPromise();
      } catch (err) {
        this.failCount += 1;
      }
    }

    await this.refreshUploadInfo();

    this.isLoading = false ;
  }

  showFailLogs() {
    const errMsgs = [];
    const dicErrMsgs = {};
    this.isShowError = !this.isShowError ;

    this.lastUploadRec.fail_logs.forEach(log => {
      if (!dicErrMsgs[log.error]) {
        dicErrMsgs[log.error] = [];
        errMsgs.push(log.error);
      }
      dicErrMsgs[log.error].push(log.name);
    });

    if (errMsgs.length > 0) {
      if (errMsgs[0] === '課程編號錯誤，找不到相對應之課程') {
        this.isWrongCourseNo = true;
        this.errorTeachers = '';
      } else {
        this.isWrongCourseNo = false;
        this.errorTeachers = dicErrMsgs[errMsgs[0]].join(', ') ;
      }
    }
  }

  closeDialog() {
    this.modalRef.hide();
  }

}
