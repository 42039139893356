import { PeriodHoursComponent } from './../period-hours/period-hours.component';
import { ActivityNotificationServiceService } from './../activity-notification-service.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivityPeriod, ActivityRecord, ActivityService, PeriodCheckinStatistics } from 'src/app/core/activity.service';
import { ActStateService } from '../act-state.service';
import { AddActivityComponent } from '../add-activity/add-activity.component';
import { filter, take } from 'rxjs/operators';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngxs/store';
import { UserRoleRec } from 'src/app/data';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  currentActivity: ActivityRecord = {} as ActivityRecord;
  // ActivityService: ActivityService;

  start_date: Date;
  start_time: Date;
  end_time: Date;
  act_date_string: string;

  subscription: Subscription;
  actID: number;

  delActUUID: string;

  modalRef: BsModalRef;
  today = new Date();
  acted = false;
  canBeDeleted = false;  // 此活動能否刪除？
  actTime: Date;

  titleMsg = '';   // 按鈕的 tooltip 文字

  showCheckInStatusButton = true;
  showImportNameListButton = true;

  periods: ActivityPeriod[] = [];
  dicPeriodStatistics: { [checkinDate: string]: PeriodCheckinStatistics } = {};

  refreshKey: number = (new Date()).getMilliseconds() ;

  @ViewChild('actCanNotDel')
  tpl: TemplateRef<any>;

  roleSubscription: Subscription ;
  curRole: UserRoleRec = {} as UserRoleRec;

  constructor(
    private modal: BsModalService,
    private actStateSrv: ActStateService,
    private activityService: ActivityService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private bsModalSrv: BsModalService,
    private router: Router,
    private activityNotificationService: ActivityNotificationServiceService,
    private store: Store
  ) {
    // this.activityNotificationService.emitCheckInTabStatus(false);   // 先隱藏上層的頁籤
    // this.activityNotificationService.emitImportListTabStatus(false);   // 先隱藏上層的頁籤
  }

  ngOnInit() {
    console.log('info.component, ng init ......');
    this.subscription = this.route.params.subscribe(async params => {
      this.actID = params.id;
      console.log( ` actID: ${this.actID}`);
      this.refreshActivity(this.actID);
    });

    // 當使用者切換角色時
    this.roleSubscription = this.store.subscribe(x => {
      // 根據角色取得活動清單
      this.curRole = x.role;
      // console.log( {curRole: this.curRole });
    });
  }

  async refreshActivity(actID) {
    // await this.refreshActivityPeriods();
    // await this.refreshPeriodStatistics();
    this.currentActivity = await this.activityService.getActByID(this.actID).toPromise();
    console.log("取得當前活動資料:", this.currentActivity);
    this.actStateSrv.setCurrentAct(this.currentActivity);
    this.currentActivity.act_date_string = moment(this.currentActivity.act_date).format('YYYY/MM/DD');
    // this.canBeDeleted = (await this.activityService.canBeDeleted(this.currentActivity.act_uuid).toPromise()).canBeDeleted;

    // this.actTime = new Date(this.currentActivity.act_date_string + ' ' + this.currentActivity.start_time);
    this.actTime = new Date(this.currentActivity.act_date_string + ' 23:59');

    // 判斷是否能編輯：
    // 1. 如有畫面設定的 meta data，
    // if (this.currentActivity.act_periods_meta) {
    // 2. 才進行判斷是否已經過期
    if (!this.currentActivity.is_repeat) {
      this.acted = (this.today < this.actTime);
      console.log("時間",{ actTime: this.actTime, now: this.today });
    } else {
      const deadline = moment(this.currentActivity.repeat_end_date).add(1, 'd');
      this.acted = (moment(this.today) < deadline);
    }
    if (!this.acted) {
      this.titleMsg = '此活動已結束，不能修改';
    }

    // } else {
    //   this.titleMsg = '沒有畫面設定參數，不能修改';
    // }
  }

  // 修改活動
  editActivity(editorMode: string, id: number) {
    this.modal.show(AddActivityComponent, {
      id: 1,
      initialState: { editorMode, id, curRole: this.curRole, is_from_api: this.currentActivity.is_from_api },
    });

    this.modal.onHide.pipe(take(1), filter(reason => reason === 'data edit OK')).subscribe(() => {
      console.log('data saved !');
      this.refreshActivity(id).then(() => {
        this.activityNotificationService.emitActivityStatus(this.currentActivity);  // 觸發事件，讓上層可以修改活動名稱
      });
      this.refreshKey = (new Date()).getMilliseconds();
    });
  }

  // 開啟刪除確認對話視窗
  async openConfirmForm(templateRef: TemplateRef<any>, act: ActivityRecord) {
    this.delActUUID = act.act_uuid;
    this.canBeDeleted = (await this.activityService.canBeDeleted(this.currentActivity.act_uuid).toPromise()).canBeDeleted;
    // 1. 判斷是否已有人報到，如果有，就提示已有人報到，不能刪除。
    if (!this.canBeDeleted) {
      this.snackBar.open(`發生錯誤`, `此活動已有報到紀錄，不能刪除。`, {
        duration: 2000,
      });
    } else {
      // 如果沒有人報到，才跳出確認視窗。
      this.modalRef = this.bsModalSrv.show(templateRef);
    }
  }

  // 刪除活動
  async delAct() {
    try {
      console.log(this.delActUUID);
      const checkInCount = await this.activityService.getCheckInCount(this.delActUUID).toPromise();
      console.log(checkInCount);
      if (checkInCount.checkInCount > 0) {
        this.modalRef = this.bsModalSrv.show(this.tpl);
        // window.alert('已經有人報到，無法刪除');
        return;
      }
      await this.activityService.deleteAct(this.delActUUID).toPromise();
      // await this.getActList();
      this.modalRef.hide();
      this.router.navigate(['../../'], { relativeTo: this.route });

    } catch (error) {
      const msg = '';
      this.snackBar.open(`發生錯誤`, msg, {
        duration: 2000,
      });
    }
  }

  // 分場時數設定
  async editPeriod() {
    this.modal.show(PeriodHoursComponent, {
      id: 2,
      initialState: { actID: this.actID },
    });

    // this.modal.onHide.pipe(take(1), filter(reason => reason === 'data edit OK')).subscribe(() => {
    //   console.log('data saved !');
    //   this.activityNotificationService.emitActivityPeriodsStatus(this.actID) ;  // 觸發節次已被修改的事件，要更新下方另一個元件裡的節次清單
    // });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // 顯示匯入報名名單的功能
  showImportList(act: ActivityRecord) {
    this.activityNotificationService.emitImportListTabStatus(true);   // 顯示上層的頁籤
    // this.showImportNameListButton = false ;
    this.actStateSrv.setCurrentAct(act);
    this.router.navigate(['../../activity', act.id, 'import'], { relativeTo: this.route });
  }

  showCheckInStatus(act: ActivityRecord) {
    this.activityNotificationService.emitCheckInTabStatus(true);   // 顯示上層的頁籤
    // this.showCheckInStatusButton = false ;
    this.actStateSrv.setCurrentAct(act);
    this.actStateSrv.setPeriod(undefined);
    this.router.navigate(['../../activity', act.id, 'checkin_status'], { relativeTo: this.route });
  }

  showCheckInStatus2(pd) {
    console.log(pd);
    this.activityNotificationService.emitCheckInTabStatus(true);   // 各場次的日期查看
    this.actStateSrv.setPeriod(pd); // 設定要顯示的日期。
    this.router.navigate(['../../activity', pd.ref_activity_id, 'checkin_status'], { relativeTo: this.route });
  }



  // async refreshActivityPeriods() {
  //   const tempPeriods = await this.activityService.getActPeriodsByActID(this.actID).toPromise();
  //   tempPeriods.forEach( pd => {
  //     const tempDate = moment(pd.act_date_string);
  //     pd.weekday_string = this.getWeekdayString(tempDate.day());
  //     pd.act_date_mmdd = moment(pd.act_date_string).format('MM/DD');
  //   });
  //   this.periods = tempPeriods ;
  // }

  // async refreshPeriodStatistics() {
  //   const act = this.actStateSrv.getCurrentAct();
  //   const periodStatistics = await this.activityService.getCheckinStatusByPeriod(act.act_uuid).toPromise();
  //   this.dicPeriodStatistics = {};
  //   periodStatistics.forEach( pd => {
  //     this.dicPeriodStatistics[pd.checkin_date] = pd ;
  //   });
  // }


  // getWeekdayString(weekDay: number) {
  //   let result = '' ;
  //   if (weekDay === 0) { result = '日'; }
  //   if (weekDay === 1) { result = '一'; }
  //   if (weekDay === 2) { result = '二'; }
  //   if (weekDay === 3) { result = '三'; }
  //   if (weekDay === 4) { result = '四'; }
  //   if (weekDay === 5) { result = '五'; }
  //   if (weekDay === 6) { result = '六'; }
  //   return result ;
  // }

  // async savePeriods() {
  //   try {
  //     await this.activityService.setActPeriods(this.actID.toString(), this.periods).toPromise();
  //     // this.modalService.setDismissReason('data saved');
  //     this.modalRef.hide();
  //   }
  //   catch (error) {
  //     const msg = '修改場次資訊時發生錯誤';
  //     this.snackBar.open(`修改場次資訊時發生錯誤`, msg, {
  //       duration: 2000,
  //     });
  //   }
  //   this.refreshActivity(this.actID);
  // }




}
