<div class="d-flex flex-column flex-md-row justify-content-between">
    <button mat-button style="margin-bottom: -20px" (click)="this.back.emit(null);">
      <mat-icon style="padding-top: 4px">keyboard_backspace</mat-icon>
      取消
    </button>
    <div class="mt-2 d-flex flex-row align-items-end">
        <span>總計：{{ this.matchedData.length + this.unmatchedData.length }} 筆
        ,</span
      >
      <span style="color: green" class="ml-2">
        比對中介成功：{{ this.matchedData.length }} 筆 ,</span
      >
      <span style="color: red" class="ml-2">
        失敗：{{ this.unmatchedData.length }} 筆</span
      >
    </div>
    <!-- <div class="p-2 d-flex flex-row justify-content-center align-items-center" > -->
    <button
      mat-raised-button
      color="primary"
      (click)="startImport()"
      class="px-2 py-1"
      [disabled]="this.matchedData.length === 0" >
      開始匯入
    </button>
    <!-- </div> -->
  </div>


<table
  class="table-rwd table table-bordered table-striped my-3 text-md-center"
  style="width: 100%"
>
  <thead>
    <tr>
      <th style="width: 15%">類型</th>
      <th style="width: 15%">小孩姓名</th>
      <th>小孩學號</th>
      <th>小孩班級</th>
      <th>比對</th>
      <th>匯入結果</th>
    </tr>
  </thead>
  <tbody>
    <tr
      data-toggle="modal"
      data-backdrop="static"
      *ngFor="let data of unmatchedData"
    >
      <td data-th="類型" style="text-align: center">
        {{ data.role_type_text }}
      </td>
      <td data-th="小孩姓名">
        {{ data.name }}
      </td>
      <td data-th="小孩學號">
        {{ data.studNo }}
      </td>
      <td data-th="小孩班級"></td>
      <td data-th="比對">
        <span style="color: red">失敗</span>
        </td>
        <td data-th="匯入狀態">----</td>
        </tr>

        <tr data-toggle="modal" data-backdrop="static" *ngFor="let data of matchedData">
            <td data-th="類型" style="text-align: center">
                {{ data.role_type_text }}
            </td>
            <td data-th="小孩姓名">
                {{ data.name }}
            </td>
            <td data-th="小孩學號">
                {{ data.studNo }}
            </td>
            <td data-th="小孩班級">
                {{ !data.targetStud ? "" : data.targetStud.ClassName }}
            </td>
            <td data-th="比對">
                <span style="color: green"></span>
            </td>
            <td data-th="匯入狀態">
                <mat-icon *ngIf="data.isSaved">done</mat-icon>
                <span *ngIf="data.errMsg" style="color: red; font-size: 0.8rem">{{
          data.errMsg
        }}</span>
            </td>
        </tr>
        </tbody>
        </table>
