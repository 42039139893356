import { AP2IMDBTeacherInfo, ImdbService } from './../../core/imdb.service';
import { Component, Input, OnInit } from '@angular/core';
import { DeptBlackList, DeptInfo, PermissionService } from 'src/app/core/permission.service';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss']
})
export class OfficeComponent implements OnInit {

  // allDepts: string[] = [];
  blacklist: DeptBlackList = {} as DeptBlackList;

  // depts: DeptInfo[] = [];


  private _schoolCode: string;
  private _schoolType: string;
  allSchoolDepts: DeptInfo[] ;

  @Input()
  set schoolCode(schCode: string) {
    this._schoolCode = schCode ;
    // this.reloadData().then();
  }

  @Input()
  set schoolType(schType: string) {
    this._schoolType = schType ;
    // this.reloadData().then();
  }

  @Input()
  set schoolDepts(schoolDepts: DeptInfo[]) {
    this.allSchoolDepts = schoolDepts ;
    this.reloadData().then();
  }

  constructor(
    private permissionService: PermissionService
  ) { }

  async ngOnInit(): Promise<void> {
    // this.allDepts = await this.permissionService.getAllowedDepts(this._schoolCode, this._schoolType).toPromise();
    // await this.reloadData();
  }

  async reloadData() {
    if (this._schoolCode && this._schoolType) {
      this.blacklist = await this.permissionService.getDeptBlackList(this._schoolCode, this._schoolType).toPromise();

      this.parseDepts();
    }
  }




  parseDepts() {
    // this.depts = [];
    this.allSchoolDepts.forEach( dept => {
      // const di = new DeptInfo();
      // di.deptName = dept ;
      if (this.blacklist.dept_blacklist) {
        dept.selected = (this.blacklist.dept_blacklist.indexOf(dept.deptCode) === -1);
      } else  {
        dept.selected = true ;
      }
      // di.selected = (this.blacklist.dept_blacklist ? this.blacklist.dept_blacklist.indexOf(dept) === -1 : true) ;
      // this.depts.push(di);
    });
  }

  async setBlacklist() {
    const blacklist = [];
    this.allSchoolDepts.forEach(dept => {
      if (!dept.selected) {
        // console.log(dept);
        blacklist.push(dept.deptCode);
      }
    });
    try {
      await this.permissionService.setDeptBlackList(this._schoolCode, this._schoolType, blacklist).toPromise();
      alert('儲存成功');
      await this.reloadData();
    } catch (error) {

    }

  }
}


