import { UserService } from './../user.service';
import { ImdbService, IMDBStudentInfo } from './../imdb.service';
import { Observable } from 'rxjs';


export class OthersImporter {

  rawData: any ;
  matchedData = [];
  unmatchedData = [];
  schoolStudents: any;

  _schoolCode: string;
  _schoolType: string ;

  constructor(
    data: any,
    schoolCode: string,
    schoolType: string,
    private userService: UserService) {

    this.rawData = data ;
    this._schoolCode = schoolCode ;
    this._schoolType = schoolType ;
  }

  /** 校外人士，沒什麼好比對的，就全部匯入 */
  public match(): Observable<any> {

    const result = new Observable((observer) => {
      this.rawData.forEach(row => {
        row.name = row['姓名'];
        row.tel_no = row['手機號碼'] ;
        row.type = 'others'; // 類型是學生
        row.role_type_text = this.userService.convertRoleText(row.type);
        row.isSaved = false;
      });

      observer.next({
        matched_data: this.rawData ,  // 有比對到的學生資料
        unmatched_data: []  // 沒有比對到的學生資料
      });
    });
    return result ;
  }

}
