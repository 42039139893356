<div class="my-2">請勾選可以建立活動的處室：</div>
<div style="display: flex; flex-direction: row; flex-wrap: wrap" class="mb-4 mt-4">
  <div *ngFor="let dept of allSchoolDepts" style="width: 50%">
    <mat-checkbox [(ngModel)]="dept.selected">
      <span style="font-size: 1.3rem">{{ dept.deptName }}</span>
    </mat-checkbox>
  </div>
</div>
<button
  mat-raised-button
  color="primary"
  class="p-1 w-100 mt-4"
  style="font-size: 1.2rem"
  (click)="setBlacklist()"
>
  確定
</button>
