import { UserService } from './../user.service';
import { ImdbService, IMDBStudentInfo, IMDBTeacherInfo } from '../imdb.service';
import { Observable } from 'rxjs';


export class TeacherImporter {

  rawData: any ;
  matchedData = [];
  unmatchedData = [];
  schoolTeachers: any;

  _schoolCode: string;
  _schoolType: string ;

  constructor(
    data: any,
    schoolCode: string,
    schoolType: string,
    private imdbService: ImdbService,
    private userService: UserService) {

    this.rawData = data ;
    this._schoolCode = schoolCode ;
    this._schoolType = schoolType ;
  }

  /** 根據匯入教師姓名，比對中介裡的教師資料 */
  public match(): Observable<any> {

    const result = new Observable((observer) => {
      // 1. 讀取中介的學校學生資料
      this.imdbService.getSchoolTeachers(this._schoolCode, this._schoolType).subscribe( {
        next: (teachers) => {
          // console.log(teachers);
          this.schoolTeachers = teachers;
          // 2. 比對目前報名學生與學校資料，找出身分證號，班級等資料，也清出找不到的學生
          this.compareTeachers(teachers);
          // 3. 找到後，回傳學生資料
          observer.next({
            matched_data: this.matchedData ,  // 有比對到的學生資料
            unmatched_data: this.unmatchedData  // 沒有比對到的學生資料
          });
        },
        error: (error) => {
          // console.log(error) ;
          observer.error(error);
        }
      });

    });

    return result ;
  }

  /** 取得中介的學生資料 */
  public getSchoolTeachers() {
    return this.schoolTeachers ;
  }

  /** 根據匯入資料的姓名比對中介的學生資料 */
  private compareTeachers(teachers: IMDBTeacherInfo[]) {
    // 1.把所有中介教師放到 dictionary 中
    const dicTeachers: {[teacherName: string]: IMDBTeacherInfo} = {};
    teachers.forEach( tea => {
      if (!dicTeachers[tea.TeacherName]) {
        dicTeachers[tea.TeacherName] = tea ;
      }
    });
    // console.log(dicTeachers);

    // 2. 比對 Excel 中的學生資料
    this.matchedData = [];
    this.unmatchedData = [];
    this.rawData.forEach( row => {
      const teacherName = row['教師姓名'];
      // console.log(teacherName);
      const targetTea = dicTeachers[teacherName];
      // console.log(targetTea);
      row.name = row['教師姓名'];
      row.type = 'teacher'; // 類型是教師
      row.role_type_text = this.userService.convertRoleText(row.type); // 類型是教師
      row.isSaved = false;
      if (targetTea) {
        row.targetTea = targetTea;
        this.matchedData.push(row);
      } else {
        this.unmatchedData.push(row);
      }
    });
  }

}
