import { ActivityRecord } from './../core/activity.service';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs' ;

@Injectable({
  providedIn: 'root'
})
export class ActivityNotificationServiceService {

  emitCheckInTabStatusChange$: Subject<any> = new BehaviorSubject<any>(null);
  emitImportListTabStatusChange$: Subject<any> = new BehaviorSubject<any>(null);
  emitActivityStateChange$: Subject<ActivityRecord> = new BehaviorSubject<ActivityRecord>(null);
  emitActivityPeriodsStateChange$: Subject<number> = new BehaviorSubject<number>(null);

  constructor() { }

  /* 觸發報到狀況頁籤的狀態，要送給 parent */
  emitCheckInTabStatus(value: any) {
    this.emitCheckInTabStatusChange$.next(value);
  }

  get emitCheckInTabStatusChange(): BehaviorSubject<any> {
    return (this.emitCheckInTabStatusChange$ as BehaviorSubject<any>);
  }

  /* 觸發匯入報名名單頁籤的狀態，要送給 parent */
  emitImportListTabStatus(value: any) {
    this.emitImportListTabStatusChange$.next(value);
  }

  get emitImportListTabStatusChange(): BehaviorSubject<any> {
    return (this.emitImportListTabStatusChange$ as BehaviorSubject<any>);
  }

  /* 觸發匯入報名名單頁籤的狀態，要送給 parent */
  emitActivityStatus(value: ActivityRecord) {
    this.emitActivityStateChange$.next(value);
  }

  get emitActivityStatusChange(): BehaviorSubject<ActivityRecord> {
    return (this.emitActivityStateChange$ as BehaviorSubject<ActivityRecord>);
  }

   /* 觸發修改活動場次的事件 */
   emitActivityPeriodsStatus(value: number) {
    this.emitActivityPeriodsStateChange$.next(value);
  }

  get emitActivityPeriodsStatusChange(): BehaviorSubject<number> {
    return (this.emitActivityPeriodsStateChange$ as BehaviorSubject<number>);
  }
}
