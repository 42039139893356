import { IMDBUserInfo } from './imdb.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivityRecord } from './activity.service';

@Injectable({
  providedIn: 'root'
})
export class CheckInStatusService {

  constructor(
    private http: HttpClient
  ) {}

  public getManualCheckInRecords(actUuid: string, pdDate: string): Observable<IMDBUserInfo[]> {
    // return this.http.get(`/service/checkinstatus`).pipe(
    //   map(response => response as CheckInStatusService[])
    const url = `/service/checkinstatus/manual_checkin_recs?act_uuid=${actUuid}&pd_date=${pdDate}`;
    return this.http.get(url).pipe(
      map(response => response as IMDBUserInfo[])
      );
  }

  public createDownloadFile( data , role, startPeriod: string, endPeriod): Observable<any> {
    const url = `/service/checkinstatus/create_file?start_period=${startPeriod}&end_period=${endPeriod}&role=${role}`;
    return this.http.post(url, data).pipe(
      map(response => response as any)
    );
  }

  /** 重新計算時數 */
  public reCalcHours( actUuid: string): Observable<any> {
    const url = `/service/checkinstatus/recalc_issued_hours?act_uuid=${actUuid}`;
    return this.http.get(url).pipe(
      map(response => response as any)
    );
  }

  /** 重新計算時數 */
  public getLastReCalcTime( actUuid: string): Observable<any> {
    const url = `/service/checkinstatus/get_last_recalc_time?act_uuid=${actUuid}`;
    return this.http.get(url).pipe(
      map(response => response as any)
    );
  }

  // public getCheckinList(act: ActivityRecord): Observable<any> {
  //   return this.http.get(`/service/activity/checkinList/${act.id}`);
  // }

}
// export class CheckInStatusRecord {
// id: number ;
// act_uuid: string;
// school_code: string;
// school_type: string;
// school_name: string;
// role: string;
// other_info: string;
// checkin_time: Date;
// checkin_time_string: string;
// signout_time: Date;
// signout_time_string: string;
// name: string;
// id_no: string;
// checkin_type: string;
// tel_no: string;
// }
