import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImdbService {

  constructor(
    private http: HttpClient
  ) { }

  /** 取得中介老師身分 */
  getTeacherInfo(): Observable<TeacherRoleInfo[]> {
    return this.http.get('/service/imdb/get_teacher_info').pipe(
      map(response => response as TeacherRoleInfo[])
    );
  }

  getSchoolTeachers(schoolCode: string, schoolType: string): Observable<IMDBTeacherInfo[]> {
    return this.http.get(`/service/imdb/getSchoolTeachers?school_code=${schoolCode}&school_type=${schoolType}`).pipe(
      map(response => response as IMDBTeacherInfo[])
    );
  }

  getSchoolTeachersFromAP2(schoolCode: string, schoolType: string): Observable<AP2IMDBTeacherInfo[]> {
    return this.http.get(`/service/imdb/getSchoolTeachersFromAP2?school_code=${schoolCode}&school_type=${schoolType}`).pipe(
      map(response => response as AP2IMDBTeacherInfo[])
    );
  }

  getSchoolStudents(schoolCode: string, schoolType: string): Observable<IMDBStudentInfo[]> {
    return this.http.get(`/service/imdb/getSchoolStudents?school_code=${schoolCode}&school_type=${schoolType}`).pipe(
      map(response => response as IMDBStudentInfo[])
    );
  }

  getByIdNumber(idNo: string, actUuid: string, periodDate: string): Observable<IMDBUserInfo[]> {
    return this.http.get(`/service/imdb/getByIdNumber?id_no=${idNo}&act_uuid=${actUuid}&pd_date=${periodDate}`).pipe(
      map(response => response as IMDBUserInfo[])
    );
  }
}

export interface TeacherRoleInfo {
  schoolName: string;
  schoolCode: string;
  schoolType: string;
  isAdmin: boolean;
  isAgent: boolean;
  roleList: DeptRoleInfo[];
}

export interface DeptRoleInfo {
  deptID: string;
  dept: string;
  title: string;
  role: string;
}

/** 巨耀中介的 */
export interface AP2IMDBTeacherInfo {
  DepartmentCode: string;
  SchoolCode: string;
  UnitCode: string;
  Department: string;
  SchoolType: string;
  TitleCode: string;
  UpdateTime: string;
  Title: string;
  TeacherUUID: string;
  Unit: string;
  TeacherId: number;
}

export interface IMDBTeacherInfo {
  SchoolName: string ;
  SchoolType: string ;
  TeacherID: number ;
  TeacherName: string ;
  Gender: string ;
  SourceIndex: string ;
  Position: TitleInfo[];
  Identity: string ;
  isAdminAgent: boolean;  // 是否是副管理員，在前端運算用
  roleString: string;     // 角色字串，前端運算用
}

export interface TitleInfo {
  Dept: string;
  Title: string;
}

export interface IMDBStudentInfo {
  SchoolName: string ;
  SchoolType: string ;
  SchoolCode: string ;
  ClassName: string;
  ClassNo: string;
  GradeYear: number;
  StudentID: string;
  StudentNumber: string;
  StudentName: string;
  SeatNo: number;
  Gender: string;
  Identity: string;
}

/* 局端人工報到使用 */
export interface IMDBUserInfo {
  SchoolName: string ;
  SchoolType: string ;
  SchoolCode: string ;
  ClassName: string;  // role === 'student' 才有
  ClassNo: string;  // role === 'student' 才有
  GradeYear: number;  // role === 'student' 才有
  StudentNumber: string;  // role === 'student' 才有
  StudentName: string;  // role === 'student' 才有, 前端協助。
  Name: string;
  SeatNo: number;  // role === 'student' 才有
  Gender: string;
  Identity: string;
  role: string;
  roleText: string ;
  checkin_time: string;
  checkin_type: string;
  checkin_id: string;
  isCheckIn: boolean ;
}
