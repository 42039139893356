import { SignupService } from 'src/app/core/signup.service';
import { SignupRecord } from './../../core/signup.service';
import { ActivityPeriod, ActivityService, triggerQuery } from './../../core/activity.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ActivityRecord } from 'src/app/core/activity.service';
import { CheckinService } from 'src/app/core/checkin.service';
import { UserService } from 'src/app/core/user.service';
import * as moment from 'moment';
import { ThrowStmt } from '@angular/compiler';
import { textChangeRangeIsUnchanged } from 'typescript';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.scss']
})
export class CheckinComponent implements OnInit {

  currentUrl = '';
  actUuid = '';
  currentAction: '報到' | '簽退' = '報到';

  currentSchoolCode: string;
  currentSchoolType: string;
  currentRoleType: string;
  currentUUID: string;
  currentAccessToken: string;

  currentUserInfo: any = {};
  userRoles: UserRoleInfo[] = [];
  targetRole: UserRoleInfo = {} as UserRoleInfo;
  currentActivity: ActivityRecord = {} as ActivityRecord;
  triggerData: triggerQuery;

  errMsgTitle = '';
  errMsg = '';
  showRoleChooser = true ;    // 是否顯示切換角色的下拉式方塊
  mealType: '葷' | '素' | undefined;
  isLoadingPage = true;  // 是否正在載入頁面中

  finishCheckedIn = false;   // 已經報到完成。(報到完成當下，此變數為 true)
  hadCheckedIn = false;   // 曾經報到過了。 (如果已經報到過了，則把此變數變為 true)

  finishSignout = false;   // 已經完成簽退。(簽退完成當下，此變數為 true)
  hadSignout = false;   // 曾經簽退過了。 (如果已經簽退過了，則把此變數變為 true)

  canChangeRole = true; // 可否選身份

  checkInRec: any ;
  serverTime: any ;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private checkinService: CheckinService,
    private singupService: SignupService,
    private activityService: ActivityService,
    private userService: UserService,
    private http: HttpClient
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentUrl = this.router.url;
    this.parseAction();

    this.router.events.subscribe(event => {
      // console.log(event);
      if (event instanceof NavigationEnd) {
        console.log(event);
        this.currentUrl = event.url;
        console.log(this.currentUrl);
        this.parseAction();
        console.log(this.currentAction);
      }
    });

    const { school_type, school_code, role_type, uuid, access_token } = this.route.snapshot.queryParams;
    this.currentSchoolCode = school_code;
    this.currentSchoolType = school_type;
    this.currentRoleType = role_type;
    this.currentUUID = uuid;
    this.currentAccessToken = access_token;
    // console.log(this.currentSchoolCode, this.currentSchoolType, this.currentRoleType, this.currentUUID);
    // console.log( { school_type, school_code, role_type, uuid, access_token });
    try {
      this.currentUserInfo = await this.userService.getUserInfo(this.currentAccessToken).toPromise();
      console.log({ currentUserInfo : this.currentUserInfo });
      this.parseUserRoles();

      // 因應新的 app_links 規格，所以 act_uuid 從 /:params 改成 query string
      this.route.queryParams.subscribe(params => {
        // console.log(params);
        this.actUuid = params.act_uuid;
        this.activityService.getActByUUID(this.actUuid).toPromise().then(async act => {
          this.currentActivity = act;
          try {
            // 這裡還要判斷是否過期, 以及是否限制校內報名。。。
            await this.parseActivity();
          } catch (ex) {
            console.log(ex);
            if (typeof ex === 'string') {
              const errs = ex.split(' ');
              if (errs.length > 1) {
                this.errMsgTitle = errs[0];
                this.errMsg = errs[1];
              } else {
                this.errMsgTitle = '';
                this.errMsg = errs[0];
              }
            } else {
              this.errMsg = ex;
            }
          } finally {
            this.isLoadingPage = false ;  // 頁面載入完成
          }

        })
          .catch(error => {
            // console.log('some error ....');
            console.log(error);
            this.errMsgTitle = '';
            this.errMsg = `找不到符合的活動: ${this.actUuid} `;
            this.showRoleChooser = false ;  // 活動不對，也不用顯示切換身份了。
            this.isLoadingPage = false ;  // 頁面載入完成
          });
      });

    } catch (error) {
      this.errMsgTitle = '登入失敗';
      this.errMsg = '無法取得身分資料！';
      this.showRoleChooser = false ;  // access token 不對，也不用顯示切換身份了。
      this.isLoadingPage = false ;  // 頁面載入完成
    }
  }

  /** 如果是簽退，就不影響，
   * 但如果是報到，就要解析看目前是否在報到時間內？
   * // TODO
   */
  async parseActivity() {
    //  1.0 取得 server time
    const serverTimeString = await this.checkinService.getServerTime().toPromise();
    this.serverTime = moment(serverTimeString.server_time).format('MM/DD HH:mm');

    // 1.1 取得報到(簽退)紀錄
    const checkinRecs = await this.checkinService.findCheckInRecord(this.actUuid, this.currentAccessToken,
      this.targetRole.schoolCode, this.targetRole.schoolType, this.targetRole.roleType,
      this.targetRole.studNumber, undefined).toPromise() ;

    if (this.currentAction === '報到') {

      // 1.2 檢查時間是否在可報到區間
      try {
        const isRightTime = await this.checkinService.isRightTimeToCheckin(this.actUuid).toPromise();   // 由 server 檢查時間
        if (isRightTime.error) {
          throw isRightTime.error.error;
        }
      } catch (ex) {
        console.log( { error : ex });
        this.showRoleChooser = false ;  // 報到時間不對，也不用顯示切換身份了。
        throw ex.error.error;
      }

      // 1.3 檢查是否報到過了？
      if (checkinRecs.length > 0) { // 如果已經報到過了！
        this.checkInRec = checkinRecs[0];
        this.hadCheckedIn = true ;
        return ;
      }

      // 1.3 檢查是否開放現場報名
      if (!this.currentActivity.scene_registration) {
        let hasSignedup = false;
        // 檢查是否預先報名
        const regRecords = await this.singupService.getMyActRegRecords(
          this.actUuid, this.currentAccessToken, this.currentRoleType
        ).toPromise();
        regRecords.forEach((regRec) => {
          if (regRec.school_code === this.currentSchoolCode) {
            // 如果是家長，還要判斷是否是這位小孩
            if (this.currentRoleType === 'parent') {
              hasSignedup = (regRec.stud_no === this.targetRole.studNumber);
            } else {
              hasSignedup = true;
            }
          }
        });

        if (!hasSignedup) {
          // this.errMsgTitle = '未預先報名';
          // this.errMsg = '本活動不開放現場掃碼報到';
          this.showRoleChooser = false ;  // 沒有預先報名，也不用選身份了。
          throw '未預先報名 本活動不開放現場掃碼報到';
        }
      }

      // 1.4 檢查是否僅限校內報到
      if (this.currentActivity.limited_my_school) {
        if (this.currentActivity.school_code !== this.targetRole.schoolCode) {
          //window.alert(`很抱歉，本活動僅限 ${this.currentActivity.school_name} ${this.currentActivity.school_type} 參加！`);
          //return;
          throw '他校人員不得參加 非所屬單位辦理之研習';
        }
      }

      // 1.5 角色是否正確
      // console.log({ targetRole : this.targetRole , role_permitted: this.currentActivity.role_permitted });
      if (!this.currentActivity.role_permitted[this.targetRole.roleType]) {
        throw (`報到身分資格不符 本活動不開放${this.targetRole.roleTypeText}參加`);
      }

      // 如果有提供餐食，預設是葷食。
      if (this.currentActivity.has_meal) {
        this.mealType = '葷';
      }
    }
    else if (this.currentAction === '簽退') {

      try {
        // 1.1 檢查是否曾簽退過了
        if (checkinRecs.length > 0) { // 如果已經報到過了！
          // 檢查是否有簽退
          const rec = checkinRecs[0];
          if (rec.signout_time) {
            this.checkInRec = checkinRecs[0];
            this.hadSignout = true ;
            return ;
          }
        }

        // 1.2 檢查時間是否在可簽退區間
        const isRightTime = await this.checkinService.isRightTimeToSignout(
                                                      this.actUuid,
                                                      this.currentAccessToken,
                                                      this.targetRole.schoolCode, this.targetRole.schoolType,
                                                      this.targetRole.roleType, this.targetRole.studNumber).toPromise();   // 由 server 檢查時間
        if (isRightTime.error) {
          throw isRightTime.error.error;
        }
      } catch (ex) {
        console.log(ex);
        throw ex.error.error;
      }
    }
    return true;
  }

  // async getActivityPeriod(todayString) {
  //   const tempPeriods = await this.activityService.getActPeriodsByActID(this.currentActivity.id).toPromise();
  //   const targetPeriod = tempPeriods.filter(pd => pd.act_date_string === todayString);
  //   return targetPeriod[0];
  // }

  parseAction() {
    if (this.currentUrl.indexOf('/act_checkin') > -1) {
      this.currentAction = '報到';
    }
    if (this.currentUrl.indexOf('/act_signout') > -1) {
      this.currentAction = '簽退';
    }
  }

  parseUserRoles() {
    const result = [];
    this.currentUserInfo.sso_role.forEach(roleItem => {
      if (roleItem.source === 'ldap') {
        roleItem.role.forEach(role => {
          const objRole = new UserRoleInfo();
          objRole.schoolName = role.school_name;
          objRole.schoolCode = role.school_code;
          objRole.schoolType = role.school_type;
          objRole.roleType = role.role_type;
          objRole.roleTypeText = this.userService.convertRoleText(role.role_type);
          if (objRole.roleType === 'parent') {
            objRole.childName = role.child_detail.name;
            objRole.childIdentity = role.child_detail.identity;
          }
          if (objRole.roleType === 'parent' || objRole.roleType === 'student') {
            objRole.studNumber = role.school_student_number;
            objRole.seatNo = role.school_seat_no;
            objRole.className = role.school_class_name;
          }
          result.push(objRole);

          // console.log({ result });
          // console.log( { currentSchoolCode: this.currentSchoolCode,
          //                 currentSchoolType: this.currentSchoolType,
          //                 currentRoleType: this.currentRoleType } );

          // console.log( { school_code: role.school_code,
          //               school_type: role.school_type,
          //               role_type: role.role_type } );

          // 判斷是否是目前選取的身份
          let isTargetRole = (this.currentSchoolCode === role.school_code &&
                                this.currentSchoolType === (role.school_type || '') &&
                                this.currentRoleType === role.role_type);
          if (this.currentRoleType === 'parent' && isTargetRole) {
            isTargetRole = (this.currentUUID === objRole.childIdentity);
          }

          // 如果是目前選取的身份，則指定到 targetRole 變數，好在畫面呈現。
          if (isTargetRole) {
            this.targetRole = objRole;
          }
        });
      }
    });
    this.userRoles = result;
    console.log({ targetRole : this.targetRole });

  }

  showRole(role) {
    console.log(role);
  }


  async doAction() {
    try {
      // let msg = '';
      if (this.currentUrl.indexOf('/act_checkin') > -1) {
        this.checkInRec =  await this.checkinService.checkin(this.actUuid,
          this.currentAccessToken,
          this.targetRole.schoolName,
          this.targetRole.schoolCode,
          this.targetRole.schoolType,
          this.targetRole.roleType,
          this.currentUUID,
          this.mealType,
          this.targetRole.childName,
          this.targetRole.studNumber,
          this.targetRole.seatNo,
          this.targetRole.className
        ).toPromise();
        console.log({ checkinRec : this.checkInRec });
        // window.alert('報到成功！');
      }
      if (this.currentUrl.indexOf('/act_signout') > -1) {
        this.checkInRec =  await this.checkinService.signout(this.actUuid,
          this.currentAccessToken,
          this.targetRole.schoolName,
          this.targetRole.schoolCode,
          this.targetRole.schoolType,
          this.targetRole.roleType,
          this.currentUUID).toPromise();
        // window.alert('簽退成功！');
      }
      this.finishCheckedIn = true ;
    } catch (error) {
      console.log(error.error.error);
      window.alert(JSON.stringify(error.error.error));
    } finally {
      // 轉向報到歷史頁面
      this.backToMain();
    }
  }

  /** 報到 */
  async confirmCheckIn() {
    console.log(this.targetRole);

    // 1.3 檢查是否僅限校內報到
    if (this.currentActivity.limited_my_school) {
      if (this.currentActivity.school_code !== this.targetRole.schoolCode) {
        window.alert(`很抱歉，本活動僅限 ${this.currentActivity.school_name} ${this.currentActivity.school_type} 參加！`);
        return;
      }
    }

    // 若有verification_code則觸發角色成長trigger API
    if (this.currentActivity.verification_code) {
      await this.activityService.trigger({ 
        id_number: this.currentUserInfo.id_number, 
        condition_code: 'SCAN_CHECKIN', 
        verification_code: this.currentActivity.verification_code 
      });
    }

    await this.doAction();
  }


  backToMain() {
    this.router.navigateByUrl(`/main?access_token=${this.currentAccessToken}`);
  }

  async changeRole(evt, role) {
    console.log( { url: this.currentUrl , role });
    const urlPath = this.currentUrl.split('?')[0];
    // "/act_checkin?school_code=403401&school_type=%E9%AB%98%E8%81%B7&role_type=teacher&sso_source=ldap&access_token=180b1727c95140e79ea3a63935774661&uuid=d5be4223-e65e-4bb7-9dc4-3579586c6b19&act_uuid=1595f0c9-05aa-4c9c-bc5d-46607ce0b1f1"
    const newUrl = `${urlPath}?school_code=${role.schoolCode}&school_type=${role.schoolType}&role_type=${role.roleType}&act_uuid=${this.actUuid}&uuid=${role.childIdentity}&access_token=${this.currentAccessToken}`;
    console.log({ newUrl });
    window.location.href = newUrl ;
    // evt.stopPropagation();
  }

  async setMeal(eatMeat) {
    this.mealType = (eatMeat ? '葷' : '素');
  }

  // 對於已經報到或簽退的畫面，要找出報到或簽退的時間
  getCheckInSignoutTime() {
    let result = '';
    if (this.currentAction === '報到') {
      if (this.checkInRec) {
        result = moment(this.checkInRec.checkin_time).format('MM/DD HH:mm');
      }
    } else if (this.currentAction === '簽退') {
      if (this.checkInRec) {
        result = moment(this.checkInRec.signout_time).format('MM/DD HH:mm');
      }
    }
    return result ;
  }

}

class UserRoleInfo {
  schoolName: string;
  schoolCode: string;
  schoolType: string;
  roleType: string;
  roleTypeText: string;
  childName: string;    // 小孩的姓名
  studNumber: string;   // 小孩的學號
  className: string; // 小孩的班級
  seatNo: string; // 小孩的座號
  childIdentity: string;  // 小孩的唯一編號
}
