import { IMDBStudentInfo, IMDBUserInfo } from './../../../core/imdb.service';
import { ActivityRecord, CheckInStatusRecord } from './../../../core/activity.service';
import { SignupRecord } from './../../../core/signup.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivityService } from 'src/app/core/activity.service';
import * as moment from 'moment';
import { UserService } from 'src/app/core/user.service';
import { ImdbService } from 'src/app/core/imdb.service';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { UserRoleRec } from 'src/app/data';
import { throwIfEmpty } from 'rxjs/operators';
import { CheckInStatusService } from 'src/app/core/checkinstatus.service';

@Component({
  selector: 'app-manual-checkin',
  templateUrl: './manual-checkin.component.html',
  styleUrls: ['./manual-checkin.component.scss']
})
export class ManualCheckinComponent implements OnInit {

  @Input() nameList: SignupRecord[] = [];
  @Input() checkInList: CheckInStatusRecord[];
  @Input() currentDate = '';
  @Input() actUuid = '';
  @Output() closed = new EventEmitter<void>();

  currentActivity: ActivityRecord = {} as ActivityRecord;

  // ---- 以下是 「未自行報到」頁籤會用到的變數 ----------
  dicCheckInStatusRecords: Map<string, CheckInStatusRecord> = new Map();

  currentSchoolCode = '0';
  arySchools = [];    //  所有報名者的學校清單
  dicSchools: { [schoolCode: string]: string} = {};   // 學校代碼與校名的對應

  dicCheckInStatusBySignupID: {[signupID: string]: CheckInStatusRecord} = {}; // 用來查詢某筆報名資料是否已經報到？

  aryTeacherSignup: SignupCheckinStatus[] = [];   // 教師的報名清單(含報到狀況)，按照學校與姓名排序
  dicStudSignupBySchool: { [schoolCode: string]: SignupCheckinStatus[]} = {} ;   // 已報名的學生清單，按照學校分成不同包


  isBatchCheckIn = false ;
  isCheckAllTeachers: false ;
  isSchoolAllStudChecked: { [schoolCode: string]: boolean} = {};  // 用來判斷畫面上某個學校全選學生的方塊是否被選取？
  // isSchoolAllParentChecked: { [schoolCode: string]: boolean} = {}; // 用來判斷畫面上某個學校全選家長的方塊是否被選取？
  uiTeacherCount = 0; // 未自行報到畫面上經篩選學校後的教師人數
  uiStudCount = 0;  // 未自行報到畫面上經篩選學校後的學生人數

  // ---- 以下是 「人工新增」頁籤會用到的變數 ----------
  studs: IMDBStudentInfo[] = [];   // 全校學生
  isStudsLoaded = false ; // 是否已經讀過學生資料。如果讀過，就不需要再讀一次，節省資源。
  subscription: Subscription ;
  curRole: UserRoleRec = {} as UserRoleRec;
  grades: string[] = [];  // 全校年級清單。
  classList: any[] = [];  // 全校班級清單。
  filterClassNames: string[] = [];  // 顯示在畫面上的班級清單
  filteredStuds: IMDBStudentInfo[] = [];  // 篩選過後的學生清單
  targetStuds: StudentCheckinStatus[] = []; // 要顯示在畫面上的學生清單以及報到狀況。

  selectedGradeYear = '0';  // 使用者選擇的年級
  selectedClassName = 'all'; // 使用者選擇的班級
  searchString = '';      // 使用者輸入搜尋字串
  searchIDNumber = '';    // 局端搜尋身分證號
  allGradeYearText = '全部年級';  // 不會有全部年級了！為了數量，一定是分年級
  allClassesText = '此年級所有班級';

  targetUsers: IMDBUserInfo[] = [];   // 從中介根據身分證號篩選出來的紀錄


  // 用來查詢某位學生是否已經報到？ studKey: [school_code]_[school_type]_[role]_[id_number]
  dicCheckInStatusByStudKey: {[studKey: string]: CheckInStatusRecord} = {};

  isBatchCheckIn2 = false ;   // 第二個頁籤是否再批次報到的狀態
  isAllStudentsSelected = false;  // 第二個頁籤是否選擇所有學生(核取方塊是否勾選)

  EduManualCheckInMode: 'search_idno' | 'show_checkin_list' = 'search_idno' ; // 紀錄目前是局端人工新增功能的 「按照身份證號查詢」還是「顯示人工報到名單」。用在局端取消人工報到後，該重整哪一種資料。

  constructor(
    private actService: ActivityService,
    // private modalRef: BsModalRef,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private imdbService: ImdbService,
    private store: Store,
    private checkInStatusService: CheckInStatusService,
  ) { }

  async ngOnInit() {
    // console.log('ngOnInit() ...');
    // console.log({ nameList : this.nameList });
    // console.log({ checkInList : this.checkInList});

    this.subscription = this.store.subscribe(x => {
      console.log('人工報到 -- currentRole', x);
      this.curRole = x.role;
    });

    await this.refreshData();
  }

  async refreshData() {
    // console.log('refreshData() ...');
    this.clearData();
    this.currentActivity = await this.actService.getActByUUID(this.actUuid).toPromise();

    this.parseCheckIn();
    this.parseSchools();
  }

  clearData() {
    // console.log('clearData() ...');
    this.aryTeacherSignup = [];
    this.dicStudSignupBySchool = {};
  }

  // 找出有報名的報到清單
  parseCheckIn() {
    // console.log('parseCheckIn() ...');
    this.dicCheckInStatusBySignupID = {};
    this.checkInList.forEach( checkin => {
      if (checkin.signup_id) {
        this.dicCheckInStatusBySignupID[checkin.signup_id.toString()] = checkin ;
      }

      // 這個 dictionary 用來判斷這位學生是否報到過了？
      const studKey = `${checkin.school_code}_${checkin.school_type}_${checkin.role}_${checkin.id_no}`;
      // console.log(studKey);
      if (!this.dicCheckInStatusByStudKey[studKey]) {
        this.dicCheckInStatusByStudKey[studKey] = checkin ;
      }
    });


  }

  // 從已報名名單中解析出學校清單
  parseSchools() {
    console.log(' parseSchools() ....');
    this.arySchools = [];
    this.dicSchools = {};
    this.aryTeacherSignup = [];
    this.dicStudSignupBySchool = {};
    // 對於所有已經報名此活動的報名名單
    this.nameList.forEach( signup => {
      // 找出每一筆報名紀錄的學校
      const { school_code, school_name } = signup ;
      if (!this.dicSchools[school_code]) {
        this.dicSchools[school_code] = school_name ;
        this.arySchools.push( {school_code, school_name});
      }

      if (this.currentSchoolCode === '0' || signup.school_code === this.currentSchoolCode) {
        const rec = new SignupCheckinStatus();
        rec.signup = signup ;
        rec.checkin = this.dicCheckInStatusBySignupID[rec.signup.id.toString()];
        rec.isCheckIn = !!rec.checkin ;

        // 判斷角色
        if (rec.signup.role === 'teacher') {
          this.aryTeacherSignup.push(rec);
        } else if (rec.signup.role === 'student') {
          if (!this.dicStudSignupBySchool[rec.signup.school_code]) {
            this.dicStudSignupBySchool[rec.signup.school_code] = [] ;
          }
          this.dicStudSignupBySchool[rec.signup.school_code].push(rec);
        } else if (rec.signup.role === 'parent') {
          if (!this.dicStudSignupBySchool[rec.signup.school_code]) {
            this.dicStudSignupBySchool[rec.signup.school_code] = [] ;
          }
          this.dicStudSignupBySchool[rec.signup.school_code].push(rec);
        }
      }
    });

    console.log(this.aryTeacherSignup); // 已報名的教師清單

    /** 找出顯示在畫面上的教師或學生數，以決定批次報到按鈕是否要 disabled */
    let teacherCount = 0;
    // 畫面上的教師數
    this.aryTeacherSignup.forEach( rec => {
      if (this.currentSchoolCode === '0' || rec.signup.school_code === this.currentSchoolCode) {
        teacherCount += 1;
      }
    });
    console.log( { teacherCount });
    this.uiTeacherCount = teacherCount ;

    let studCount = 0;
    this.arySchools.forEach( sch => {
      const studs = this.dicStudSignupBySchool[sch.school_code];
      if (studs) {
        studs.forEach(rec => {
          if (this.currentSchoolCode === '0' || rec.signup.school_code === this.currentSchoolCode) {
            studCount += 1 ;
          }
        });
      }
    });
    console.log( { studCount });
    this.uiStudCount = studCount ;
  }

  // 重新取得此活動在指定日期的報到紀錄
  async refreshCheckStatusRecords() {
    // console.log('refreshCheckStatusRecords() ...');
    // this.CheckInStatusRecord = await this.checkinStatusService.getCheckInStatus(this.actUuid).toPromise();
    this.checkInList = await this.actService.getCheckinStatus(this.actUuid, this.currentDate).toPromise();

    this.dicCheckInStatusRecords = new Map(); // 用來判斷報名者是否已經報到了。
    this.dicCheckInStatusByStudKey = {};      // 用來判斷這位學生是否已經報到。

    // 處理要呈現的文字
    this.checkInList.forEach(statusRec => {
      statusRec.role_text = this.userService.convertRoleText(statusRec.role);
      if (statusRec.checkin_time) { statusRec.checkin_time_string = moment(statusRec.checkin_time).format('YYYY-MM-DD HH:mm:ss'); }
      if (statusRec.signout_time) { statusRec.signout_time_string = moment(statusRec.signout_time).format('YYYY-MM-DD HH:mm:ss'); }

      // 這個 dictionary 是用來判斷報名者是否已經報到了。
      if (statusRec.signup_id) {
        if (!this.dicCheckInStatusRecords[statusRec.signup_id.toString()]) {
          this.dicCheckInStatusRecords[statusRec.signup_id.toString()] = statusRec ;
        }
      }

      // 這個 dictionary 用來判斷這位學生是否報到過了？
      const studKey = `${statusRec.school_code}_${statusRec.school_type}_${statusRec.role}_${statusRec.id_no}`;
      // console.log(studKey);
      if (!this.dicCheckInStatusByStudKey[studKey]) {
        this.dicCheckInStatusByStudKey[studKey] = statusRec ;
      }

      // 轉換報到方式為中文字
      statusRec.checkin_type_text = this.getCheckInTypeText(statusRec.checkin_type);
      // console.log(this.dicCheckInStatusRecords);

    });
  }

  getCheckInTypeText( checkinType: string ): string {
    if (checkinType === 'qrcode') { return checkinType; }
    if (checkinType === 'form') { return '填表'; }
    if (checkinType === 'manual') { return '人工'; }
    return checkinType ;
  }


  changeSchool() {
    console.log(this.currentSchoolCode);
    this.parseSchools();
  }

  closeDialog() {
    // this.modalRef.hide();
    // console.log(' closeDialog() ...');
    this.closed.emit();
  }

  SelectAllTeachers() {
    console.log(this.isCheckAllTeachers);
    this.aryTeacherSignup.forEach( rec => {
      if (!rec.isCheckIn && (this.currentSchoolCode === '0' || rec.signup.school_code === this.currentSchoolCode)) {
        rec.isSelected = this.isCheckAllTeachers;
      } else {
        rec.isSelected = false ;
      }
    });
  }

  SelectSchoolStudents(schoolCode: string) {
    // console.log(this.isSchoolAllStudChecked[schoolCode], schoolCode);
    if (!this.dicStudSignupBySchool[schoolCode]) { return ; }
    // console.log('select all students');
    this.dicStudSignupBySchool[schoolCode].forEach( rec => {
      if (!rec.isCheckIn) {
        rec.isSelected = this.isSchoolAllStudChecked[schoolCode];
      } else {
        rec.isSelected = false ;
      }
      // console.log(rec.isSelected);
    });
  }

  /** 未自行報到頁籤，此學校學生是否顯示 */
  showSchool(sch) {
    if (this.currentSchoolCode === '0') {  // 全部學校
      return true;
    } else  {
      return sch.school_code === this.currentSchoolCode ;
    }
  }

  onBatchCheckIn() {
    this.isBatchCheckIn = true ;
    // console.log(this.isBatchCheckIn);
  }


  // 批次報到，只有針對尚未報到的紀錄才能進行報到。
  async onSaveBatchCheckIn() {

    const batchRecs = [];
    // 找出被勾選的教師
    const teachers = this.aryTeacherSignup.forEach( rec => {
      if (rec.isSelected) {
        batchRecs.push(rec);
      }
    });

    this.arySchools.forEach( sch => {
      // 找出被勾選的學生
      const studs = this.dicStudSignupBySchool[sch.school_code];
      if (studs) {
        studs.forEach( stud => {
          if (stud.isSelected) {
            batchRecs.push(stud);
          }
        });
      }


      // 找出被勾選的家長紀錄
    });

    console.log(batchRecs);

    const result = window.confirm(`您確定要為 ${batchRecs.length} 位使用者進行批次報到嗎？`);
    // console.log( { result });
    if (!result) { return ; }

    await this.CheckInBatchRec(batchRecs);
    this.onCancelBatchCheckIn();
    this.snackBar.open(`提醒`, `已手動完成 ${batchRecs.length} 人的報到作業。`, {
      duration: 2000,
    });
  }

  onCancelBatchCheckIn() {
    const teachers = this.aryTeacherSignup.forEach( rec => {
      rec.isSelected = false ;
    });

    this.arySchools.forEach( sch => {
      // 找出被勾選的學生
      const studs = this.dicStudSignupBySchool[sch.school_code];
      if (studs) {
        studs.forEach( stud => {
          stud.isSelected = false ;
        });
      }

      // 找出被勾選的家長紀錄
    });

    this.isBatchCheckIn = false ;
  }

  // 對一筆資料做報到
  async CheckInSingleRec(rec: SignupCheckinStatus) {

    if (this.isBatchCheckIn) {
      this.snackBar.open(`提醒`, '在批次報到模式下，不能進行個別報到', {
        duration: 2000,
      });
      return ;
    }
    console.log(rec);
    await this.CheckInBatchRec([rec]);

  }

  // 批次人工報到
  async CheckInBatchRec(recs: SignupCheckinStatus[]) {
    if (recs.length < 1) { return ; }

    const data = [];
    const signupIDs = recs.map( rec => rec.signup.id);
    // 批次人工報到
    await this.actService.manualCheckInFromSignup(this.actUuid, this.currentDate, this.currentActivity.has_meal, signupIDs).toPromise();
    // 重新取得活動報到清單
    await this.refreshCheckStatusRecords();
    // 重新找出畫面所需資料
    this.refreshData();   // 更新第一頁籤的畫面

    this.onSelectClass(); // 更新第二頁籤的畫面
  }

  // 對一筆資料取消報到，只有針對人工報到的紀錄才能取消報到。
  async CancelCheckInSingleRec(rec: SignupCheckinStatus) {
    await this.actService.manualCancelCheckIn(this.actUuid, [rec.checkin.id]).toPromise();

    await this.refreshCheckStatusRecords();

    // 重新找出畫面所需資料
    this.refreshData();   // 更新第一頁籤的畫面

    this.onSelectClass(); // 更新第二頁籤的畫面
  }


  // 取得全校學生資料
  async getStuds() {
    if (!this.isStudsLoaded) {
      this.studs = await this.imdbService.getSchoolStudents( this.curRole.schoolCode, this.curRole.schoolType).toPromise();
      this.isStudsLoaded = true ;

      // 解析出年級清單與班級清單
      // this.grades = [];
      const tempGrades = [];
      this.classList = [];
      this.filteredStuds = [];
      const dicGrades: {[grade: string]: number} = {};  // 確保年級不重複
      const dicClasses: { [className: string]: string} = {};
      this.studs.forEach( stud => {
        if (!dicGrades[stud.GradeYear]) {
          dicGrades[stud.GradeYear.toString()] = stud.GradeYear ;
          tempGrades.push(stud.GradeYear.toString());
        }
        if (!dicClasses[stud.ClassName]) {
          dicClasses[stud.ClassName] = stud.ClassName;
          this.classList.push( {className: stud.ClassName, gradeYear: stud.GradeYear});
        }
      });

      this.grades = tempGrades.sort( (x, y) => {
        if ( x > y ) { return 1 ; }
        if ( x < y ) { return -1 ; }
        return 0;
      });

      // 設定預設年級與班級
      this.selectedGradeYear = this.grades[0];
      // 執行選擇年級之後的動作
      this.onSelectGradeYear();
    }
  }

  // 選擇年級之後，要篩選出對應的班級清單
  onSelectGradeYear(isFromUI: boolean = false) {
    console.log(this.selectedGradeYear);
    // 篩選班級
    // if (this.selectedGradeYear === '0') {
    //   const tempClasses = [ this.allClassesText ];
    //   this.filterClassNames = tempClasses.concat(this.classList.map( cls => cls.className)) ;
    //   console.log(this.filterClassNames);
    //   this.selectedClassName = this.allClassesText ;
    // } else {
      // let tempClasses = [ this.allClassesText ];
    let tempClasses = [];
    console.log(this.classList);
    console.log(this.classList.filter( cls => (cls.gradeYear.toString() === this.selectedGradeYear)));
    const filteredClass = this.classList.filter( cls => (cls.gradeYear.toString() === this.selectedGradeYear)).map( cls => cls.className);
    console.log(filteredClass);
    tempClasses = tempClasses.concat(filteredClass);
    tempClasses.sort( (x, y) => {
      if (x > y) { return 1; }
      if (x < y) { return -1; }
      return 0;
    });
    tempClasses.push(this.allClassesText);
    console.log(tempClasses);
    this.filterClassNames = tempClasses ;
    this.selectedClassName = this.filterClassNames[0];
    // }

    // 執行選擇班級之後的動作
    this.onSelectClass( isFromUI );
  }

  // 選擇班級之後的動作，就是篩選出對應的學生清單
  onSelectClass(isFromUI: boolean = false) {

    // console.log('onSelectClass()....', this.selectedClassName);
    if (isFromUI) {
      this.searchString = '';
    }
    this.searchStuds(null);
  }

  // 篩選出畫面所需要的學生
  searchStuds(evt) {
    const tempStuds = [];

    this.studs.forEach( stud => {
      let isMatch = false ;

      if (!this.searchString) {
        // 判斷年級是否符合
        isMatch = (this.selectedGradeYear === '0' || this.selectedGradeYear === stud.GradeYear.toString() );
        isMatch = isMatch && (this.selectedClassName ===  this.allClassesText  || this.selectedClassName === stud.ClassName);
        isMatch =  isMatch && (this.searchString === '' ||
                              stud.StudentName.indexOf(this.searchString) > -1 ||
                              stud.StudentNumber === this.searchString );
      } else {
        isMatch =  (
                    stud.StudentName.indexOf(this.searchString) > -1 ||
                    stud.StudentNumber === this.searchString );
      }

      if (isMatch) {
        tempStuds.push(stud);
      }
    });

    // console.log( {  filteredStud : this.filteredStuds, searchString: this.searchString });
    this.filteredStuds = tempStuds ;
    // console.log(this.filteredStuds);

    this.makeTargetStuds();
  }

  // 把篩選出來的學生，比對報到紀錄，並組合出畫面所需要的資料。
  makeTargetStuds() {
    // console.log('makeTargetStuds() ....');
    const tempStuds: StudentCheckinStatus[] = [];
    this.filteredStuds.forEach( stud => {
      const rec = new StudentCheckinStatus();
      rec.stud = stud ;
      const studKey = `${stud.SchoolCode}_${stud.SchoolType}_student_${stud.Identity}`;
      // console.log(studKey) ;
      const checkinRec = this.dicCheckInStatusByStudKey[studKey] ;
      rec.checkin = checkinRec ;
      rec.isCheckIn = (!!checkinRec);
      rec.isSelected = false ;
      tempStuds.push(rec);
    });


    this.targetStuds = tempStuds.sort( (x, y) => {
      if (x.stud.SchoolName > y.stud.SchoolName) { return 1 ; }
      if (x.stud.SchoolName < y.stud.SchoolName) { return -1 ; }

      if (x.stud.ClassName > y.stud.ClassName) { return 1 ; }
      if (x.stud.ClassName < y.stud.ClassName) { return -1 ; }

      if ( parseInt(x.stud.SeatNo.toString(), 10) > parseInt(y.stud.SeatNo.toString(), 10) ) { return 1 ; }
      if ( parseInt(x.stud.SeatNo.toString(), 10) < parseInt(y.stud.SeatNo.toString(), 10) ) { return -1 ; }
      return 0;
    }) ;

    console.log(tempStuds);

    console.log(this.dicCheckInStatusByStudKey );
  }

  // 對一筆資料做報到
  async CheckInSingleRec2(rec: StudentCheckinStatus) {

    if (this.isBatchCheckIn) {
      this.snackBar.open(`提醒`, '在批次報到模式下，不能進行個別報到', {
        duration: 2000,
      });
      return ;
    }
    console.log(rec);
    await this.CheckInBatchRec2([rec]);
  }

  // 批次報到，只有針對尚未報到的紀錄才能進行報到。
  async onSaveBatchCheckIn2() {

    const batchRecs = [];
    // 找出被勾選的學生
    const studs = this.targetStuds.forEach( rec => {
      if (rec.isSelected) {
        batchRecs.push(rec);
      }
    });

    console.log({ targetStuds: this.targetStuds, batchRecs });

    // show confirm dialog.
    const result = window.confirm(`您確定要為 ${batchRecs.length} 位使用者進行批次報到嗎？`);
    // console.log( { result });
    if (!result) { return ; }

    await this.CheckInBatchRec2(batchRecs);
    this.onCancelBatchCheckIn2();
    this.snackBar.open(`提醒`, `已手動完成 ${batchRecs.length} 人的報到作業。`, {
      duration: 2000,
    });
  }

  onCancelBatchCheckIn2() {
    this.targetStuds.forEach( rec => {
      rec.isSelected = false ;
    });
    this.isBatchCheckIn2 = false ;
    this.isAllStudentsSelected = false ;
  }

  // 批次人工報到
  async CheckInBatchRec2(recs: StudentCheckinStatus[]) {
    // console.log(recs);
    if (recs.length < 1) { return ; }

    const data = recs.map( rec => rec.stud );
    // 批次人工報到
    await this.actService.manualCheckIn(this.actUuid, this.currentDate, this.currentActivity.has_meal,  data).toPromise();
    // 重新取得活動報到清單
    await this.refreshCheckStatusRecords();
    // 重新找出畫面所需資料
    this.onSelectClass(); // 更新第二頁籤的畫面

    this.refreshData();   // 更新第一頁籤的畫面
  }

  onSelectAllStudents() {
    // 1. 所有資料清除選曲
    this.targetStuds.forEach( studCheckinStatus => {
      studCheckinStatus.isSelected = false ;
    });

    // 2. 針對畫面上的學生勾選
    this.targetStuds.forEach( studCheckinStatus => {
      // 還沒報到過的才設定為勾選。
      if (!studCheckinStatus.isCheckIn) {
        studCheckinStatus.isSelected = this.isAllStudentsSelected ;
      }
    });

    // console.log(this.targetStuds);
  }

  /* =============== 局端人工報到功能  ================ */

  async searchByIdNumber() {
    // console.log( { id_no: this.searchIDNumber, act_uuid: this.actUuid, pd_date: this.currentDate } );
    if (this.searchIDNumber) {
      this.targetUsers = await this.imdbService.getByIdNumber(this.searchIDNumber, this.actUuid, this.currentDate).toPromise();
      this.targetUsers.forEach( u => {
        u.roleText = this.userService.convertRoleText(u.role);
      });
      this.EduManualCheckInMode = 'search_idno' ;
    }
  }

  /* 局端活動人工報到 */
  async CheckInEduSingleRec(rec: IMDBUserInfo) {
    if (this.isBatchCheckIn) {
      this.snackBar.open(`提醒`, '在批次報到模式下，不能進行個別報到', {
        duration: 2000,
      });
      return ;
    }
    // console.log(rec);
    // 如果是學生
    if (rec.role === 'student') {
      rec.StudentName = rec.Name ;
      const temp = new StudentCheckinStatus();
      temp.stud = rec ;
      temp.isCheckIn = rec.isCheckIn ;
      await this.CheckInBatchRec2([temp]);
    } else {
    // 如果是教師
      // 批次人工報到
      await this.actService.manualCheckInTeacher(this.actUuid, this.currentDate, this.currentActivity.has_meal, [rec]).toPromise();
      // 重新取得活動報到清單
      await this.refreshCheckStatusRecords();
    }

    // 更新局端人工報到頁籤畫面
    await this.searchByIdNumber();
  }


  /* 局端活動取消人工報到 */
  async CancelCheckInEduSingleRec(rec: IMDBUserInfo) {
    // console.log( { rec });
    await this.actService.manualCancelCheckIn(this.actUuid, [rec.checkin_id]).toPromise();

    // await this.refreshCheckStatusRecords();

    // 重新找出畫面所需資料
    this.refreshData();   // 更新第一頁籤的畫面


    // 更新局端人工報到頁籤畫面
    if (this.EduManualCheckInMode === 'search_idno') {
      await this.searchByIdNumber();
    }
    else {
      await this.showEduManualCheckList();
    }

  }

  /** 取得局端手動報到的名單 */
  async showEduManualCheckList() {
    this.searchIDNumber = ''; // 清空
    //
    this.targetUsers = await this.checkInStatusService.getManualCheckInRecords(this.actUuid, this.currentDate).toPromise();
    this.targetUsers.forEach( u => {
      u.roleText = this.userService.convertRoleText(u.role);
    });

    this.EduManualCheckInMode = 'show_checkin_list';
  }

  /** 轉換成中文的年級 */
  toChiGrade(grade) {
    if (grade === '0') { return '全部'; }
    if (grade === '1') { return '一'; }
    if (grade === '2') { return '二'; }
    if (grade === '3') { return '三'; }
    if (grade === '4') { return '四'; }
    if (grade === '5') { return '五'; }
    if (grade === '6') { return '六'; }
    if (grade === '7') { return '七'; }
    if (grade === '8') { return '八'; }
    if (grade === '9') { return '九'; }
    if (grade === '10') { return '十'; }
    if (grade === '11') { return '十一'; }
    if (grade === '12') { return '十二'; }
    return '';
  }


}



class SignupCheckinStatus {
  signup: SignupRecord ;
  checkin: CheckInStatusRecord ;
  isCheckIn: boolean;
  isSelected: boolean;
}

/** 此類別用來產生「人工新增」畫面中所需要的紀錄 */
class StudentCheckinStatus {
  stud: IMDBStudentInfo | IMDBUserInfo ;
  checkin: CheckInStatusRecord ;
  isCheckIn: boolean;
  isSelected: boolean;
}
