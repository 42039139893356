  <div class="modal-content">
    <div class="modal-header pb-2">
      <div class="d-flex justify-content-between w-100">
        <div></div>
        <h4 class="modal-title">分場時數設定</h4>
        <button type="button" class="close" (click)="closeDialog(2)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div class="modal-body px-3 px-md-4 mt-4">
      <table class="dtable w-100">
        <tr>
          <td>日期</td>
          <td>時間</td>
          <td *ngIf="hasSections || expired">校務對應節次</td>
          <td *ngIf="hasIssuedHours|| expired">核發時數</td>
        </tr>
        <tr *ngFor="let pds of periods">
          <td>{{pds.act_date_mmdd}} ({{ pds.weekday_string }})</td>
          <td class="">{{pds.act_start_time}}-{{pds.act_end_time}}</td>
          <td  *ngIf="hasSections || expired">
            <div class="d-flex align-items-center"  *ngIf="pds.will_set_periods || expired">
              <select class="form-select form-control" style="width: 45px;" [(ngModel)]="pds.start_period">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
              </select>
              <div class="mx-2">至</div>
              <select class="form-select form-control" style="width: 45px;" [(ngModel)]="pds.end_period">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
              </select>
            </div>
          </td>
          <td class="text-nowrap"  *ngIf="hasIssuedHours || expired">
            <div class="d-flex align-items-center"  *ngIf="pds.will_issue_hours || expired">
              <input type="number" min=0 max="" id="" placeholder="" class="form-control" style="width: 48px;"
                [(ngModel)]="pds.issued_hours">
              小時
            </div>
          </td>
        </tr>
      </table>

    </div>
    <div class="modal-footer justify-content-center py-4">
      <button type="button" class="btn btn-outline-cyan mr-3" style="width: 120px;"
        (click)="closeDialog(2)">關閉</button>
      <button type="button" class="btn btn-cyan" style="width: 120px;" (click)="savePeriods()">儲存</button>
    </div>
  </div>
