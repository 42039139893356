import { CheckinService } from 'src/app/core/checkin.service';
import { ActivityRecord, ActivityService } from 'src/app/core/activity.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SchoolInfo, SchoolService } from '../core/school.service';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  currentAct: ActivityRecord = {} as ActivityRecord ;
  currentUrl = '';
  hasError = false;
  errMsg = '';
  isRegSuccess = false ;
  mealType: '葷' | '素' ;
  // mealType: '葷' | '素' | undefined ;
  isLoading = true;   // 剛開啟畫面時，會在載入資料狀態


  regForm = new FormGroup({
    name : new FormControl('', Validators.required),
    tel_no : new FormControl('', Validators.required),
    school: new FormControl(''),
    role: new FormControl(),
    mealType: new FormControl()
  });

  myControl = new FormControl();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private actService: ActivityService,
    private checkinService: CheckinService
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentUrl = this.router.url ;
    this.router.events.subscribe(event =>
      {
        // console.log(event);
        if (event instanceof NavigationEnd) {
          console.log(event);
          this.currentUrl = event.url ;
        }
      }
    );

    const { uuid } = this.route.snapshot.queryParams;
    if (!uuid) {
      this.hasError = true ;
      this.errMsg = '缺少活動代碼';
      return ;
    }
    try {
      this.currentAct = await this.actService.getActByUUID(uuid).toPromise();
      if (!this.currentAct) {
        this.errMsg = '找不到相關的活動資訊';
      }
    } catch (error) {
      console.log(error);
      this.errMsg = '取得活動資訊時發生錯誤！';
    } finally {
      this.isLoading = false ;  // 已完成載入資料
    }
  }

  close() {
    window.close();
  }

  async submitForm() {
    const data = this.regForm.value;
    data.school_name = this.regForm.value.school ;
    data.act_uuid = this.currentAct.act_uuid ;
    data.meal_type = this.regForm.value.mealType ;
    console.log(data);
    try {
      await this.checkinService.registration(data).toPromise();
      this.isRegSuccess = true ;
      // alert('報名成功！');

    } catch (error) {
      console.log(error);
      alert(error.error.error);
    }
  }

}
