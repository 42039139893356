

/**
 * 此類別根據 Excel 解析的 json 欄位，決定呼叫哪一個 importer
 */
export class ImporterType {

  public static getImporterType(jsonContent: any): 'teacher' | 'student' | 'parent' | 'others' | 'nodata' {

    const result = 'teacher';

    if (!jsonContent || jsonContent.length === 0) { return 'nodata' ; }

    const firstElm = jsonContent[0];

    if (firstElm['手機號碼']) {
      return 'others';
    } else if (firstElm['學號'] && firstElm['學生姓名'] ) {
       return 'student';
    } else if (firstElm['小孩學號']) {
      return 'parent';
    } else if (firstElm['教師姓名']) {
      return 'teacher';
    }

    return result ;
  }
}
