<mat-radio-group
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  [(ngModel)]="mode"
>
  <mat-radio-button [value]="'namelist'" style="font-size: 2rem"
    >查看報名名單</mat-radio-button
  >
  <mat-radio-button [value]="'chooseFile'" class="ml-4" style="font-size: 2rem"
    *ngIf="currentActivity.school_code !== '399999'"
    >匯入報名資料</mat-radio-button>
</mat-radio-group>

<!-- 顯示報名名單 -->
<div class="d-flex mb-3" *ngIf="mode === 'namelist'">
  <app-namelist style="width: 100%;"></app-namelist>
</div>

<!-- 匯入檔案 -->
<div *ngIf="mode === 'chooseFile'">
  <div class="d-flex mb-3">
    <button mat-button class="btn btn-outline-cyan ml-auto" [matMenuTriggerFor]="menu">
      <img src="/assets/img/mdi-download.svg" alt="" style="width: 32px;">下載範本
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="download('teachers')">校內教師</button>
      <button mat-menu-item (click)="download('students')">校內學生</button>
      <!-- <button mat-menu-item (click)="download('parents')">校內家長</button>
      <button mat-menu-item (click)="download('others')">校外人士</button> -->
      <button mat-menu-item (click)="download('otherpts')" [disabled]="true">
        校外親師生(準備中)
      </button>
    </mat-menu>

    <!-- <button class="btn btn-light ml-auto"><i class="material-icons">save_alt</i>下載範本</button> -->
  </div>
  <div
    class="importExcel point"
    data-toggle="modal"
    data-target="#importForm"
    (click)="chooseFile()"
  >
    匯入表單
    <input
      #myfile
      type="file"
      style="opacity: 0; width: 0px"
      (change)="addFile($event)"
      accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    />
  </div>
</div>

<!-- 如果是解析完資料，會在此呈現資料-->
<div *ngIf="mode === 'parseData'">
  <!-- <div class="d-flex flex-row justify-content-between">
    <button
      mat-button
      style="margin-bottom: -20px"
      (click)="this.mode = 'chooseFile'"
    >
      <mat-icon style="padding-top: 4px">keyboard_backspace</mat-icon>
      取消
    </button>
    <div class="mt-2 d-flex flex-row align-items-end">
      <span
        >總計：{{ this.matchedData.length + this.unmatchedData.length }} 筆
        ,</span
      >
      <span style="color: green" class="ml-2">
        比對中介成功：{{ this.matchedData.length }} 筆 ,</span
      >
      <span style="color: red" class="ml-2">
        失敗：{{ this.unmatchedData.length }} 筆</span
      >
    </div>
    <div class="p-2 d-flex flex-row justify-content-center align-items-center" > -->
    <!-- <button
      mat-raised-button
      color="primary"
      (click)="startImport()"
      class="px-2 py-1"
      [disabled]="this.matchedData.length === 0" >
      開始匯入
    </button> -->
    <!-- </div> -->
  <!-- </div> -->

  <!-- 如果沒有資料-->
  <div
    *ngIf="sourceType === 'nodata'"
    class="d-flex flex-row align-items-center justify-content-center"
    style="height: 300px;"
  >
    <div>
      <mat-icon color="lightgray">folder_open</mat-icon>
      <span class="ml-4">檔案裡沒有資料喔！</span>
    </div>
  </div>

  <!-- 如果是學生名單 -->
  <div *ngIf="sourceType === 'student'">
    <app-import-student [jsonContent]="jsonContent" (afterParsed)="afterParsed($event);" (back)="back();"></app-import-student>
  </div>

  <!-- 如果是教師名單 -->
  <div *ngIf="sourceType === 'teacher'">
    <app-import-teacher [jsonContent]="jsonContent" (afterParsed)="afterParsed($event);" (back)="back();"></app-import-teacher>
  </div>

  <!-- 如果是家長名單 -->
  <div *ngIf="sourceType === 'parent'">
    <app-import-parents [jsonContent]="jsonContent" (afterParsed)="afterParsed($event);" (back)="back();"></app-import-parents>
  </div>

  <!-- 如果是其他名單 -->
  <div *ngIf="sourceType === 'others'">
    <app-import-others [jsonContent]="jsonContent" (afterParsed)="afterParsed($event);" (back)="back();"></app-import-others>
  </div>
</div>
