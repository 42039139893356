import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { ActivityPeriod, ActivityRecord, ActivityService } from 'src/app/core/activity.service';
import { CheckinService } from 'src/app/core/checkin.service';
import * as moment from 'moment';
import { relative } from '@angular/compiler-cli/src/ngtsc/file_system';

@Component({
  selector: 'app-choose',
  templateUrl: './choose.component.html',
  styleUrls: ['./choose.component.scss']
})
export class ChooseComponent implements OnInit {

  currentAct: ActivityRecord = {} as ActivityRecord ;
  currentUrl = '';
  hasError = false;
  errMsg = '';

  isNoFillForm = false ;    // 是否要顯示填表單的按鈕。如果使用者掃描簽退的 QR Code，且該移動裝置沒有安裝 App，導向此頁面時，不要出現填表單的按鈕。

  // appUrl = 'https://testflight.apple.com/join/wPvz1cSm';
  appUrl = 'https://apps.apple.com/us/app/%E9%85%B7%E8%AA%B2/id1560628279';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private actService: ActivityService,
    private checkinService: CheckinService
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentUrl = this.router.url ;
    this.router.events.subscribe(event =>
      {
        // console.log(event);
        if (event instanceof NavigationEnd) {
          console.log(event);
          this.currentUrl = event.url ;
        }
      }
    );

    const { uuid, noFillForm } = this.route.snapshot.queryParams;
    this.isNoFillForm = (noFillForm && (noFillForm === 'true'));
    if (!uuid) {
      this.hasError = true ;
      this.errMsg = '缺少活動代碼';
      return ;
    }

    // 檢查活動是否存在
    try {
      this.currentAct = await this.actService.getActByUUID(uuid).toPromise();
      if (!this.currentAct) {
        this.errMsg = '找不到相關的活動資訊';
      }
    } catch (error) {
      // console.log(error);
      this.errMsg = (error.error && error.error.error ) ? `${error.error.error}!!` : '取得活動資訊時發生錯誤！';
    }

    // 檢查現在是否是可報到時間？
    try {
      if (this.currentAct.id) {
        const isRightTime = await this.checkinService.isRightTimeToCheckin(uuid).toPromise();   // 由 server 檢查時間
        if (isRightTime.error) {
          console.log(isRightTime);
          this.errMsg = isRightTime.error;
        }
      }
    } catch (error) {
      console.log(error);
      this.errMsg = error.error.error ;
    }

    // 判斷手機OS
    const os = navigator.userAgent;
    const isAndroid = os.indexOf('Android') > -1 || os.indexOf('Adr') > -1;
    console.log(isAndroid);
    if (isAndroid) {
      // this.appUrl = 'https://play.google.com/store/apps/details?id=edu.tp_cooc_mobile_react&hl=en_US&gl=US';
      this.appUrl = 'https://play.google.com/store/apps/details?id=edu.tp_pts_cooc';
     }

  }

  // async parseActivity() {
  //   // 1. 時間是否可報到
  //   //  1.0 取得 server time
  //   const serverTimeString = await this.checkinService.getServerTime().toPromise();
  //   const serverTime = moment(serverTimeString.server_time);

  //   // 1.1 檢查日期是否同一天
  //   const today = serverTime.format('YYYY-MM-DD');
  //   const pd: ActivityPeriod = await this.getActivityPeriod(today);  // 找尋此活動在今天是否日期
  //   if (!pd) { throw `今日 (${today})不是開放報到的日期`; }
  //   // const act_date = moment(this.currentActivity.act_date).format('YYYY-MM-DD');
  //   // const isSameDay = (act_date === serverTime.format('YYYY-MM-DD'));
  //   // if (!isSameDay) { throw `目前 (${today})不是開放報到的日期: ${act_date}`  }

  //   // 1.2 檢查時間是否在可報到區間
  //   const time = serverTime.format('HH:mm');
  //   console.log(`server time: ${serverTime} `);
  //   const avalableCheckInTime = moment(`${today} ${pd.act_start_time}`)
  //     .add((0 - this.currentAct.checkin_before_start), 'minutes');
  //   console.log(`avalableCheckInTime : ${avalableCheckInTime}`);
  //   const finalCheckInTime = moment(`${today} ${pd.act_end_time}`);
  //   console.log(`finalCheckInTime : ${finalCheckInTime}`);
  //   const isRightTime = (serverTime > avalableCheckInTime && serverTime < finalCheckInTime);
  //   // const isRightTime = (time >= avalableCheckInTime && time <= pd.act_end_time);
  //   // const isRightTime = (time >= this.currentActivity.act_start_time && time <= this.currentActivity.act_end_time);
  //   // if (!isRightTime) { throw `目前（${time}）不是開放報到的時間（${this.currentActivity.start_time} ~ ${this.currentActivity.end_time}）`; }
  //   if (!isRightTime ) {
  //     throw `現在（${time}）不是開放報到的時間（${avalableCheckInTime.format("HH:mm")} ~ ${pd.act_end_time}）`;
  //   }

  //   return true;
  // }

  // async getActivityPeriod(todayString) {
  //   const tempPeriods = await this.actService.getActPeriodsByActID(this.currentAct.id).toPromise();
  //   const targetPeriod = tempPeriods.filter(pd => pd.act_date_string === todayString);
  //   return targetPeriod[0];
  // }

  toFillForm() {
    console.log('route to registration');
    const navigationExtras: NavigationExtras = {
      queryParams: { uuid : this.currentAct.act_uuid },
      fragment: 'anchor'
    };
    this.router.navigate(['registration'], navigationExtras);
  }
}
