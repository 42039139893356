import { ActivityNotificationServiceService } from './activity-notification-service.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActivityRecord, ActivityService } from '../core/activity.service';
import { ActStateService } from './act-state.service';


@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  curTab: 'info' | 'qrcode' | 'checkin_status' | 'import' | 'act_dates';
  currentActivity: ActivityRecord = {} as ActivityRecord ;
  // currentActivity: ActivityRecord = {} as ActivityRecord ;

  subscription: Subscription;
  actID: number;

  showCheckInStatus = false;    // 是否顯示「報到狀況」頁籤
  showImportNameList = false;   // 是否顯示「匯入報名名單」頁籤



  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private actStateSrv: ActStateService,
    private activityService: ActivityService,
    private activityNotificationService: ActivityNotificationServiceService
    ) {
      // console.log(router.url);
      activityNotificationService.emitCheckInTabStatusChange.subscribe( val => {
        console.log(val);
        this.showCheckInStatus = val ;
        this.curTab = 'checkin_status';
      });

      activityNotificationService.emitImportListTabStatusChange.subscribe( val => {
        console.log(val);
        this.showImportNameList = val ;
        this.curTab = 'import';
      });

      activityNotificationService.emitActivityStatusChange.subscribe( val => {
        console.log(val);
        this.currentActivity = val ;
      });
    }

  ngOnInit(): void {
    console.log(' info.init() ...');
    this.curTab = 'info';

    this.subscription = this.route.params.subscribe(async params => {
      this.actID = params.id;
      this.currentActivity = await this.activityService.getActByID(this.actID).toPromise();
      this.actStateSrv.setCurrentAct(this.currentActivity);
    });

    if (this.actStateSrv.getCurrentAct()) {
      this.currentActivity = this.actStateSrv.getCurrentAct();
    }
    if (this.router.url.indexOf ('/act_dates') > -1 ) {
      // this.router.navigate(['act_dates'], {relativeTo: this.route});
      this.setTab('act_dates');
    } else {
      // this.router.navigate(['info'], {relativeTo: this.route});
      this.setTab('info');
    }
  }

  setTab(tab: 'info' | 'qrcode' | 'checkin_status' | 'import' | 'act_dates') {
    this.curTab = tab;
    this.router.navigate([this.curTab], {relativeTo: this.route});
  }

  goback() {
    this.router.navigate(['../../'], {relativeTo: this.route});
  }

}
