import { UserService } from './../../../core/user.service';
import { OthersImporter } from './../../../core/parser/others_importer';
import { TeacherImporter } from '../../../core/parser/teacher_importer';
import { ActivityRecord } from './../../../core/activity.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImdbService } from 'src/app/core/imdb.service';
import { SignupService } from 'src/app/core/signup.service';
import { ActStateService } from '../../act-state.service';

@Component({
  selector: 'app-import-others',
  templateUrl: './import-others.component.html',
  styleUrls: ['./import-others.component.scss']
})
export class ImportOthersComponent implements OnInit {
  _jsonContent: any[] = [];
  matchedData = [];
  unmatchedData = [];

  @Input()
  set jsonContent(content: any[]) {
    // console.log('set jsonContent ....');
    this._jsonContent = content ;
    // console.log(this._jsonContent);
    this.compareContentWithIMDB();
  }

  @Output() afterParsed = new EventEmitter<any>();
  @Output() back = new EventEmitter<any>();


  constructor(
    private actStateService: ActStateService,
    private imdbService: ImdbService,
    private signupService: SignupService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  compareContentWithIMDB() {
    // console.log('compare Content with IMDB ....');
    const activity: ActivityRecord = this.actStateService.getCurrentAct();
    const importer = new OthersImporter(this._jsonContent, activity.school_code, activity.school_type, this.userService);
    importer.match().subscribe({
      next: (result) => {
        // console.log('after compare content with IMDB ...');
        // console.log(result);
        this.matchedData = result.matched_data ;
        this.unmatchedData = result.unmatched_data ;
        this.afterParsed.emit({ matchedData: this.matchedData, unmatchedData: this.unmatchedData});
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  async startImport() {
    const activity = this.actStateService.getCurrentAct();
    // const result = [];
    for(let i=0; i< this.matchedData.length; i++) {
      const other = this.matchedData[i];
      try {
        await this.signupService.registerOthers(
                                      activity.act_uuid ,
                                      other.name,
                                      other.tel_no).toPromise();
        other.isSaved = true ;
        other.errMsg = '';
      } catch (error) {
        other.isSaved = false ;
        other.errMsg = error.error.error ;
      } finally {
        //
      }
      // result.push(tea);
    }
    // this.matchedData = result ;
  }

}
