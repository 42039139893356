import { UserService } from './../../../core/user.service';
import { ParentImporter } from './../../../core/parser/parent_importer';
import { ActivityRecord } from './../../../core/activity.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImdbService } from 'src/app/core/imdb.service';
import { StudentImporter } from 'src/app/core/parser/student_importer';
import { SignupService } from 'src/app/core/signup.service';
import { ActStateService } from '../../act-state.service';

@Component({
  selector: 'app-import-parents',
  templateUrl: './import-parents.component.html',
  styleUrls: ['./import-parents.component.scss']
})
export class ImportParentsComponent implements OnInit {

  _jsonContent: any[] = [];
  matchedData = [];
  unmatchedData = [];

  @Input()
  set jsonContent(content: any[]) {
    // console.log('set jsonContent ....');
    this._jsonContent = content ;
    // console.log(this._jsonContent);
    this.compareContentWithIMDB();
  }

  @Output() afterParsed = new EventEmitter<any>();
  @Output() back = new EventEmitter<any>();


  constructor(
    private actStateService: ActStateService,
    private imdbService: ImdbService,
    private signupService: SignupService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  compareContentWithIMDB() {
    // console.log('compare Content with IMDB ....');
    const activity: ActivityRecord = this.actStateService.getCurrentAct();
    const importer = new ParentImporter(this._jsonContent, activity.school_code, activity.school_type, this.imdbService, this.userService );
    importer.match().subscribe({
      next: (result) => {
        // console.log('after compare content with IMDB ...');
        // console.log(result);
        this.matchedData = result.matched_data ;
        this.unmatchedData = result.unmatched_data ;
        this.afterParsed.emit({ matchedData: this.matchedData, unmatchedData: this.unmatchedData});
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  async startImport() {
    const activity = this.actStateService.getCurrentAct();
    const result = [];
    for(let i=0; i< this.matchedData.length; i++) {
      const stud = this.matchedData[i];
      try {
        const targetStud = stud.targetStud ;
        if (!targetStud) { throw {error: { error : ''}}; }
        await this.signupService.register(targetStud.Identity,
                                      activity.act_uuid ,
                                      stud.name,
                                      'parent',
                                      stud.studNo,
                                      targetStud.SchoolName,
                                      activity.school_code,
                                      activity.school_type,
                                      targetStud.ClassName,
                                      targetStud.SeatNo ).toPromise();
        stud.isSaved = true ;
        stud.errMsg = '';
      } catch (error) {
        stud.isSaved = false ;
        stud.errMsg = error.error.error ;
      } finally {
        //
      }
      result.push(stud);
    }
    // this.matchedData = result ;
  }

}
