import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { ActivityRecord, ActivityService } from 'src/app/core/activity.service';
import { ActStateService } from '../act-state.service';
import html2canvas from 'html2canvas';
// import jspdf from 'jspdf';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit {
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  appLinksPath = 'https://cooc.tp.edu.tw/app_links/conference.html';
  valueSignIn: string;
  valueSignOut: string;

  currentActivity: ActivityRecord;

  constructor(
    private actStateSrv: ActStateService,
    private router: Router,
    private route: ActivatedRoute,
    private activityService: ActivityService

  ) { }

  ngOnInit(): void {
    this.currentActivity = this.actStateSrv.getCurrentAct();
    // this.valueSignIn = 'https://cooc.tp.edu.tw/app_links/act_checkin.html?act_uuid=' + this.currentActivity.act_uuid;
    // this.valueSignOut = 'https://cooc.tp.edu.tw/app_links/act_signout.html?act_uuid=' + this.currentActivity.act_uuid;
    this.valueSignIn = `${this.appLinksPath}?th=a&act_uuid=${this.currentActivity.act_uuid}`;
    this.valueSignOut = `${this.appLinksPath}?th=b&act_uuid=${this.currentActivity.act_uuid}`;
  }

    // qrCode下載轉圖片
  downloadFile() {

    const data = document.getElementById('downloadQrcodeSIN');
    const h3show = document.getElementById('h3hid');
    const signOut = document.getElementById('downloadQrcodeSOUT');
    data.style.width = '900px';
    data.style.paddingTop = '20px';
    data.style.paddingBottom = '50px';

    try {
      h3show.style.display = 'block';
      signOut.style.marginTop = '200px';
    } catch { }

    html2canvas(this.screen.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = this.currentActivity.title + 'QRCode.png';
      this.downloadLink.nativeElement.click();
    });

    data.style.width = 'auto';
    data.style.paddingTop = '0';

    try {
      h3show.style.display = 'none';
      signOut.style.marginTop = '0';
    }
    catch { }
  }

    //qrCode下載轉pdf
    // const data = document.getElementById('downloadQrcodeSIN');
    // const pdf = new jspdf('p', 'mm', 'a4');

    // const h3show = document.getElementById('h3hid');
    // const signOut = document.getElementById('downloadQrcodeSOUT');
    // data.style.width = '800px';
    // data.style.paddingTop = '50px';

    // try {
    //   h3show.style.display = 'block';
    //   signOut.style.marginTop = '400px';
    // } catch { }

    // html2canvas(data).then(canvas => {

    //   const contentWidth = canvas.width;
    //   const contentHeight = canvas.height;
    //   const pageHeight = (contentWidth / 210) * 297;
    //   let leftHeight = contentHeight;
    //   let position = 10;
    //   // const imgWidth = 210;
    //   // const imgHeight = (210 / contentWidth) * contentHeight;
    //   const contentDataURL = canvas.toDataURL('image/png', 1.0);


    //   if (h3show) {

    //     while (leftHeight > 0) {
    //       pdf.addImage(contentDataURL, 'PNG', 3, position, 200, 480);
    //       leftHeight -= pageHeight;
    //       position -= 297;
    //       if (leftHeight > 0) {
    //         pdf.addPage();
    //       }
    //     }
    //   } else {
    //     pdf.addImage(contentDataURL, 'PNG', 3, 10, 200, 210);
    //   }

    //   pdf.save(this.currentActivity.title + 'QRCode.pdf');

    //   data.style.width = 'auto';

    //   try {
    //     h3show.style.display = 'none';
    //     signOut.style.marginTop = '0px';
    //   }
    //   catch { }

    // });
  // }

  qrcodePrint(){
    const h3show = document.getElementById('h3hid');
    const signOut = document.getElementById('downloadQrcodeSOUT');
    try {
      h3show.style.display = 'block';
      signOut.style.marginTop = '500px';
    } catch { }

    const newWindow = window.open('', '', 'width=800,height=600');

    const docStr = document.getElementById('downloadQrcodeSIN').innerHTML;
    const docstyle = '<text align= center><font size= 5em>' + docStr + '</font size></text align>';
    newWindow.document.write(docstyle);
    newWindow.document.close();

    newWindow.print();
    newWindow.close();

  }


}
