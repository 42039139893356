import { NoAdminPermissionComponent } from './manage/no-admin-permission/no-admin-permission.component';
import { AgentComponent } from './manage/agent/agent.component';
import { OfficeComponent } from './manage/office/office.component';
import { ChooseComponent } from './registration/choose/choose.component';
import { CheckinComponent } from './mobile/checkin/checkin.component';
import { MainComponent } from './main/main.component';
import { AuthGuard } from './guard/auth.guard';
import { SigninComponent } from './signin/signin.component';
import { ImportComponent } from './activity/import/import.component';
import { CheckinStatusComponent } from './activity/checkin-status/checkin-status.component';
import { QrcodeComponent } from './activity/qrcode/qrcode.component';
import { InfoComponent } from './activity/info/info.component';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { ActivityComponent } from './activity/activity.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MobileComponent } from './mobile/mobile.component';
import { RegistrationComponent } from './registration/registration.component';
import { ManageComponent } from './manage/manage.component';
import { LogoutComponent } from './logout/logout.component';
import { ActDatesComponent } from './activity/act-dates/act-dates.component';


const routes: Routes = [
  {
    path: 'main', component: MainComponent,
    children: [
      { path: '', component: ActivityListComponent },
      {
        path: 'activity/:id', component: ActivityComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'info', component: InfoComponent },
          { path: 'qrcode', component: QrcodeComponent },
          { path: 'checkin_status', component: CheckinStatusComponent },
          { path: 'import', component: ImportComponent },
          { path: 'act_dates', component: ActDatesComponent },
        ]
      },
    ]
  },
  { path: 'mobile', component: MainComponent },
  { path: 'act_checkin', component: CheckinComponent },
  { path: 'act_signout', component: CheckinComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'chooseReg', component: ChooseComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'manage', component: ManageComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '', redirectTo: '/main', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
