import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';

/**
 * 此類別讀取 Excel 內容，並轉成 json 內容。
 */
export class ExcelParser {
  file: File;
  arrayBuffer: any;
  filelist: any;
  content: any;
  workbook: any;
  worksheetNames: string[];

  _isSubscribed = true ;

  constructor(file: File) {
    this.file = file ;
  }

  getWorksheetContent(wsName: string) {
    const worksheet = this.workbook.Sheets[wsName];
    this.content = XLSX.utils.sheet_to_json(worksheet, { raw: true });
    // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
    return this.content ;
  }

  getContent():Observable<any> {
    this._isSubscribed = true ;
    // 定義一個 Observable
    const result = new Observable((observer) => {
      try {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(this.file);
        fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          const data = new Uint8Array(this.arrayBuffer);
          const arr = new Array();
          for (let i = 0; i !== data.length; ++i) {
            arr[i] = String.fromCharCode(data[i]);
          }
          const bstr = arr.join('');

          this.workbook = XLSX.read(bstr, { type: 'binary' });
          this.worksheetNames = this.workbook.SheetNames;
          const first_sheet_name = this.worksheetNames[0];
          const content =  this.getWorksheetContent(first_sheet_name);
          if (this._isSubscribed) {
            observer.next(content);
          }
        };

      } catch (error) {
        observer.error(error);
      }
       // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
          this._isSubscribed = false ;
        }
      };
    });

    return result ;
  }
}
