import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { UserRec } from '../data/index';
import { InitUser } from './user.action';
import { patch } from '@ngxs/store/operators';

@State<UserRec>({
    name: 'user',
    defaults: {} as UserRec
})
@Injectable()
export class UserState {

    @Action(InitUser)
    initUser(ctx: StateContext<UserState>, action: InitUser) {
        const state = ctx.getState();
        ctx.setState(state);
        const user = action.user;
        // ctx.setState(user);

        // ctx.setState(patch({
        //   action.user
        // }));
    }
}
