import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class QRImageService {

  constructor(
    private http: HttpClient
  ) { }

  // getAllowedDepts(schoolCode: string, schoolType: string): Observable<string[]> {
  //   return this.http.get(`/service/permission/getAllowedDepts?school_code=${schoolCode}&school_type=${schoolType}`).pipe (
  //     map( v => v as string[])
  //   );
  // }

  createImage(imageDataUrl: string): Observable<any> {
    const data = { data_url: imageDataUrl };
    return this.http.post(`/service/qrimage/create`, data).pipe(
      map( v => v as any)
    );
  }
}
