import { AddActivityDatesComponent } from './add-activity-dates/add-activity-dates.component';
import { Component, OnInit, TemplateRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityPeriod, ActivityRecord, ActivityService, PeriodCheckinStatistics } from 'src/app/core/activity.service';
import { PageEvent } from '@angular/material/paginator';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, take } from 'rxjs/operators';
import { ActStateService } from '../act-state.service';
import * as moment from 'moment';
import { ActivityNotificationServiceService } from '../activity-notification-service.service';

@Component({
  selector: 'app-act-dates',
  templateUrl: './act-dates.component.html',
  styleUrls: ['./act-dates.component.scss']
})
export class ActDatesComponent implements OnInit, OnChanges {

  @Input()
  set acitivityID(activityid: number) {
    this.actID = activityid ;
  }
  private actID: number ;

  @Input()
  set showEditButton(showButton: boolean) {
    this.showButton = showButton ;
  }
  showButton = false ;

  /** 宣告無用的屬性，主要是要觸發 onChange 事件，更新內容 */
  @Input()
  set refreshContentKey(refreshKey: number) {
    this.refreshKey = refreshKey ;
    console.log({ refreshKey : this.refreshKey });
  }
  private refreshKey: number ;

  subscription: Subscription;
  // actID: number;
  periods: ActivityPeriod[] = [];
  dicPeriodStatistics: {[checkinDate: string]: PeriodCheckinStatistics} = {};   // 每個活動週期的報到簽退葷素人數統計

  acted: true;

  pageLength = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 30];
  // currentActivity: ActivityRecord;
  modalRef: BsModalRef;
  perio: ActivityPeriod;

  hasIssuedHours = false; // 是否核發時數
  hasSections = false;    // 是否設定校務對應節次


  constructor(
    private route: ActivatedRoute,
    private activityService: ActivityService,
    private actStateSrv: ActStateService,
    private modal: BsModalService,
    private snackBar: MatSnackBar,
    private router: Router,
    private activityNotificationService: ActivityNotificationServiceService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log( { changes });
    console.log( 'refresh data ....');
    this.refreshData();
  }

  async ngOnInit() {
    console.log('act_dates.component, ng init ......');
    this.subscription = this.route.params.subscribe(async params => {
      this.actID = params.id;
      await this.refreshActivityPeriods();
      await this.refreshPeriodStatistics();
    });

    this.activityNotificationService.emitActivityPeriodsStatusChange.subscribe( val => {
      console.log(val);
      if (val) {
        this.actID = val ;
        this.refreshData().then();
      }
    });
    // await this.refreshData();
  }

  async refreshData() {
    console.log(`更新 periods 資料 : ${this.actID}`);
    if (this.actID) {
      await this.refreshActivityPeriods();
      await this.refreshPeriodStatistics();
    }
  }

  async refreshActivityPeriods() {
    const tempPeriods = await this.activityService.getActPeriodsByActID(this.actID).toPromise();
    tempPeriods.forEach( pd => {
      const tempDate = moment(pd.act_date_string);
      pd.weekday_string = this.activityService.getWeekdayString(tempDate.day());
      pd.act_date_mmdd = moment(pd.act_date_string).format('MM/DD');

      if (pd.will_issue_hours) {
        this.hasIssuedHours = pd.will_issue_hours ;
      }
      if (pd.will_set_periods) {
        this.hasSections = pd.will_set_periods ;
      }
    });
    this.periods = tempPeriods ;
  }

  async refreshPeriodStatistics() {
    const act = this.actStateSrv.getCurrentAct();
    if (!act ) { return ; }
    const periodStatistics = await this.activityService.getCheckinStatusByPeriod(act.act_uuid).toPromise();
    this.dicPeriodStatistics = {};
    periodStatistics.forEach( pd => {
      this.dicPeriodStatistics[pd.checkin_date] = pd ;
    });
  }

  // getWeekdayString(weekDay: number) {
  //   let result = '' ;
  //   if (weekDay === 0) { result = '日'; }
  //   if (weekDay === 1) { result = '一'; }
  //   if (weekDay === 2) { result = '二'; }
  //   if (weekDay === 3) { result = '三'; }
  //   if (weekDay === 4) { result = '四'; }
  //   if (weekDay === 5) { result = '五'; }
  //   if (weekDay === 6) { result = '六'; }
  //   return result ;
  // }

  changePage(page: PageEvent) {
    this.pageIndex = page.pageIndex;
    this.pageSize = page.pageSize;
    console.log('change page and refresh activity ....');
    this.refreshActivityPeriods();
  }

  // addPeriods() {
  //   const dialog = this.modal.show(AddActivityDatesComponent, {
  //     initialState: { periods: this.periods, actID: this.actID },
  //   });

  //   this.modal.onHide.pipe(take(1), filter(reason => reason === 'data saved')).subscribe(() => {
  //     // Do anything here, this will get called only once
  //     console.log('data saved and refresh actviity .');
  //     this.refreshActivityPeriods().then();
  //   });
  // }

  // async deletePeriod(pd) {
  //   console.log(pd);
  //   await this.activityService.deleteActPeriod(this.actID.toString(), pd.id).toPromise();
  //   await this.refreshActivityPeriods();
  // }

  // editPeriod(pd) {

  // }

  CheckInState(act: ActivityRecord) {
    // this.actStateSrv.setCurrentAct(act);
    // this.router.navigate(['../../activity', act.id, 'checkin_status'], { relativeTo: this.route });
  }

  // EditPeri(templateRef: TemplateRef<any>, peri: ActivityPeriod) {
  // EditPeri(templateRef: TemplateRef<any>) {
  //   // this.perio = peri;
  //   this.modalRef = this.modal.show(templateRef);
  // }

  // async savePeriods() {
  //   try {
  //     await this.activityService.setActPeriods(this.actID.toString(), this.periods).toPromise();
  //     // this.modalService.setDismissReason('data saved');
  //     this.modalRef.hide();
  //   }
  //   catch (error) {
  //     const msg = '修改場次資訊時發生錯誤';
  //     this.snackBar.open(`修改場次資訊時發生錯誤`, msg, {
  //       duration: 2000,
  //     });
  //   }
  // }

  showCheckInStatus(pd) {
    console.log(pd);
    this.activityNotificationService.emitCheckInTabStatus(true);
    this.actStateSrv.setPeriod(pd); // 設定要顯示的日期。
    this.router.navigate(['../../activity', pd.ref_activity_id, 'checkin_status'], { relativeTo: this.route });
  }

}
