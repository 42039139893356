import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private myDepts: DeptInfo[] = [];

  constructor(
    private http: HttpClient
  ) { }

  // getAllowedDepts(schoolCode: string, schoolType: string): Observable<string[]> {
  //   return this.http.get(`/service/permission/getAllowedDepts?school_code=${schoolCode}&school_type=${schoolType}`).pipe (
  //     map( v => v as string[])
  //   );
  // }

  getDeptBlackList(schoolCode: string, schoolType: string): Observable<DeptBlackList> {
    return this.http.get(`/service/permission/getDeptBlackList?school_code=${schoolCode}&school_type=${schoolType}`).pipe(
      map( v => v as DeptBlackList)
    );
  }

  setDeptBlackList(schoolCode: string, schoolType: string, blacklist: string[]): Observable<DeptBlackList> {
    const data = { school_code: schoolCode, school_type: schoolType, blacklist} ;
    return this.http.post(`/service/permission/setDeptBlackList`, data).pipe(
      map( v => v as DeptBlackList)
    );
  }

  getAdminAgents(schoolCode: string, schoolType: string): Observable<AdminAgentInfo[]> {
    return this.http.get(`/service/permission/getAdminAgent?school_code=${schoolCode}&school_type=${schoolType}`).pipe(
      map( v => v as AdminAgentInfo[])
    );
  }

  addAdminAgents(schoolCode: string, schoolType: string, identity: string, name: string, position: any): Observable<any> {
    const data = { school_code: schoolCode, school_type: schoolType, identity, name, position};
    return this.http.post(`/service/permission/addAdminAgent`, data).pipe(
      map( v => v as any)
    );
  }

  removeAdminAgents(schoolCode: string, schoolType: string, identity: string): Observable<any> {
    const data = { school_code: schoolCode, school_type: schoolType, identity};
    return this.http.post(`/service/permission/removeAdminAgent`, data).pipe(
      map( v => v as any)
    );
  }

  getMyDepts() {
    return this.myDepts ;
  }
  setMyDepts(depts: DeptInfo[]) {
    this.myDepts = depts ;
  }
}

export class DeptInfo {
  deptCode: string;
  deptName: string;
  selected: boolean ;
}

export interface DeptBlackList{
  school_code: string;
  school_type: string;
  dept_blacklist: string[];
}

export interface AdminAgentInfo {
  user_id: number;
  name: string;
  identity: string;
  agent_id: number;
  school_code: string;
  school_type: string;
  role_text: string;
}
