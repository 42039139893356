import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-admin-permission',
  templateUrl: './no-admin-permission.component.html',
  styleUrls: ['./no-admin-permission.component.scss']
})
export class NoAdminPermissionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
