<!-- <ng-template #templateEditIssuedHours> -->
  <div class="">
    <div class="modal-header pb-2">
      <div class="d-flex justify-content-between w-100">
        <div style="width: 40px;"></div>
        <h4 class="modal-title">調整核發時數</h4>
        <button type="button" class="close ml-0" (click)="closeDialog()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div class="modal-body px-4">
      <div class="d-flex justify-content-center py-4 align-items-center">
        <!-- <span>{{ selectedCIS.school_name }}</span> -->
        <span>{{ selectedCIS.name }}</span> 在 {{ selectedCIS.checkin_time.toLocaleString().split('T')[0] }} 的核發時數：
      </div>
      <div class="d-flex justify-content-center align-items-center">
        從 <span> {{ selectedCIS.issued_hours || '0' }} 小時</span> 改成
        <input type="number" class="form-control mx-2" style="width: 50px;" id="" placeholder=""
            min="0"  max="24" [(ngModel)]="newIssuedHours" >
        小時
      </div>

    </div>
    <div class="modal-footer justify-content-center py-4">
      <button type="button" class="btn btn-outline-cyan mr-3" style="width: 120px;color: #4BA9AB;"
        (click)="closeDialog()">取消</button>
      <button type="submit" class="btn btn-cyan" (click)="saveIssuedHours();"
        style="background-color: #4BA9AB;width: 120px">儲存</button>
    </div>
  </div>
<!-- </ng-template> -->
