import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivityPeriod, ActivityService } from 'src/app/core/activity.service';
import { ActivityNotificationServiceService } from '../activity-notification-service.service';

@Component({
  selector: 'app-period-hours',
  templateUrl: './period-hours.component.html',
  styleUrls: ['./period-hours.component.scss']
})
export class PeriodHoursComponent implements OnInit {

  actID: number;
  periods: ActivityPeriod[] = [];

  hasIssuedHours = false; // 是否核發時數
  hasSections = false;    // 是否設定校務對應節次

  expired = false;

  constructor(
    private modalService: BsModalService,
    private activityService: ActivityService,
    // private modalRef: BsModalRef,
    private snackBar: MatSnackBar,
    private activityNotificationService: ActivityNotificationServiceService
  ) { }

  async ngOnInit() {
    this.refreshActivityPeriods();

    // 判斷活動是否已經過期 (若活動過期，則要顯示時數和日期可供調整。)
    const currentActivity = await this.activityService.getActByID(this.actID).toPromise();
    currentActivity.act_date_string = moment(currentActivity.act_date).format('YYYY/MM/DD');
    const actTime = new Date(currentActivity.act_date_string + ' 23:59');
    const today = new Date();
    if (!currentActivity.is_repeat) {
      this.expired = (today > actTime);
      console.log({ actTime, now: today, expired: this.expired });
    } else {
      const deadline = moment(currentActivity.repeat_end_date).add(1, 'd');
      this.expired = (moment(today) > deadline);
      console.log({ deadline, today,  expired: this.expired });
    }
  }

  closeDialog(modalId?: number) {
    this.modalService.hide(modalId);
  }

  async refreshActivityPeriods() {
    const tempPeriods = await this.activityService.getActPeriodsByActID(this.actID).toPromise();
    tempPeriods.forEach( pd => {
      const tempDate = moment(pd.act_date_string);
      pd.weekday_string = this.getWeekdayString(tempDate.day());
      pd.act_date_mmdd = moment(pd.act_date_string).format('MM/DD');

      if (pd.will_issue_hours) {
        this.hasIssuedHours = pd.will_issue_hours ;
      }
      if (pd.will_set_periods) {
        this.hasSections = pd.will_set_periods ;
      }

    });
    this.periods = tempPeriods ;
  }

  getWeekdayString(weekDay: number) {
    let result = '' ;
    if (weekDay === 0) { result = '日'; }
    if (weekDay === 1) { result = '一'; }
    if (weekDay === 2) { result = '二'; }
    if (weekDay === 3) { result = '三'; }
    if (weekDay === 4) { result = '四'; }
    if (weekDay === 5) { result = '五'; }
    if (weekDay === 6) { result = '六'; }
    return result ;
  }

  async savePeriods() {
    try {
      await this.activityService.setActPeriods(this.actID.toString(), this.periods).toPromise();
      this.activityNotificationService.emitActivityPeriodsStatus(this.actID) ;  // 觸發節次已被修改的事件，要更新下方另一個元件裡的節次清單
      this.modalService.setDismissReason('data edit OK');
      this.modalService.hide(2);

    }
    catch (error) {
      const msg = '修改場次資訊時發生錯誤';
      this.snackBar.open(`修改場次資訊時發生錯誤`, msg, {
        duration: 2000,
      });
    }
  }

}
