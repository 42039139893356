<!-- 報到紀錄清單 -->
<div class="p-4 pt-5 mx-auto" style="font-size: 1.1rem; max-width: 980px;" *ngIf="this.currentMode === 'list'">
  <span>您一年內成功報到紀錄如下：</span>
  <table class="table my-3" style="width:100%;font-size:1.5rem;">
    <thead>
      <tr>
        <th class="pr-2 pl-0 pl-sm-3 pl-lg-4" style="height: 46px;">活動</th>
        <th class="pl-2 pr-0 pr-sm-3 pr-lg-4" style="height: 46px; width: 130px;">日期</th>
      </tr>
    </thead>
    <tbody>
      <tr data-toggle="modal" data-backdrop="static" *ngFor="let history of histories" (click)="showHistory(history)" style="cursor:pointer">
        <td data-th="活動" class="pr-2 pl-0 pl-sm-3 pl-lg-4">
          {{ history.title }}
        </td>

        <td data-th="日期" class="pl-2 pr-0 pr-sm-3 pr-lg-4 text-nowrap text-right">
          {{ history.checkin_date }}<span class="btn-outline-custom"> 詳細</span>
        </td>
      </tr>
    </tbody>
    </table>
</div>

<!-- 某一筆報到紀錄明細 Detail -->
<div class="p-4 pt-5 mx-auto" style="font-size: 1.1rem; max-width: 980px;" *ngIf="this.currentMode === 'detail'">
  <div class="d-flex flex-row justify-content-between">
    <div><mat-icon class="pointer" (click)="back()" >keyboard_backspace</mat-icon> <span class="ml-3">報到資訊</span> </div>
    <div></div>
    <div></div>
  </div>
  <table class="table my-3" style="width:100%;">
    <!-- <thead>
      <tr>
        <th class="px-0" colspan="2">報到資訊</th>
      </tr>
    </thead> -->
    <tbody>
      <tr>
        <td style="width: 25%;">活動</td>
        <td>{{ currentHistory.title }}</td>
      </tr>
      <tr>
        <td>主辦學校</td>
        <td>{{ currentHistory.act_school_name }}</td>
      </tr>
      <tr>
        <td>報到身分</td>
        <td>{{ currentHistory.role_type_text }}</td>
      </tr>
      <tr>
        <td>報到日期</td>
        <td>{{ currentHistory.checkin_type === 'manual' ? '人工報到' :currentHistory.checkin_date }}</td>
      </tr>
      <tr>
        <td>報到時間</td>
        <td>{{ currentHistory.checkin_type === 'manual' ? '人工報到' :currentHistory.checkin_time }}</td>
      </tr>
      <tr>
        <td>簽退時間</td>
        <td>{{ currentHistory.signout_type === 'manual' ? '人工簽退' : currentHistory.signout_time }}</td>
      </tr>
      <tr *ngIf="hasCertificate.has_certificate && (currentHistory.role_type !== 'parent')">
        <!-- <td></td> -->
        <td colspan="2" style="text-align: center;" (click)="downloadCert(currentHistory.act_uuid)">
          <span class="btn btn-cyan">下載證明書</span>
        </td>
      </tr>
    </tbody>
    </table>
</div>
