<!-- web Only header -->
<header class="navbar navbar-expand-lg py-3 px-2 border-bottom border-custom" *ngIf="!isInApp">
  <div class="cocontainer">
    <div class="d-flex justify-content-between align-items-center">
      <!-- <button aria-controls="navbarTogglerDemo01"
                class="btn btn-link navbar-toggler"
                type="button"
                aria-haspopup="true"
                (click)="openNav()">
          <img src="../assets/img/menu.svg"
               alt="image" />
        </button>-->
      <a href="/">
        <div class="d-flex align-items-center mr-auto">
          <img class="cologo" src="../assets/img/logos.png" alt="酷課雲 Logo" />
          <h4 class="align-self-center" style="word-break: keep-all;font-weight:700;">掃碼登記</h4>
        </div>
      </a>
      <div class="dropdown loginp"   >
        <a aria-controls="dropdown-basic" class="btn-out-link dropdown-toggle" href="javascript:;"
          data-toggle="dropdown" aria-haspopup="true">
          <table>
            <tbody>
              <tr>
                <td rowspan="2">
                  <!-- <img class="user-photo mr-2" src="../assets/img/avatar/b02@1x.png" /> -->
                  <img class="user-photo mr-2" src="{{ userInfo.photo }}" />
                </td>
                <td class="text-left">
                  <div class="username"> {{ userInfo.name || '' }} <span style="font-size: 1rem">{{ curRole.title ||
                      ''}}</span></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="schname" *ngIf="isTeacher">
                    <!-- <span> {{currentClass?.schoolName}} </span> -->
                    <span>{{curRole.schoolName || '查無身分' }} </span>
                    <span class="caret"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </a>
        <div class="dropdown-menu dropdown-menu-right scrolly rounded-xl" id="dropdown-basic" role="menu"
          aria-labelledby="button-basic" style="min-width: 200px;">

          <div  *ngIf="isTeacher" >
            <button class="dropdown-item px-3" *ngFor="let role of userRoleList; let i = index">
              <input type="radio" class="form-check-input" name="sizeSel" id="sizel{{i}}" [checked]="i==0">
              <label for="sizel{{i}}" class="dropdown-item d-flex align-items-center cursor-point mb-0" (click)="setCurRole(role)">
                <span></span>
                <div style="white-space: pre-line; word-break: keep-all;">{{ role.schoolName+' '+
                  role.dept+' '+ (role.unit || '') +' '+ role.title }}</div>
              </label>
            </button>
            <hr style="margin: 5px;">
            <!-- <div class="dropdown-item d-flex align-items-center px-3" >
              <img alt="" src="/assets/img/history.webp" class="mx-1" style="width: 24px; height: 24px;">
              <button class="dropdown-item drop-link cursor-point" (click)="viewMode= 'checkinHisotry'">報到歷程
              </button>
            </div>
            <div class="dropdown-item d-flex align-items-center px-3" >
              <img alt="" src="/assets/img/manage.png" class="mx-1" style="width: 24px; height: 24px;">
              <a class="dropdown-item drop-link" href="/">活動管理
              </a>
            </div> -->
            <div class="dropdown-item d-flex px-3" *ngIf="isScIT">
              <img alt="" src="/assets/img/setting.svg" class="mx-1">
              <button class="dropdown-item drop-link cursor-point" (click)="ShowManage()">管理權限
              </button>
            </div>
          </div>
          <div class="dropdown-item d-flex px-3">
            <img _ngcontent-leb-c2="" alt="" src="/assets/img/Signout.svg" class="mx-1">
            <button class="dropdown-item drop-link cursor-point" (click)="logout()">登出
            </button>
          </div>
          <!-- <a class="dropdown-item d-flex"
               *ngFor="let class of userInfo.class"
               [ngClass]="{'active': class==currentClass}"
               [routerLink]="['loader', class.classID]">
              <a class="drop-link">
                {{class.schoolName}} {{class.className}} {{class.teacherType=="homeroom"?"班導師":class.subject}}
              </a>
            </a> -->

          <!--
            <a class="dropdown-item d-flex"
               (click)="logout()">
              <a class="drop-link">登出</a>
            </a> -->
        </div>
      </div>
    </div>
  </div>
</header>

<!-- APP Only header, 只有 App 才會出現 -->
<div *ngIf="isInApp">
  <div class="headermenu">
    <div class="d-flex justify-content-between align-items-center mx-4">
      <div class="app-menu-icon app-icon5" onclick="window.ReactNativeWebView.postMessage('SCAN_QRCODE')">
      </div>
        <h4>掃碼登記</h4>
        <a href="https://ptsapp.tp.edu.tw/close">
          <mat-icon aria-hidden="false" style="color:#000;">close</mat-icon>
        </a>
    </div>
  </div>
  <div class="p-4"></div>

  <div class="navbar py-1 mx-3 mt-3 mb-0" style="border: 1px solid #cacaca; border-radius: 8px; min-height: 48px;" *ngIf="isTeacher && viewMode==='manage'">
    <div class="dropdown loginp w-100">
      <a aria-controls="dropdown-basic" class="btn-out-link dropdown-toggle w-100 app" href="javascript:;"
        data-toggle="dropdown" aria-haspopup="true">
        <div class="w-100 d-flex align-items-center" style="white-space: pre-line; word-break: keep-all;">
          <img src="/assets/img/account.png" alt="" class="mr-2" style="width: 32px; height: 32px;">
          <div>
            {{curRole.schoolName || '查無身分' }} {{ curRole.dept || '' }} {{ curRole.unit ? curRole.unit :  '' }} {{ curRole.title || ''}}
          </div>
        </div>

      </a>
      <div class="dropdown-menu dropdown-menu-right scrolly rounded-xl w-100" id="dropdown-basic" role="menu"
        aria-labelledby="button-basic">

        <button class="dropdown-item px-3" *ngFor="let role of userRoleList; let i = index">
          <input type="radio" class="form-check-input" name="sizeSel" id="sizel{{i}}">
          <label for="sizel{{i}}" class="dropdown-item d-flex align-items-center" (click)="setCurRole(role)">
            <div style="white-space: pre-line; word-break: keep-all;">{{ role.schoolName+' '+
              role.dept+' '+  (role.unit || '') +' '+ role.title }}</div>
          </label>
        </button>
        <hr style="margin: 5px;">
        <div class="dropdown-item d-flex px-3" *ngIf="isScIT">
          <img alt="" src="/assets/img/setting.svg" class="mx-1">
          <button class="dropdown-item drop-link" (click)="ShowManage()">管理權限
          </button>
        </div>
        <!-- <div class="dropdown-item d-flex px-3">
          <img _ngcontent-leb-c2="" alt="" src="/assets/img/Signout.svg" class="mx-1">
          <button class="dropdown-item drop-link" (click)="logout()">登出
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <!-- APP Only footer -->
  <div class="footmenu" *ngIf="isTeacher" >
    <div class="d-flex fs-12">
      <!-- <div [ngClass]="{'app-menu': true, 'w-100': true, 'text-center': true, 'active': (viewMode === 'checkinHisotry')}" class="app-menu active w-100 text-center" (click)="setViewMode('checkinHisotry')"></div> -->
      <div [ngClass]="{'app-menu': true, 'w-100': true, 'text-center': true, 'active': (viewMode === 'checkinHisotry')}" (click)="setViewMode('checkinHisotry')">
        <div class="app-menu-icon app-icon0 mx-auto my-1"></div>
        <div>報到歷程</div>
      </div>
      <div [ngClass]="{'app-menu': true, 'w-100': true, 'text-center': true, 'active': (viewMode === 'manage')}"  (click)="setViewMode('manage')" >
        <div class="app-menu-icon app-icon1 mx-auto my-1"></div>
        <div>活動管理</div>
      </div>
    </div>
  </div>
</div>

<!-- 教師才出現此 Menu，學生和家長都只看到報到歷程，所以不需要這些 menu .-->
<div id="scanPage" class="container" page  *ngIf="isTeacher" >
  <div class="d-flex">
    <div class="pr-4 mt-0 mt-sm-1 text-nowrap" style="cursor: pointer;">
      <ul class="nav nav-pills" id="pills-tab" role="tablist" *ngIf="!isInApp && isMainMenu">
        <li class="nav-item">
          <a class="nav-link mr-3" href="javascript:void(0)" role="tab" [ngClass]="{'active': viewMode==='manage'}"
            (click)="setViewMode('manage')">
            活動管理
          </a>
          <div class="backg4"></div>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" role="tab" [ngClass]="{'active': viewMode==='checkinHisotry'}"
            (click)="setViewMode('checkinHisotry')">
            報到歷程
          </a>
          <div class="backg4"></div>
        </li>
      </ul>
    </div>
  </div>
</div>


<!-- 報到歷程所有人都可顯示 -->
<app-check-history *ngIf="viewMode === 'checkinHisotry'"></app-check-history>

<!-- 管理介面只有教師才能使用 -->
<div *ngIf="(isTeacher && viewMode==='manage')">
  <router-outlet ></router-outlet>
</div>

