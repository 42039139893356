<div class="text-center h-auto" style="background-color: #9bd7d7">
  <div style="padding: 1rem; font-size: 1.2rem; font-weight: 600">
    {{ currentAct.title }}
  </div>
  <div
    class=""
    style="font-size: 1rem; padding-bottom: 1rem; margin-top: -0.7rem"
  >
    活動表單填寫
  </div>
</div>

<div class="py-4 px-5">
  <div *ngIf="isLoading" class="alert text-center" role="alert">
    資料載入中 ....
  </div>

  <div
    *ngIf="!isLoading && currentAct.limited_my_school"
    class="alert alert-danger p-4 text-center"
    role="alert"
  >
    此活動僅限校內人員參加，不接受填表報名。
  </div>

  <div *ngIf="!isLoading && !currentAct.limited_my_school">
    <form
      [formGroup]="regForm"
      (ngSubmit)="submitForm()"
      *ngIf="!this.isRegSuccess"
    >
      <div *ngIf="!errMsg" class="d-flex flex-column">
        <mat-form-field [hideRequiredMarker]="false">
          <mat-label>姓名</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error>姓名必填欄位喔！</mat-error>
        </mat-form-field>

        <mat-form-field [hideRequiredMarker]="false">
          <mat-label>行動電話</mat-label>
          <input matInput type="text" formControlName="tel_no" />
          <mat-error>行動電話是必填欄位喔！</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>學校/服務單位</mat-label>
          <input matInput type="text" formControlName="school" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>身分/職稱</mat-label>
          <input
            matInput
            type="text"
            formControlName="role"
            placeholder="如：教師、家長、院長。。。"
          />
        </mat-form-field>
      </div>
      <div
        *ngIf="this.currentAct.has_meal"
        class="d-flex flex-row align-items-center"
        style="font: size 1rem"
      >
        <div>餐點：</div>
        <mat-radio-group formControlName="mealType">
          <mat-radio-button value="葷">葷</mat-radio-button>
          <mat-radio-button value="素">素</mat-radio-button>
        </mat-radio-group>
      </div>
      <button
        type="button"
        mat-Stroked-button
        class="py-2 my-4"
        [disabled]="regForm.invalid"
        (click)="submitForm()"
        style="border-radius: 10px; font-size: 1rem; width: 100%"
      >
        送出表單
      </button>
    </form>
  </div>
  <!-- <div *ngIf="this.currentAct.has_meal" class="d-flex flex-row align-items-center" style="font: size 1rem;">
      <div>餐點：</div>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="mealType">
          <mat-radio-button value="葷"><span style="font-size:1.1rem;">葷</span></mat-radio-button>
          <mat-radio-button value="素" class="ml-2"><span style="font-size:1.1rem;">素</span></mat-radio-button>
      </mat-radio-group>
  </div>
  <button type="submit" mat-Stroked-button class="py-2 my-4" [disabled]="regForm.invalid" (click)="submitForm()" style="border-radius: 10px; font-size: 1rem; width: 100%;">
    送出表單
  </button> -->
</div>

<div *ngIf="this.isRegSuccess">
  <div
    class="d-flex flex-column align-items-center justify-content-center mt-4 p-4"
  >
    <div>報到成功!!</div>
    <div
      class="w-100 pb-4"
      style="
        text-align: center;
        border-bottom: 1px solid lightgray;
        font-size: 1.5rem;
      "
    >
      您已完成活動報到
    </div>
    <mat-divider></mat-divider>
  </div>
</div>

<div
  *ngIf="errMsg"
  class="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
>
  <div class="d-flex flex-column align-items-center">
    <div style="font-size: 1.5rem; color: red">{{ errMsg }}</div>
    <button class="mt-4" mat-raised-button (click)="close()">關閉</button>
  </div>
</div>
