<!-- 加入日期的畫面 -->
<form [formGroup]="fgActEditor">
  <mat-card>
    <mat-card-header>
      新增活動日期：
    </mat-card-header>
    <mat-card-content>
      <mat-form-field appearance="fill" class="mt-2 w-100">
        <mat-label>開始日期</mat-label>
        <input
          matInput
          [min]="minDate"
          [matDatepicker]="pickerRepeadStart"
          formControlName="repeat_start_date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerRepeadStart"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerRepeadStart></mat-datepicker>
      </mat-form-field>
      <!-- 活動時間-->
      <div class="d-flex mb-3">
          <div appearance="fill" class="ngx-timepicker-sel">
              <div class="ngx-label">活動開始時間</div>
              <ngx-timepicker-field formControlName="act_start_time" [format]="24" [defaultTime]="'12:30'"></ngx-timepicker-field>
          </div>
          <div appearance="fill" class="ngx-timepicker-sel" style="margin-left: auto;">
              <div class="ngx-label">活動結束時間</div>
              <ngx-timepicker-field formControlName="act_end_time" [format]="24" [defaultTime]="'12:30'"></ngx-timepicker-field>
          </div>
      </div>

      <div >
        <mat-form-field appearance="fill" style="width: 100%;margin-right: 10px;">
          <mat-label>對照學校公假節次：</mat-label>
          <mat-select  formControlName="map_school_periods" multiple (selectionChange)="selectSchoolPeriods();">
            <mat-option [value]="1">1</mat-option>
            <mat-option [value]="2">2</mat-option>
            <mat-option [value]="3">3</mat-option>
            <mat-option [value]="4">4</mat-option>
            <mat-option [value]="5">5</mat-option>
            <mat-option [value]="6">6</mat-option>
            <mat-option [value]="7">7</mat-option>
            <mat-option [value]="8">8</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <br />
      <!-- 重複模式-->
      <mat-radio-group
        formControlName="repeat_mode"
        (change)="selectRepeatMode($event)"
      >
        <mat-radio-button value="0" class="mr-4">不重複</mat-radio-button>
        <mat-radio-button value="1" class="mr-4">每天</mat-radio-button>
        <mat-radio-button value="2">每週</mat-radio-button>
      </mat-radio-group>

      <div class="pl-4 mt-2" *ngIf="fgActEditor.value.repeat_mode === '1'">
        <mat-checkbox class="mr-4" formControlName="includeSat">含週六</mat-checkbox>
        <mat-checkbox class="mr-4" formControlName="includeSun">含週日</mat-checkbox>
      </div>

      <div class="pl-4 mt-2" *ngIf="fgActEditor.value.repeat_mode === '2'">
        <mat-checkbox class="mr-4" formControlName="Mon">一</mat-checkbox>
        <mat-checkbox class="mr-4" formControlName="Tue">二</mat-checkbox>
        <mat-checkbox class="mr-4" formControlName="Wed">三</mat-checkbox>
        <mat-checkbox class="mr-4" formControlName="Thr">四</mat-checkbox>
        <mat-checkbox class="mr-4" formControlName="Fri">五</mat-checkbox>
        <mat-checkbox class="mr-4" formControlName="Sat">六</mat-checkbox>
        <mat-checkbox class="mr-4" formControlName="Sun">日</mat-checkbox>
      </div>

      <mat-form-field appearance="fill" class="w-100 mt-2" *ngIf="repeatMode !== '0'">
        <mat-label>截止日期</mat-label>
        <input
          matInput
          [min]="minDate"
          [matDatepicker]="pickerRepeadEnd"
          formControlName="repeat_end_date"
          (dateChange)="AfterChoosingEndDate();"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerRepeadEnd"

        ></mat-datepicker-toggle>
        <mat-datepicker #pickerRepeadEnd></mat-datepicker>
      </mat-form-field>

    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button (click)="cancelAddPeriod()">取消</button>
      <button mat-button (click)="saveAddPeriod()">確定</button>
    </mat-card-actions>
  </mat-card>
</form>
