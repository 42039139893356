<div class="row mx-0 mt-4 mb-0" id="downloadQrcodeSIN" #screen>
  <div class="col-lg-12">
    <div class="text-center text-justify">
      <div class="qrcode-title fw-600 px-0 mb-4">{{currentActivity ? currentActivity.title : ''}}</div>

      <div class="d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
        <div class="d-flex mb-3 mb-sm-0">
          <div class="qrcode-type mb-0">
            {{currentActivity ? (currentActivity.act_date_string | date: 'yyyy/MM/dd') : ''}}
            <!-- <span *ngIf="currentActivity && currentActivity.is_repeat"></span> -->
            <span *ngIf="currentActivity && currentActivity.is_repeat"> ~ {{ currentActivity ?
              (currentActivity.repeat_end_date | date: 'yyyy/MM/dd') : '' }}</span>
          </div>
          <div class="qrcode-type mx-2">{{ qrTypeText }}用</div>
        </div>
          <button id="btn" title="下載QRCode" class="btn btn-outline-cyan px-2 py-0 text-nowrap" style="min-width: auto;" (click)="downloadFile();">
          <img src="/assets/img/mdi-download.svg" alt="" width="24px"></button>
      </div>
    </div>

      <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [margin]="0"
        [value]="targetValue" Class="bshadow"></ngx-qrcode>
  </div>
</div>
