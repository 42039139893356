<div class="d-flex flex-wrap mb-4">

  <div class="text-nowrap mt-3">
    <!-- <div class="btn btn-outline-orange border-1 mr-2 mr-sm-3 px-3 px-sm-4" *ngIf="showCheckInStatusButton"
      (click)="showCheckInStatus(currentActivity)">報到狀況</div> -->
    <div class="btn btn-outline-orange border-1 px-3 px-sm-4 mr-3" *ngIf="showImportNameListButton"
      (click)="showImportList(currentActivity)">報名名單</div>
  </div>
  <div class="ml-0 ml-md-auto text-nowrap mt-3">
    <button class="btn btn-outline-cyan mr-2 mr-sm-3 px-3 px-sm-4" (click)="editPeriod()">
      分場時數設定
    </button>

    <button class="btn btn-outline-cyan mr-2 mr-sm-3 px-3 px-sm-4"
      (click)="openConfirmForm(actCheckDel, currentActivity)" [title]="titleMsg">
      刪除</button>
    <button class="btn btn-cyan px-3 px-sm-4" [disabled]="!acted" (click)="editActivity('edit',currentActivity.id)"
      [title]="titleMsg">
      修改
    </button>
  </div>
</div>
<dl class="row">
  <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3">活動名稱</dt>
  <dd class="col-6 col-sm-8 col-lg-9 pl-0">{{ currentActivity.title }}</dd>

  <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3" *ngIf="currentActivity.act_verification_code">活動驗證碼</dt>
  <dd class="col-6 col-sm-8 col-lg-9 pl-0"  *ngIf="currentActivity.act_verification_code">{{ currentActivity.act_verification_code }}</dd>

  <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3">活動建立者</dt>
  <dd class="col-6 col-sm-8 col-lg-9 pl-0">{{ currentActivity.user_name }}</dd>
  <!-- <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3">活動日期</dt>
    <dd class="col-6 col-sm-8 col-lg-9">{{ currentActivity.act_date_string}}</dd>

    <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3">活動開始時間</dt>
    <dd class="col-6 col-sm-8 col-lg-9">{{ currentActivity.act_start_time}}</dd>

    <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3">活動結束時間</dt>
    <dd class="col-6 col-sm-8 col-lg-9">{{ currentActivity.act_end_time}}</dd> -->

  <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3">開始報到時間</dt>
  <dd class="col-6 col-sm-8 col-lg-9 pl-0">
    <span *ngIf="currentActivity.checkin_before_start">
      {{ ( currentActivity.checkin_before_start === -1 ? "當日" : '活動開始前' + currentActivity.checkin_before_start + "分鐘"
      )}}
    </span>
  </dd>

  <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3">開始簽退時間</dt>
  <dd class="col-6 col-sm-8 col-lg-9 pl-0">
    <span *ngIf="currentActivity.signout_before_end">
      {{ ( currentActivity.signout_before_end === -1 ? "當日" : '結束前' + currentActivity.signout_before_end + "分鐘" )}}
    </span>
  </dd>

  <!-- <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3"> 所屬單位</dt>
    <dd class="col-6 col-sm-8 col-lg-9 pl-0">{{ currentActivity.dept_code }}</dd> -->

  <!-- <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3"> 對照節次 </dt>
    <dd class="col-6 col-sm-8 col-lg-9 pl-0">{{ currentActivity.map_school_periods ? currentActivity.map_school_periods : '' }}</dd> -->

  <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3">提供餐食</dt>
  <dd class="col-6 col-sm-8 col-lg-9 pl-0">
    <div *ngIf="currentActivity.has_meal">是</div>
    <div *ngIf="!currentActivity.has_meal">否</div>
  </dd>

  <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3" style="word-break: keep-all;">限校內人員參加</dt>
  <dd class="col-6 col-sm-8 col-lg-9 pl-0">
    <div *ngIf="currentActivity.limited_my_school">是</div>
    <div *ngIf="!currentActivity.limited_my_school">否</div>
  </dd>

  <dt class="col-6 col-sm-4 col-lg-3 px-2 px-sm-3">開放現場報名</dt>
  <dd class="col-6 col-sm-8 col-lg-9 pl-0">
    <div *ngIf="currentActivity.scene_registration">是</div>
    <div *ngIf="!currentActivity.scene_registration">否</div>
  </dd>
</dl>

<!-- 活動場次 -->
 <app-act-dates [acitivityID]="actID" [showEditButton]="true" [refreshContentKey]="refreshKey" class="mt-4"></app-act-dates>


<ng-template #actCheckDel>
  <div class="modal-content">
    <div class="modal-header justify-content-between">
      <div></div>
      <h4 class="modal-title">警告</h4>
      <button type="button" class="close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="my-2"><b>確定刪除？</b></p>
    </div>
    <div class="modal-footer justify-content-center mb-4">
      <button type="button" class="btn btn-outline-cyan mr-3" style="width: 120px;" (click)="delAct()">確認</button>
      <button type="button" class="btn btn-cyan" style="width: 120px;" (click)="modalRef.hide()">取消</button>
    </div>
  </div>
</ng-template>

<ng-template #actCanNotDel>
  <div class="modal-content">
    <div class="modal-header justify-content-between">
      <div></div>
      <h4 class="modal-title">警告</h4>
      <button type="button" class="close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="my-2"><b>此活動已有報到紀錄，不能刪除。</b></p>
    </div>
    <div class="modal-footer justify-content-center mb-4">
      <button type="button" class="btn btn-outline-cyan mr-3" style="width: 120px;"
        (click)="modalRef.hide()">確認</button>
    </div>
  </div>
</ng-template>
