<div style="font-size: 1.2rem;font-family: 'Microsoft JhengHei',PingFang;">
  <div
    class="d-flex flex-column align-items-center justify-content-center mt-4 p-4"
    >
    <div>活動名稱</div>
    <div class="w-100 pb-4" style="text-align: center; border-bottom: 1px solid lightgray;font-size: 1.5rem;font-weight: 600;">{{ currentAct ? currentAct.title : '' }}</div>

    <!-- 錯誤訊息-->
    <div
      *ngIf="errMsg"
      class="w-100 d-flex flex-row justify-content-center align-items-center py-4"
      style="border-bottom: 1px solid lightgray;"
    >
      <div class="d-flex flex-column align-items-center">
        <div style="font-size: 1.2rem; color: red">{{ errMsg }}</div>
      </div>
    </div>

    <mat-divider></mat-divider>
  </div>


    <div
      style="font-weight: 600;"
      class="d-flex flex-column align-items-center justify-content-center p-4 mt-4 mb-4"
      >
      <div>臺北市教師、學生或已申請親子綁定的家長，請下載酷課App，登入進行報到。</div>
      <a mat-stroked-button href="{{appUrl}}" class="w-100 button1" color="primmary" style="background-color: #5bb4b4; color:white; font-size: 1.2rem;margin-top: 1.2rem;">下載「酷課 App」</a>
    </div>

    <div *ngIf="!this.errMsg">
      <div
        *ngIf="!this.isNoFillForm"
        style=" margin-top: 3rem;"
        class="d-flex flex-column align-items-start justify-content-center p-4"
        >
        <div style="font-weight: 600;">非臺北師生，或家長無親子綁定，請填表單簽到</div>
        <button mat-stroked-button (click)="toFillForm()"  class="w-100 button1"  color="primmary" style="border: 1px solid #5bb4b4; color: #5bb4b4; font-size: 1.2rem;margin-top: 1.2rem;">填表單</button>
        <!-- <button class="btn" (click)="toFillForm()"  class="w-100" style="color: #5bb4b4; font-size: 1.2rem;margin-top: 1.2rem;">填表單</button> -->
        <!-- <a mat-stroked-button href="/registration?uuid={{currentAct.act_uuid}}" color="primary" class="w-100" style="border-color: #5bb4b4; color: #5bb4b4; font-size: 1.2rem;margin-top: 1.2rem;">填表單</a> -->

        <div style="color: red;font-weight: 600;">
          <div class="mt-4">請注意：</div>
          <div>臺北市教職員如選擇填表將無實名制
          簽到紀錄可認證教師研習時數。</div>
      </div>
      </div>
    </div>

</div>
