import { ShowQrCodeComponent } from './activity/qrcode/show-qr-code/show-qr-code.component';
import { RoleState } from './state/role.state';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { ActivityComponent } from './activity/activity.component';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { InfoComponent } from './activity/info/info.component';
import { QrcodeComponent } from './activity/qrcode/qrcode.component';
import { CheckinStatusComponent } from './activity/checkin-status/checkin-status.component';
import { ImportComponent } from './activity/import/import.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AddActivityComponent } from './activity/add-activity/add-activity.component';
import { NgxsModule } from '@ngxs/store';
import { UserState } from './state/user.state';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { SigninComponent } from './signin/signin.component';
import { MatButtonModule } from '@angular/material/button';
import { MainComponent } from './main/main.component';
import { MobileComponent } from './mobile/mobile.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatRadioModule} from '@angular/material/radio';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';



import { CheckinComponent } from './mobile/checkin/checkin.component';
import { RegistrationComponent } from './registration/registration.component';
import { ChooseComponent } from './registration/choose/choose.component';
import { ManageComponent } from './manage/manage.component';
import { OfficeComponent } from './manage/office/office.component';
import { AgentComponent } from './manage/agent/agent.component';
import { NopermComponent } from './manage/noperm/noperm.component';
import { NoAdminPermissionComponent } from './manage/no-admin-permission/no-admin-permission.component';
import { NamelistComponent } from './activity/import/namelist.component';
import { ImportStudentComponent } from './activity/import/import-student/import-student.component';
import { ImportTeacherComponent } from './activity/import/import-teacher/import-teacher.component';
import { ImportOthersComponent } from './activity/import/import-others/import-others.component';
import { ImportParentsComponent } from './activity/import/import-parents/import-parents.component';
import { LogoutComponent } from './logout/logout.component';
import { ActDatesComponent } from './activity/act-dates/act-dates.component';
import { AddActivityDatesComponent } from './activity/act-dates/add-activity-dates/add-activity-dates.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ManualCheckinComponent } from './activity/checkin-status/manual-checkin/manual-checkin.component';
import { ManualSignoutComponent } from './activity/checkin-status/manual-signout/manual-signout.component';
import { CheckHistoryComponent } from './mobile/check-history/check-history.component';
import { PeriodHoursComponent } from './activity/period-hours/period-hours.component';
import { UploadInscComponent } from './activity/upload-insc/upload-insc.component';
import { getzhPaginatorIntl } from './core/zh-paginator-intl';
import { EditIssueHoursComponent } from './activity/checkin-status/edit-issue-hours/edit-issue-hours.component';

// import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';



@NgModule({
  declarations: [
    AppComponent,
    ActivityComponent,
    ActivityListComponent,
    InfoComponent,
    QrcodeComponent,
    CheckinStatusComponent,
    ImportComponent,
    AddActivityComponent,
    SigninComponent,
    MainComponent,
    MobileComponent,
    CheckinComponent,
    RegistrationComponent,
    ChooseComponent,
    ManageComponent,
    OfficeComponent,
    AgentComponent,
    NopermComponent,
    NoAdminPermissionComponent,
    NamelistComponent,
    ImportStudentComponent,
    ImportTeacherComponent,
    ImportOthersComponent,
    ImportParentsComponent,
    LogoutComponent,
    ActDatesComponent,
    AddActivityDatesComponent,
    ShowQrCodeComponent,
    ManualCheckinComponent,
    ManualSignoutComponent,
    CheckHistoryComponent,
    PeriodHoursComponent,
    UploadInscComponent,
    EditIssueHoursComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    HttpClientModule,
    NgxsModule.forRoot([UserState, RoleState]),
    ModalModule.forRoot(),
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    MatCheckboxModule,
    MatSnackBarModule,
    NgxQRCodeModule,
    MatButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatTabsModule,
    MatRadioModule,
    MatGridListModule,
    MatCardModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getzhPaginatorIntl() }   // 分頁元件的畫面顯示中文字翻譯
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
