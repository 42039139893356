import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'querystring';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(
    private http: HttpClient
  ) { }

  public register(identity: string,
                  act_uuid: string,
                  name: string,
                  role: string,
                  stud_no: string,
                  schoolName: string,
                  schoolCode: string,
                  schoolType: string,
                  className: string,
                  seatNo: string): Observable<any> {
    const url = `/service/signup/register`;
    const data = {identity: identity,
                  act_uuid: act_uuid,
                  name: name,
                  role: role,
                  stud_no: stud_no,
                  school_name: schoolName,
                  school_code: schoolCode,
                  school_type: schoolType,
                  class_name: className,
                  seat_no: seatNo};
    return this.http.post(url, data);
  }

  public registerOthers( act_uuid: string, name: string, telNo: string): Observable<any> {
    const url = `/service/signup/regOthers`;
    const data = {
                  act_uuid: act_uuid,
                  name: name,
                  tel_no: telNo};
    return this.http.post(url, data);
  }

  public getSignupRecords( act_uuid: string): Observable<SignupRecord[]> {
    const url = `/service/signup/getSignupRecords?act_uuid=${act_uuid}`;

    return this.http.get(url).pipe(
      map( v => v as SignupRecord[] )
    );
  }

  /** 取得使用者在指定活動指定角色的報名紀錄 */
  public getMyActRegRecords( act_uuid: string, access_token: string, role_type: string): Observable<SignupRecord[]> {
    // console.log(ids);
    const url = `/service/checkin/getMyActRegRecords/${act_uuid}?access_token=${access_token}&role_type=${role_type}`;
    return this.http.get(url).pipe(
      map( v => v as SignupRecord[] )
    );
  }

  public removeSignupRecords( act_uuid, ids: any[]): Observable<any> {
    console.log(ids);
    const url = `/service/signup/remove/${act_uuid}`;
    return this.http.post(url, ids);
  }
}

export interface SignupRecord {
  id: string;
  act_uuid: string;
  school_code: string;
  school_type: string;
  role: string;
  other_info: any;
  create_time: string;
  class_name: string;
  seat_no: string;
  tel_no: string;
  name: string;
  identity: string;
  school_name: string;
  stud_no: string;
  checked: boolean;   // 僅在前端運算，確定使用者是否選取這筆紀錄。
  role_text: string;  // 僅在前端運算，角色的中文。
}
