import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { textChangeRangeIsUnchanged } from 'typescript';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(
    private http: HttpClient
  ) { }

  public getMyActivities(schoolCode: string, schoolType: string, dept: string) {
    schoolType = (schoolType || '');
    const url = `/service/activity?schoolCode=${schoolCode}&schoolType=${schoolType}&dept=${dept}`;
    return this.http.get(url).pipe(
      map(response => response as ActListRecord)
      );
    }

  // public getAllActivity(): Observable<ActivityRecord[]> {
  //   return this.http.get('/service/activity').pipe(
  //     map(response => response as ActivityRecord[])
  //     );
  //   }

  // public async getAllActList(pageIndex: number, pageSize: number) {
  //   // return this.http.get(`/service/activity?pageIndex=${pageIndex}&pageSize=${pageSize}`).toPromise();
  //   const url = `/service/activity?pageIndex=${pageIndex}&pageSize=${pageSize}`;
  //   return this.http.get(url).pipe(
  //     map(response => response as ActListRecord)
  //     ).toPromise();

  // }


  public getActByID(actID: number): Observable<ActivityRecord> {
    return this.http.get(`/service/activity/${actID}`).pipe(
      map(response => response as ActivityRecord)
    );
  }

  public getActByUUID(actUUID: string): Observable<ActivityRecord> {
    return this.http.get(`/service/activity/getByUUID?uuid=${actUUID}`).pipe(
      map(response => response as ActivityRecord)
    );
  }

  public canBeDeleted(actUUID: string): Observable<any> {
    return this.http.get(`/service/activity/canBeDeleted?uuid=${actUUID}`);
  }

  public addAct(act: ActivityRecord): Observable<any> {
    return this.http.post(`/service/activity/add`, act);
  }

  public updateAct(act: ActivityRecord): Observable<any> {
    return this.http.put(`/service/activity/update/`, act);
  }

  public getStatistics(act: ActivityRecord): Observable<any> {
    return this.http.get(`/service/activity/statistics/${act.id}`);
  }

  public getCheckinStatus(actUuid: string, currentDate: string): Observable<CheckInStatusRecord[]> {
    return this.http.get(`/service/activity/checkin_list/${actUuid}?act_date=${currentDate}`).pipe(
      map( v =>  v as CheckInStatusRecord[] )
    );
  }

  public getCheckinStatusByPeriod(actUuid: string): Observable<PeriodCheckinStatistics[]> {
    return this.http.get(`/service/activity/checkin_statistics_by_periods/${actUuid}`).pipe(
      map( v =>  v as PeriodCheckinStatistics[] )
    );
  }

  public deleteAct(actUuid: string): Observable<any> {
    return this.
    http.delete(`/service/activity/${actUuid}`);
  }

  public getCheckInCount(actUuid: string): Observable<any> {
    return this.
    http.get(`/service/activity/get_checkin_count/${actUuid}`);
  }

  public getActPeriodsByActID(actID: number): Observable<ActivityPeriod[]> {
    return this.http.get(`/service/activity/${actID}/periods`).pipe(
      map(response => response as ActivityPeriod[])
    );
  }

  public setActPeriods(actId: string, actPeriods: ActivityPeriod[]): Observable<any> {
    return this.http.put(`/service/activity/${actId}/period`, actPeriods);
  }

  public deleteActPeriod(actId: string, actPeriodID: string): Observable<any> {
    return this.http.delete(`/service/activity/${actId}/period/${actPeriodID}`);
  }

  // 從報名名單進行人工報到
  public manualCheckInFromSignup(actUuid: string, periodDate: string, hasMeal: boolean,  signupIDs: string[]) {
    const body = {
      period_date: periodDate,
      has_meal: hasMeal,
      signupIDs
    };
    return this.http.post(`/service/activity/${actUuid}/manual_checkin_from_signup_record`, body);
  }

  // 進行人工報到
  public manualCheckIn(actUuid: string, periodDate: string, hasMeal: boolean, studs: any[]) {
    const body = {
      period_date: periodDate,
      has_meal: hasMeal,
      studs
    };
    return this.http.post(`/service/activity/${actUuid}/manual_checkin`, body);
  }

  // 進行人工報到(教師)
  public manualCheckInTeacher(actUuid: string, periodDate: string, hasMeal: boolean, teas: any[]) {
    const body = {
      period_date: periodDate,
      has_meal: hasMeal,
      teas
    };
    return this.http.post(`/service/activity/${actUuid}/manual_checkin`, body);
  }

  // 手動取消報到
  public manualCancelCheckIn(actUuid: string, checkinID: string[]) {
    const body = {
      checkin_ids: checkinID
    };
    return this.http.post(`/service/activity/${actUuid}/manual_cancel_checkin`, body);
  }

  // 批次簽退
  public manualSignout(actUuid: string, periodDate: string, checkInIDs: any[]) {
    const body = {
      period_date: periodDate,
      checkin_ids: checkInIDs
    };
    // console.log(body);
    return this.http.post(`/service/activity/${actUuid}/manual_signout`, body);
  }

  // 手動取消報到
  public manualCancelSignout(actUuid: string, checkInIDs: string[]) {
    const body = {
      checkin_ids: checkInIDs
    };
    return this.http.post(`/service/activity/${actUuid}/manual_cancel_signout`, body);
  }

  // 手動取消報到
  public updateIssuedHours(actUuid: string, checkInID: string, oldHours: number, newHours: number) {
    const body = {
      checkInID , oldHours , newHours
    };
    return this.http.post(`/service/activity/${actUuid}/update_issued_hours`, body);
  }

  // 觸發角色成長的trigger
  public trigger(data: {id_number: string, condition_code: string, verification_code: string}) {
    return fetch(`https://encourage.tp.edu.tw/service/activity/trigger`, {
      method: 'POST',    
      body: JSON.stringify(data)
    });
    // console.log(data);
    // return this.http.post(`https://encourage.tp.edu.tw/service/activity/trigger`, JSON.stringify(data));
  }

  getWeekdayString(weekDay: number) {
    let result = '' ;
    if (weekDay === 0) { result = '日'; }
    if (weekDay === 1) { result = '一'; }
    if (weekDay === 2) { result = '二'; }
    if (weekDay === 3) { result = '三'; }
    if (weekDay === 4) { result = '四'; }
    if (weekDay === 5) { result = '五'; }
    if (weekDay === 6) { result = '六'; }
    return result ;
  }
}

export interface ActListRecord {
  // length: number;
  years: number[];
  list: ActivityRecord[];
}

export class ActivityRecord {
  act_uuid: string;
  id: number ;
  title: string ;
  dept_code: string;
  dept_name: string;  // 從用戶端比對出來
  school_code: string;
  school_type: string;
  school_name: string;

  act_date: Date;
  act_date_string: string;
  is_repeat = false; // 是否重複性活動
  // repeat_mode: number;
  repeat_end_date: Date;  // 結束日期
  repeat_end_date_string: string;  // 結束日期

  checkin_before_start = 60;   // 活動開始前多久可以報到
  need_sign_out = false;         // 是否要簽退
  signout_before_end = 30 ;    // 活動結束前多久可以簽退

  limited_my_school = false;  // 僅限本校人員參加
  scene_registration = true;  // 開放現場報名
  has_meal = false;           // 是否提供餐點

  act_periods: ActivityPeriod[];  // 活動的所有節次 (日期節次展開的結果)。
  act_periods_meta: ActivityPeriodMeta[];   // 活動節次的定義。如果

  last_update: Date;
  server_time: Date;

  user_id: string;
  user_name: string;

  allow_accumulated_hours: boolean; /* 核發時數時要累積場次 */
  cannot_accumulated_hours: boolean; /* 核發時數不要累積場次，僅擇優單一場次。 此欄位和前一個欄位 「hours_allow_accumulated」實際上是相斥的欄位，理論上不需此欄位。但為了畫面上主任需要的效果，所以才加上此欄位。 */

  cert_need_signout: boolean;  /** 在計算使用者在此活動的有效場次數量時，是否每場次都需簽退才算入有效場次 */
  cert_need_full_attendance: boolean;  /* 需要全勤才核發研習證明 */
  cert_allow_absence: boolean;  /* 不需要全勤才核發研習證明。此欄位和前一個欄位 「cert_need_full_attendance」實際上是相斥的欄位，理論上不需此欄位。但為了畫面上主任需要的效果，所以才加上此欄位。 */
  cert_absence_limit: number ;    /* 若不需全勤，那允許缺席多少場次以內才核發研習證明 */

  is_from_api: boolean;

  // total_hours: string; //累計時數
  // once_hours: string; //一場時數

  // rule_have_checkout: string //必須完成簽退
  // rule_all_checkin: string //全數場次均參加
  // rule_less_checkin: string //可缺席
  // less_times: string //可缺席次數

  map_school_periods: string; // 此欄位應該不需要了！
  act_start_time: string; // 此欄位應該不需要了！
  act_end_time: string; // 此欄位應該不需要了！

  role_permitted: RolePermittedInfo = { teacher: true, parent: true, student: true};  // 此活動允許哪些角色參加，預設全部參加。
  api_client_id: string;  // 從 api 建立的活動，要紀錄其 client_id
  api_client_name: string;  // 從 api 建立的活動，要紀錄其 client_name
  act_verification_code: string;
  verification_code?: string;
}

export class RolePermittedInfo {
  teacher = true;
  parent = true ;
  student = true ;
}

// 活動節次的設定。根據設定內容可展開所有活動節次日期
export class ActivityPeriodMeta {
  start_time: string ;
  end_time: string;
  weekdays: WeekdaySelectedMeta = new WeekdaySelectedMeta(); // 一星期中哪幾天

  have_issued_hours: boolean ;  // 是否核發時數，20220419 新增需求，配合修改活動畫面上的 「3.活動時間」的是否「核發時數」。如果是，則顯示要核發的時數。
  have_section: boolean ;  // 設定對應校務節次，20220419 新增需求，配合修改活動畫面上的 「3.活動時間」的是否「設定對應校務節次」。如果是，則顯示要對應的節次。

  issued_hours: number;   // 核發時數
  start_period: number;   // 開始節次
  end_period: number;     // 結束節次
}

//
export class WeekdaySelectedMeta {
  Mon: boolean;
  Thu: boolean;
  Wed: boolean;
  Tue: boolean;
  Fri: boolean;
  Sat: boolean;
  Sun: boolean;
}


/** 活動的某一次週期 */
export class ActivityPeriod {
  id: number ;
  act_date: Date ;        // 活動日期
  act_start_time: string; // 開始時間
  act_end_time: string;   // 結束時間
  // map_school_periods: number[]; // 對應公假節次  // 這欄位要更換到查詢報到狀況時才設定。
  ref_activity_id: number;  // 對照的活動編號
  last_update: Date ;       // 最後更新時間
  act_date_string: string;  // 活動日期的字串格式
  weekday_string: string;   // 星期節次文件, 用戶端計算
  act_date_mmdd: string;  // 活動日期的字串格式

  issued_hours: number;   // 核發時數
  start_period: number;   // 開始節次
  end_period: number;     // 結束節次

  will_issue_hours: boolean;  // 是否會核發時數
  will_set_periods: boolean;  // 是否會設定開始結束節次

  insc_code: string;  // 教師研習網對應的課程代碼
}

/** 活動某週期的報到簽退葷素人數統計 */
export class PeriodCheckinStatistics {
  checkin_date: string ;   // 活動日期
  checkin_count: number;   // 報到人數
  signout_count: number;   // 簽退人數
  meat_count: number;      // 葷食人數
  veg_count: number;       // 素食人數
}

export class CheckInStatusRecord {
  id: string;
  school_name: string;
  school_code: string;
  school_type: string;
  name: string;
  id_no: string;
  role: string;
  tel_no: string;
  class_name: string;
  seat_no: string;
  checkin_type: string;
  checkin_time: Date;
  signout_time: Date;
  signout_type: string;
  signup_id: number ;
  meal_type: string;  // 餐點，葷、素
  child_name: string;
  stud_number: string;
  issued_hours: number;   // 核發時數
  checkin_time_string: string;    // 前端處理用
  signout_time_string: string;    // 前端處理用
  role_text: string;              // 前端處理用
  checkin_type_text: string;              // 前端處理用, 把報到類型轉成中文字


}

export class triggerQuery {
  id_number: string;
  condition_code: string;
  verification_code: string;
}
