import { Injectable } from '@angular/core';
import { State, StateContext, Action } from '@ngxs/store';
import { UserRoleRec } from '../data';
import { SetCurRole } from './role.action';

@State<UserRoleRec>({
    name: 'role',
    defaults: {} as UserRoleRec
})
@Injectable()
export class RoleState {

    @Action(SetCurRole)
    setCurRole(ctx: StateContext<UserRoleRec>, action: SetCurRole) {
        const state = ctx.getState();
        // ctx.setState(state);
        ctx.setState(action.role);
    }
}
