<div class="d-flex flex-column">
  <header class="navbar navbar-expand-lg py-3 px-2 border-bottom border-custom">
    <div class="cocontainer">
      <div class="d-flex justify-content-between align-items-center">
        <!-- <button aria-controls="navbarTogglerDemo01"
                  class="btn btn-link navbar-toggler"
                  type="button"
                  aria-haspopup="true"
                  (click)="openNav()">
            <img src="../assets/img/menu.svg"
                 alt="image" />
          </button>-->
        <div class="d-flex align-items-center mr-auto">
          <img class="cologo" src="../assets/img/logos.png" alt="酷課雲 Logo" />
          <h4 class="align-self-center" style="word-break: keep-all;">掃碼登記</h4>
        </div>
        <div class="dropdown loginp">
          <a aria-controls="dropdown-basic" class="btn-out-link dropdown-toggle" href="javascript:;"
            data-toggle="dropdown" aria-haspopup="true">
            <table>
              <tbody>
                <tr>
                  <td rowspan="2">
                    <img class="user-photo mr-2" src="../assets/img/avatar/b02@1x.png" />
                  </td>
                  <td class="text-left">
                    <!-- <div class="username"> {{ curRole.dept || '' }} {{ curRole.unit || ''}} {{ curRole.title || ''}}</div> -->
                    <div class="username"> {{ userInfo.name || '' }}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="schname">
                      <!-- <span> {{currentClass?.schoolName}} </span> -->
                      <span>
                        {{ curRole.schoolName || "查無身分" }}
                      </span>
                      <span class="caret"></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </a>
          <div class="dropdown-menu dropdown-menu-right scrolly rounded-xl" id="dropdown-basic" role="menu"
            aria-labelledby="button-basic">

            <button class="dropdown-item" *ngFor="let role of myRoles; let i = index">
              <input type="radio" class="form-check-input" name="sizeSel" id="sizel{{i}}" [checked]="i==0">
              <label for="sizel{{i}}" class="dropdown-item d-flex align-items-center" (click)="setCurRole(role)">
                <span></span>
                <div style="width: 200px; white-space: pre-line; word-break: keep-all;">{{ role.schoolName+' ' }}</div>
              </label>
            </button>
            <hr style="margin: 5px;">
            <div class="dropdown-item d-flex">
            <img _ngcontent-leb-c2="" alt="" src="/assets/img/Signout.svg" class="mx-1">
            <button class="dropdown-item drop-link" (click)="logout()">登出
            </button></div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- <section class="container p-0">
    <nav class="navbar navbar-expand-lg p-3 fixed-top">
      <div class="container">
        <a class="navbar-brand mr-auto" [routerLink]="['/coocmsg']">
          <img src="/assets/img/logo.png" alt="臺北掃碼報到" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="mdi mdi-account-circle"></span>
        </button>
      </div>
      <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarNavDropdown"
        *ngIf="this.myRoles.length > 0"
      >
        <div class="dropdown">
          <button
            class="btn btn-light btn-r30 dropdown-toggle"
            type="button"
            id="roleMenu"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="mdi mdi-account-circle"></span>
            {{ curRole.schoolName || "查無身分" }}
            {{ curRole.schoolType ? "(" + curRole.schoolType + ")" : "" }}
          </button>
          <div class="dropdown-menu" aria-labelledby="roleMenu">
            <button
              class="dropdown-item"
              *ngFor="let role of myRoles"
              (click)="setCurRole(role)"
            >
              {{ role.schoolName + "(" + role.schoolType + ")" }}
            </button>
            <button class="dropdown-item" (click)="logout()">登出系統</button>
          </div>
        </div>
      </div>
    </nav>
  </section> -->
  <section class="bg-light" style="padding: 1rem 0 1rem;">
    <div class="container d-flex align-items-center">
      <h3>
        <button class="btn btn-light mb-0 mr-3" (click)="goBack()" >
          <i class="material-icons mr-1" >arrow_back_ios</i>
          返回
        </button>
        <span class="ml-4" style="font-size: 1.4rem;"> 後台管理 </span>
      </h3>
    </div>
  </section>

  <div
    *ngIf="isLoading"
    class="alert alert-primary p-4 text-center"
    role="alert"
  >
    資料載入中
  </div>

  <div *ngIf="!isLoading" class="cocontainer">
    <div class="mt-4 " *ngIf="this.myRoles.length > 0">
      <mat-tab-group class="m-0" style="margin-left: 6%;margin-right: 6%;">
        <mat-tab
          label="處室"
          class="p-4"
          *ngIf="this.curRole.isAdmin"
          style="font-size: 1.2rem"
        >
          <div class="p-2">
            <app-office
              [schoolCode]="this.curRole.schoolCode"
              [schoolType]="this.curRole.schoolType"
              [schoolDepts]="this.allSchoolDepts"
            ></app-office>
          </div>
        </mat-tab>
        <!-- <mat-tab
          label="副管理員"
          class="p-4"
          *ngIf="this.curRole.isAdmin || this.curRole.isAgent"
        >
          <div class="p-2">
            <app-agent
              [schoolCode]="this.curRole.schoolCode"
              [schoolType]="this.curRole.schoolType"
              [schoolTeachers]="this.ap2Teachers"
            ></app-agent>
          </div>
        </mat-tab> -->
      </mat-tab-group>
    </div>

    <div
      class="p-4 d-flex flex-column justify-content-center align-items-center"
      style="height: 60vh"
      *ngIf="this.myRoles.length === 0"
    >
      <div style="font-size: 1.3rem" class="pb-4">
        您沒有管理權限，請重新登入
      </div>
      <button mat-raised-button color="primary" (click)="reLogin()">
        重新登入
      </button>
    </div>
  </div>
</div>
