<section id="scanList" class="container" page style="padding-top: 2rem;">
  <div class="d-flex flex-wrap flex-column flex-md-row align-items-center justify-content-center">
    <div class="d-flex flex-wrap justify-content-center">
      <select [(ngModel)]="curYear" class="form-control mb-3" style="width: 120px;"
        (ngModelChange)="changeYear($event)">
        <option [ngValue]="0">全部活動</option>
        <option *ngFor="let yr of years" [ngValue]="yr">
          {{yr}}年
        </option>
        <!-- <option value="2021" selected>2021年</option>
          <option value="2020">2020年</option> -->
      </select>

      <select [(ngModel)]="isFromAPI" class="form-control mx-2 mb-3" style="width: 120px;"
        (ngModelChange)="changeAPIClient($event)">
        <option [ngValue]="'0'">全部顯示</option>
        <option [ngValue]="'1'">外部系統</option>
        <!-- <option *ngFor="let client of apiClients" [ngValue]="client">
            {{ client }}
        </option> -->
        <!-- <option value="2021" selected>2021年</option>
          <option value="2020">2020年</option> -->
      </select>

      <div class="nnsearch mr-2 mb-3">
        <input class="form-control day-of-week" id="nnsearch" placeholder="活動名稱關鍵字"
          (keyup.enter)='searchActivities($event)' [(ngModel)]="searchString">
      </div>
    </div>
    <button class="btn btn-cyan ml-md-auto mb-3" style="min-width: auto;" data-toggle="modal" data-target="#addModal"
      (click)="addActivity('新增')">
      新增活動
    </button>


    <!-- <button class="btn btn-cyan ml-2" *ngIf="isAdmin" (click)="ShowManage()">
        <span class="mdi mdi-settings"></span>後台管理
      </button> -->
  </div>

  <div class="table-responsive" *ngIf="filteredActivities && filteredActivities.length > 0">

    <table class="table-rwd table text-md-center">
      <thead class="thead-light">
        <tr style="border-top:0!important;">
          <th class="text-nowrap">活動來源</th>
          <th>活動日期</th>
          <th>活動名稱</th>
          <th>QR Code</th>
          <!-- <th >QR<br/>(報到)</th>
          <th >QR<br/>(簽退)</th> -->
          <!-- <th >單位</th> -->
          <!-- <th >公假節次</th> -->
          <!-- <th >限校內</th> -->
          <!-- <th >現場報名</th> -->
          <th class="text-nowrap">餐點</th>
          <th class="text-nowrap">建立者</th>
          <th class="text-nowrap">活動資訊</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let act of filteredActivities">
          <td data-th="API建立">
            {{ act.api_client_name || (act.is_from_api ? '是' : '') }}
          </td>
          <td data-th="活動時間" (click)="CurrentAct(act)" class="pointer">
            <span>{{act.act_date_string}}</span>
            <span *ngIf="act.is_repeat"> ~ </span>
            <span *ngIf="act.is_repeat">{{ act.repeat_end_date | date: 'yyyy-MM-dd' }}</span>
          </td>
          <td data-th="活動名稱" (click)="CurrentAct(act)" class="pointer">
            {{ act.is_expired ? " * " : '' }}{{act.title}}
          </td>
          <td data-th="QR Code" class="text-nowrap">
            <span class="btn-outline-custom mr-3" (click)="ShowCheckInQR(act)">報到</span>
            <span class="btn-outline-custom" *ngIf="act.need_sign_out" (click)="ShowSignoutQR(act)">簽退</span>
          </td>
          <!-- <td data-th="簽退" ><span class="btn" *ngIf="act.need_sign_out" (click)="ShowSignoutQR(act)">簽退</span></td> -->
          <!-- <td data-th="單位" >{{ act.dept_name }}</td> -->
          <!-- <td data-th="公假節次" >{{ act.map_school_periods ? act.map_school_periods.join(",") : '' }}</td>
          <td data-th="限校內" >
              <div *ngIf="act.limited_my_school"><i class="mdi mdi-check"></i></div>
          </td>
          <td data-th="現場報名" >
              <div *ngIf="act.scene_registration"><i class="mdi mdi-check"></i></div>
          </td> -->
          <td data-th="餐點">
            <div *ngIf="act.has_meal">是</div>
            <div *ngIf="!act.has_meal">否</div>
          </td>
          <td data-th="建立者">{{ act.user_name }}</td>
          <td data-th="活動資訊">
            <span class="btn-outline-custom" (click)="ShowStatisticsCount(act)">查看</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row justify-content-center" style="margin-top:100px;margin-bottom:100px;"
    *ngIf=" filteredActivities && filteredActivities.length == 0">
    <img src="/assets/img/lazy_orange.svg" alt="">
    <p class="text-black-50">查無相關資料，<br />請點選「新增活動」！</p>
  </div>

  <!--  -->
  <!-- <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageIndex]="0" [pageSizeOptions]="pageSizeOptions" (page)="changePage($event)">
    </mat-paginator> -->
</section>
