import { ActivityPeriod } from 'src/app/core/activity.service';
import { UserRoleRec } from './../../data/role';
import { ActivityPeriodMeta, ActivityRecord, ActivityService } from './../../core/activity.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { ActStateService } from '../act-state.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import $ from 'jquery';
import { ActivityNotificationServiceService } from '../activity-notification-service.service';
import { PeriodHoursComponent } from '../period-hours/period-hours.component';
import { filter, take } from 'rxjs/operators';
// import { constants } from 'buffer';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { zhCnLocale } from 'ngx-bootstrap/locale';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss']
})
export class AddActivityComponent implements OnInit {
  editorMode: string;
  curRole: UserRoleRec = {} as UserRoleRec;

  isRepeat = false;
  repeatMode = 0;  // 不重複

  id: number;
  isFromApi = false;   // 此活動是否由 API 設定進來？

  allAct: ActivityRecord[] = [];
  rawAct: ActivityRecord; // 複製過來的 .......
  currentAct: ActivityRecord;

  fgActEditor: FormGroup;
  title = new FormControl('', [Validators.required]);
  actDate = new FormControl('', [Validators.required]);
  StartTime: Date;
  EndTime: Date;
  startTime = '';
  ActivityService: ActivityService;
  // minDate = moment('2020-11-20');
  minDate: Date;

  myDateValue: Date = new Date();

  selectCheckinTimeFormControl: FormControl = new FormControl('60');
  selectSignoutTimeFormControl: FormControl = new FormControl('30');

  // snackBar: any;
  // actDate: Date;
  // title: string;

  act_start_date: any = '2021-07-01';
  act_end_date: any = '';

  act_start_date_new: Date;
  act_end_date_new: Date;

  chkMoreDate = false;
  starTime;
  endTime;
  weekdays = [{ name: 'Mon', text: '一' },
  { name: 'Tue', text: '二' },
  { name: 'Wed', text: '三' },
  { name: 'Thu', text: '四' },
  { name: 'Fri', text: '五' },
  { name: 'Sat', text: '六' },
  { name: 'Sun', text: '日' }];
  weekdayEngs = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  needCheckout = false;  // 是否簽退
  not_scene_reg = false;       // 不開放現場報名
  limit_in_school = false;      // 限校內人員參加

  custom_checkin_before_start = 60;
  custom_checkin_before_start_tag = -999;
  checkin_before_start_values = [30, 60, 90, -1];

  custom_signout_before_end = 30;
  custom_signout_before_end_tag = -999;
  signout_before_end_values = [10, 20, 30, -1];

  roleContraint = {
    teacher: true,
    parent: true ,
    student: true
  };


  constructor(
    private modalService: BsModalService,
    private actService: ActivityService,
    private modalRef: BsModalRef,
    private currentRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private actStateSrv: ActStateService,
    private activityNotificationService: ActivityNotificationServiceService,
    private localeService: BsLocaleService

  ) {
    this.minDate = new Date();

  }

  async ngOnInit() {

    this.currentRoute.url.subscribe(async val => {
      const path = val[0].path;
      if (this.editorMode === 'edit') {
        // this.currentAct = this.ActivityService.getByID(act.id);
        this.editorMode = '修改';
        this.rawAct = this.actStateSrv.getCurrentAct();
        this.currentAct = $.extend(true, {}, this.rawAct);  // 要深層複製另一個物件，才不會改到原來的物件。
        this.act_start_date = moment(this.currentAct.act_date).format('YYYY-MM-DD'); // 要放到一個變數，這樣畫面的 input type="date" 預設值才會顯示
        this.act_start_date_new = moment(this.act_start_date).toDate();
        this.act_end_date = moment(this.currentAct.repeat_end_date).format('YYYY-MM-DD');
        this.act_end_date_new = moment(this.act_end_date).toDate();
        this.currentAct.scene_registration = !!this.currentAct.scene_registration;

        // 判斷是否報到時間是否是自訂
        if (this.checkin_before_start_values.indexOf(this.currentAct.checkin_before_start) < 0) {
          this.custom_checkin_before_start = this.currentAct.checkin_before_start;
          this.currentAct.checkin_before_start = -999;
        }

        // 判斷簽退時間是否是自訂
        if (this.signout_before_end_values.indexOf(this.currentAct.signout_before_end) < 0) {
          this.custom_signout_before_end = this.currentAct.signout_before_end;
          this.currentAct.signout_before_end = -999;
        }

        if (!this.currentAct.act_periods_meta) {
          this.addEmptyActivityPeriodMeta();   // 預設會有一個
        }

      } else {
        // this.editorMode = '新增';
        this.currentAct = new ActivityRecord();
        this.addEmptyActivityPeriodMeta();
        this.act_start_date = moment().format('YYYY-MM-DD'); // 要放到一個變數，這樣畫面的 input type="date" 預設值才會顯示
        this.act_start_date_new = moment(this.act_start_date).toDate();
        this.act_end_date = moment().add(1, 'months').format('YYYY-MM-DD');  // 預設一個月
        this.act_end_date_new = moment(this.act_end_date).toDate();
        this.currentAct.checkin_before_start = 60;  // default value
        this.currentAct.signout_before_end = -1;    // default value
        this.currentAct.scene_registration = true; // default value
      }
    });
    // console.log(this.minDate);
    const zhTWLocale = { ...zhCnLocale };
    zhTWLocale.weekdaysShort = ["日", "一", "二", "三", "四", "五", "六"];
    defineLocale('zh-tw', zhTWLocale);
    this.localeService.use('zh-tw');

  }

  addEmptyActivityPeriodMeta() {
    const apm = new ActivityPeriodMeta();
    apm.start_time = '09:00';
    apm.end_time = '11:00';
    apm.issued_hours = 0;
    apm.start_period = 0;
    apm.end_period = 0;
    apm.have_issued_hours = false;
    apm.have_section = false;
    if (!this.currentAct.act_periods_meta) {
      this.currentAct.act_periods_meta = [];
    }
    this.currentAct.act_periods_meta.push(apm);
  }

  closeModal() {
    this.modalRef.hide();
    // this.modalRef = undefined ;
  }


  /** 儲存活動 */
  async saveAct() {
    // if (this.fgActEditor.valid) {
    try {

      this.validateData();  // 驗證資料合理性，若有問題會丟出 Exception.

      this.currentAct.act_date = this.act_start_date_new;
      this.currentAct.act_date_string = moment(this.act_start_date_new).format('YYYY-MM-DD');
      this.currentAct.repeat_end_date = this.currentAct.is_repeat ? this.act_end_date_new : undefined;
      this.currentAct.repeat_end_date_string = this.currentAct.is_repeat ? moment(this.act_end_date_new).format('YYYY-MM-DD') : '';

      // this.currentAct.act_date = moment(this.act_start_date).toDate();
      // this.currentAct.act_date_string = this.act_start_date;
      // this.currentAct.repeat_end_date = this.currentAct.is_repeat ? this.act_end_date : '' ;

      if (!this.currentAct.is_from_api) { // 如果不是第三方建立的活動，儲存時才重新建立活動日期。
        this.currentAct.act_periods = this.expandActivityDates();
      }


      // 判斷是否自訂報到時間
      if (this.currentAct.checkin_before_start.toString() === this.custom_checkin_before_start_tag.toString()) {
        this.currentAct.checkin_before_start = this.custom_checkin_before_start;
      }

      // 判斷簽退
      if (!this.currentAct.need_sign_out) {
        this.currentAct.signout_before_end = -1;
      } else {
        // 判斷是否自訂簽退時間
        if (this.currentAct.signout_before_end.toString() === this.custom_signout_before_end_tag.toString()) {
          this.currentAct.signout_before_end = this.custom_signout_before_end;
        }
      }

      if (this.currentAct.act_uuid) { // 修改
        console.log(this.currentAct);
        await this.actService.updateAct(this.currentAct).toPromise();
        this.modalService.setDismissReason('data edit OK');

      } else {   // 新增
        this.currentAct.school_code = this.curRole.schoolCode;
        this.currentAct.dept_code = this.curRole.deptCode;   // 按照目前使用者選擇的身份對應的處室代碼
        this.currentAct.dept_name = this.curRole.dept;
        this.currentAct.school_type = this.curRole.schoolType;
        this.currentAct.school_name = this.curRole.schoolName;
        // this.currentAct.act_date_string = this.act_start_date;

        await this.actService.addAct(this.currentAct).toPromise();

        console.log(this.currentAct);

        this.modalService.setDismissReason('data saved');
      }
      this.modalRef.hide();

    } catch (error) {
      let msg = '';
      if (error.message === 'no_title') {
        msg = '請填寫活動名稱';
      } else if (error.message === 'error_start_time') {
        msg = '活動開始時間不可早於結束時間';
      } else if (error.message === 'error_date') {
        msg = '活動結束日期不能早於開始日期！';
      } else if (error.message === 'error_end_date') {
        msg = '活動開始日期有誤';
      } else {
        msg = error.message;
      }
      this.snackBar.open(`提醒您`, msg, {
        duration: 3000,
      });
    }
  }

  // 展開活動的日期時段
  expandActivityDates(): ActivityPeriod[] {
    const result: ActivityPeriod[] = [];
    // 1. 如果只有一天，則活動開始日就是活動日。
    if (!this.currentAct.is_repeat) {
      const ap = new ActivityPeriod();
      ap.act_date = this.currentAct.act_date;
      ap.act_date_string = this.currentAct.act_date_string;
      ap.act_start_time = this.currentAct.act_periods_meta[0].start_time;
      ap.act_end_time = this.currentAct.act_periods_meta[0].end_time;
      ap.act_end_time = this.currentAct.act_periods_meta[0].end_time;

      ap.issued_hours = this.currentAct.act_periods_meta[0].issued_hours;
      ap.start_period = this.currentAct.act_periods_meta[0].start_period;
      ap.end_period = this.currentAct.act_periods_meta[0].end_period;

      ap.will_issue_hours = this.currentAct.act_periods_meta[0].have_issued_hours ;
      ap.will_set_periods = this.currentAct.act_periods_meta[0].have_section ;

      result.push(ap);
    } else {
      // 2. 如果該活動有多個日期，則從活動開始日到活動結束日，找出每一天是星期幾，然後比對每個 meta 中是否有選擇，若有，則建立週期物件 (ActivityPeriod)
      const mntActStartDate = moment(this.act_start_date_new);
      const mntActEndDate = moment(this.act_end_date_new);
      // const mntActStartDate = moment(this.act_start_date);
      // const mntActEndDate = moment(this.act_end_date) ;
      // console.log({ mntActStartDate, mntActEndDate });
      let index = 0;
      let indexDate = mntActStartDate;
      while (indexDate.toDate().getTime() <= mntActEndDate.toDate().getTime()) {
        const weekDayEng = this.weekdayEngs[indexDate.day()];
        // console.log(` index: ${index} ,${indexDate.format('YYYY-MM-DD')} , ${weekDayEng}`);
        // console.log( { act_period_meta : this.currentAct.act_periods_meta });

        this.currentAct.act_periods_meta.forEach(meta => {
          if (meta.weekdays[weekDayEng]) {
            // console.log(` date: ${indexDate.format('YYYY-MM-DD')} matched ${weekDayEng}`);
            const ap = new ActivityPeriod();
            ap.act_date = indexDate.toDate();
            ap.act_date_string = indexDate.format('YYYY-MM-DD');
            ap.act_start_time = meta.start_time;
            ap.act_end_time = meta.end_time;

            ap.issued_hours = meta.issued_hours;
            ap.start_period = meta.start_period;
            ap.end_period = meta.end_period;

            ap.will_issue_hours = meta.have_issued_hours ;
            ap.will_set_periods = meta.have_section ;

            result.push(ap);
          }
        });

        index++;
        indexDate = moment(this.act_start_date_new).add(index, 'd');
        // console.log(` next round, index: ${index}, indexDate: ${indexDate.toString()}`);
      }
    }
    return result;
  }

  validateData() {
    // 1. 檢查是否有填活動名稱
    if (!this.currentAct.title) {
      throw new Error('no_title');
    }

    // 2. 如果有新增日期，則活動開始日期不可晚於結束日期
    if (this.currentAct.is_repeat) {
      const mntBeginDate = moment(this.act_start_date_new);
      const mntEndDate = moment(this.act_end_date_new);
      // const mntBeginDate = moment(this.act_start_date);
      // const mntEndDate = moment(this.act_end_date);
      if (mntBeginDate.toDate().getTime() > mntEndDate.toDate().getTime()) {
        throw new Error('error_date');
      }
    }

    // 3. 檢查活動時段：多日活動才有多個活動時段
    //    要檢查： a. 各時段的開始時間不能晚於結束時間。  b.如果多個時段: 1.每個時段是否都有選星期， 2.各時段所選的星期是否衝突。
    if (this.currentAct.is_repeat && !this.currentAct.is_from_api) {  // 第三方建立的活動，不會重新建立活動日期，所以不需要檢查。
      let index = 1;
      const wrongStartTimePeriods = []; // 紀錄哪幾個時段的開始時間晚於結束時間。
      const noSelectedIndex = []; // 紀錄哪幾個時段沒有選擇星期
      const dicSelectedWeekdays = {}; // 紀錄一週已經有哪幾天被選擇，用來判斷一星期中的哪幾天在各時段是否重複選擇？
      const duplicatedWeekdays = []; // 紀錄一週已經有哪幾天被重複選擇。
      // 對於每個時段
      this.currentAct.act_periods_meta.forEach(meta => {
        // 3.a 判斷這時段的開始時間需要早於結束時間
        if (!this.checkStartTimeIsRight(meta)) {
          wrongStartTimePeriods.push(index);  // 如果開始時間晚於結束時間，則紀錄此錯誤的時段。
        }

        // 3.b  判斷
        let hasSelected = false; // 紀錄這時段是否有選擇任一 weekday。
        // 對於一週的每一天
        this.weekdays.forEach(wd => {
          const isSelected = meta.weekdays[wd.name];    // 這天是否被選擇
          hasSelected = hasSelected || isSelected;    // 判斷本週是否有選取任一天
          if (isSelected) {  // 如果這天有被選擇，則判斷這天在其他時段是否也有選過
            if (dicSelectedWeekdays[wd.name]) {
              // 如果這天已經被選過，則紀錄這天的中文名稱，等著發錯誤訊息
              if (duplicatedWeekdays.indexOf(wd.text) < 0) {
                duplicatedWeekdays.push(wd.text);
              }
            } else {
              // 如果這天不曾被選過，則註記已經被選過，供後續判斷。
              dicSelectedWeekdays[wd.name] = true;
            }
          }
        });
        if (!hasSelected) {
          noSelectedIndex.push(index);
        }
        index++;
      });

      if (noSelectedIndex.length > 0) {
        throw new Error(`第 ${noSelectedIndex.join(',')} 個時段沒有選擇星期！`);
      }
      if (duplicatedWeekdays.length > 0) {
        throw new Error(`星期 ${duplicatedWeekdays.join(',')} 在不同時段被重複選擇！`);
      }
    } else {
      // 如果活動只有單一日期，則檢查該時段開始時間與結束時間。
      if (!this.checkStartTimeIsRight(this.currentAct.act_periods_meta[0])) {
        throw new Error(`活動開始時間 不能晚於 結束時間 ～`);
      }
    }

    // 4. 如果勾選缺席場次，就要檢查是否有填寫允許缺席場次
    if (this.currentAct.cert_allow_absence) {
      if (!this.currentAct.cert_absence_limit) {
        throw new Error(`請填寫 「5. 研習時數證明」的「缺席場次」數`);
      }
    }

    // 5. 如果勾選「完成簽退才核發」，就要檢查是否有勾選「簽退設定」
    if (this.currentAct.cert_need_signout) {
      if (!this.currentAct.need_sign_out) {
        throw new Error(`請勾選 「6. 報到簽退設定」的「簽退設定」`);
      }
    }
  }

  /** 檢查開始時間是否正確（必須小於結束時間) */
  checkStartTimeIsRight(meta: ActivityPeriodMeta) {
    const today = moment().format('YYYY-MM-DD');
    const startTime = moment(`${today} ${meta.start_time}`).toDate().getTime();
    const endTime = moment(`${today} ${meta.end_time}`).toDate().getTime();
    return (startTime < endTime);
  }

  addActTime() {
    this.addEmptyActivityPeriodMeta();
  }

  deleteActivityPeriodMeta(apm: ActivityPeriodMeta) {
    if (this.currentAct.act_periods_meta.length > 1) {
      this.currentAct.act_periods_meta.splice(this.currentAct.act_periods_meta.indexOf(apm), 1);
    }
  }

  selectCheckinBeforeStart() {
    console.log(typeof this.currentAct.checkin_before_start);
    console.log(typeof 999);
    console.log(parseInt(this.currentAct.checkin_before_start.toString(), 10) === 999);
  }


  changeIssueHours(actMeta: ActivityPeriodMeta) {

    console.log({ actMeta });
    console.log(actMeta.have_issued_hours);
  }

  changeMapPeriods(actMeta) {
    // actMeta.have_issued_hours = false ;
    console.log({ actMeta });
    console.log(actMeta.have_issued_hours);
  }

  /** 畫面效果，當「依參加場次，累計時數」的 checkbox 被勾選或取消時 */
  changeAllowAccumulateHours(act: ActivityRecord) {
    // actMeta.have_issued_hours = false ;
    // console.log({act});
    act.cannot_accumulated_hours = false;  // 不管使用者把「允許累計的 checkbox」勾選或取消勾選，另一個一定是不勾選。
    console.log(act.allow_accumulated_hours);
  }

  /** 畫面效果，當「參與多場佈累計，僅核發一場時數」的 checkbox 被勾選或取消時 */
  changeCanNotAccumulateHours(act: ActivityRecord) {
    // actMeta.have_issued_hours = false ;
    // console.log({act});
    act.allow_accumulated_hours = false;  // 不管使用者把「允許累計的 checkbox」勾選或取消勾選，另一個一定是不勾選。
    console.log(act.cannot_accumulated_hours);
  }

  /** 畫面效果，當「全數場次㚬參加才核發」的 checkbox 被勾選或取消時 */
  changeNeedFullAttendance(act: ActivityRecord) {
    // actMeta.have_issued_hours = false ;
    // console.log({act});
    act.cert_allow_absence = false;  // 不管使用者把「允許累計的 checkbox」勾選或取消勾選，另一個一定是不勾選。
    act.cert_absence_limit = null;   // 而且要把允許缺席場次數清空
    console.log(act.cert_need_full_attendance);
  }

  /** 畫面效果，當「缺席場次」的 checkbox 被勾選或取消時 */
  changeAllowAbsence(act: ActivityRecord) {

    act.cert_need_full_attendance = false;  // 不管使用者把「允許累計的 checkbox」勾選或取消勾選，另一個一定是不勾選。

    // 如果允許缺席場次沒有被勾選，則把允許缺席場次數清空
    if (!act.cert_allow_absence) {
      act.cert_absence_limit = null;
    }
    console.log(act.cert_allow_absence);
  }

  editPeriods() {

    this.modalService.show(PeriodHoursComponent, {
      id: 2,
      initialState: { actID: this.currentAct.id },
    });

    // this.modalService.onHide.pipe(take(1), filter(reason => reason === 'data edit OK')).subscribe(() => {
    // console.log('period data saved !');
    // this.activityNotificationService.emitActivityPeriodsStatus(this.currentAct.id) ;  // 觸發節次已被修改的事件，要更新下方另一個元件裡的節次清單
    // });

    // this.closeModal();

  }

}
