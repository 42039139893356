<!-- <div class="modal fade" id="insc" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 460px;"> -->

        <div class="modal-header d-flex justify-content-between border-bottom-0">
          <div></div>
          <h4 class="modal-title">教研網認證</h4>
          <button type="button" class="close ml-0" aria-label="Close" (click)="closeDialog();">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body px-3 mx-md-4" style="overflow-y: auto;">
          <div class="container-md">
            <div class="color-8 fs-18 pb-4 fw-500">
              <!-- <div>2022/04/22(一)</div> -->
              <!-- <div>閃亮亮第一屆錢櫃歌唱大賽讓我們一起大聲嘶吼張震嶽的離開</div> -->
              <div>{{ currentPeriod.act_date_string }} ({{ currentPeriod.weekday_string }})</div>
              <div>{{ !currentActivity ? '' : currentActivity.title }}</div>
            </div>

            <div class="">
              <div class="color-p mb-1">教師在職研習網研習班序號</div>
              <input class="form-control w-100" style="max-width: 400px; border-color:#828282;" placeholder="研習班序號" [(ngModel)]="currentPeriod.insc_code">
            </div>

            <div class="d-flex align-items-center ml-0 ml-sm-5">
              <button class="btn btn-insc border-1 my-3 mr-3"
                      (click)="uploadLearnRecords()">上傳時數</button>
              <div class="d-flex flex-wrap color-8">
                <div *ngIf="!isLoading">前次上傳：</div>
                <div *ngIf="!isLoading">{{ lastUploadRec ? lastUploadRec.created_date : '' }}</div>
                <div *ngIf="isLoading">上傳中・・・ ({{ currentIndex }} / {{teachers.length}})</div>
              </div>
            </div>

            <div class="d-flex justify-content-center mb-3">
              <div class="mr-3">
                <span>上傳</span>
                <span class="ml-1">{{ lastUploadRec ? (lastUploadRec.success_count + lastUploadRec.fail_count) : 0 }}人</span>
              </div>
              <div class="mr-3">
                <span>核發</span>
                <span class="ml-1">{{ lastUploadRec ? (lastUploadRec.success_count) : 0 }}人</span>
              </div>
              <div class="mr-0">
                <span>傳送失敗</span>
                <span class="ml-1"><a href="javascript:void(0);" class="text-danger" style="text-decoration: underline;" (click)="showFailLogs()">{{ lastUploadRec ? (lastUploadRec.fail_count) : 0 }}人</a></span>
              </div>
            </div>

            <div class="d-flex justify-content-center color-8" *ngIf="isShowError && !isWrongCourseNo">
              <div>
                <div>教研網查無下列教師：</div>
                <div class="text-justify">{{ errorTeachers }}</div>
              </div>
            </div>

            <div class="d-flex justify-content-center ml-0 ml-sm-5 text-danger" *ngIf="isShowError && isWrongCourseNo">
              查無課程代碼，傳送失敗
            </div>

            <div class="checkinpeo" *ngIf="!currentActivity.cannot_accumulated_hours" style="color:red">
              此活動設定為可依照報到紀錄累計時數，所以會累計使用者所有報到場次時數後一次上傳，而不會分日期上傳。
            </div>

            <div class="my-4 text-justify color-8">
              <div>說明：</div>
              <ol class="pl-3">
                <li class="py-2">務必確認本活動於教師在職研習網已通過審核。</li>
                <li>如傳入時數大於活動審核通過之時數，以原通過時數認證，小於活動時數則以實際傳入時數認證。</li>
              </ol>
            </div>

            <div class="modal-footer justify-content-center border-top-0 mt-4 pr-3 pr-md-5">
              <button type="submit" class="btn btn-cyan" style="width: 120px"  (click)="closeDialog();">返回</button>
            </div>

          </div>
        </div>

  <!-- </div>
</div> -->
