<section id="scanPage" class="container" page style="padding-top: 2rem;">
  <div class="d-flex pb-4">
    <div class="pr-4 mb-auto mt-0 mt-sm-1 text-nowrap" style="cursor: pointer;" (click)="goback()">
      <img src="/assets/img/chevron-left.svg" alt="" style="width: 32px;"> 返回
    </div>
    <h3>{{ !currentActivity ? '' : currentActivity.title }}</h3>
  </div>
  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li class="nav-item">
      <a class="nav-link mr-3" href="javascript:void(0)" role="tab" [ngClass]="{'active': curTab==='info'}"
        (click)="setTab('info')">
        活動資訊
      </a>
      <div class="backg4"></div>
    </li>
    <!-- <li class="nav-item">
      <a class="nav-link" href="javascript:void(0)" role="tab" [ngClass]="{'active': curTab==='act_dates'}"
        (click)="setTab('act_dates')">
        報到狀況
      </a>
      <div class="backg4"></div>
    </li> -->
    <!-- <li class="nav-item">
      <a class="nav-link"
        href="javascript:void(0)"
        role="tab"
        [ngClass]="{'active': curTab==='qrcode'}"
        (click)="setTab('qrcode')">
        QR Code
      </a>
    </li> -->
    <li class="nav-item">
      <a class="nav-link" href="javascript:void(0)" role="tab" [ngClass]="{'active': curTab==='checkin_status'}"
        (click)="setTab('checkin_status')">
        報到狀況
      </a>
      <div class="backg4"></div>
    </li>
    <!-- <li class="nav-item" *ngIf="showImportNameList">
      <a class="nav-link" href="javascript:void(0)" role="tab" [ngClass]="{'active': curTab==='import'}"
        (click)="setTab('import')">
        匯入報名名單
      </a>
      <div class="backg6"></div>
    </li> -->
  </ul>
  <div class="tab-content" id="pills-tabContent">
    <app-info *ngIf="curTab==='info'"></app-info>
    <app-act-dates *ngIf="curTab==='act_dates'"></app-act-dates>
    <app-qrcode *ngIf="curTab==='qrcode'"></app-qrcode>
    <app-checkin-status *ngIf="curTab==='checkin_status'"></app-checkin-status>
    <app-import *ngIf="curTab==='import'"></app-import>
  </div>
</section>
