<div class="row my-3">
    <h4 class="mb-0 col-lg-7">請掃描 QRCode 進行報到／簽退</h4>
    <div class="col-lg-5 text-right">
        <button class="btn btn-light mr-2" (click)="downloadFile()"><i class="mdi mdi-download"></i> 下載</button>
        <button class="btn btn-light" (click)="qrcodePrint()"><i class="mdi mdi-printer"></i> 列印</button>
    </div>
</div>
<div class="row my-4" id="downloadQrcodeSIN" #screen>
    <div class="col-lg-12">
        <div class="text-center">
            <h1>{{currentActivity.title}}</h1>
            <h3>{{currentActivity.act_date_string}}（{{currentActivity.act_start_time}} - {{currentActivity.act_end_time}})</h3>
        </div>
        <div class="my-5">
            <h2 class="text-center mb-3" style="color: #4ba9ac;"><i class="mdi mdi-login"></i> 報到</h2>
            <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [width]="500" [margin]="3" [value]="valueSignIn" Class="bshadow"></ngx-qrcode>
        </div>
    </div>
    <div *ngIf="currentActivity.need_sign_out" class="col-lg-12 qrcode" id="downloadQrcodeSOUT">
        <div class="text-center" id="h3hid" style="display:none;">
            <h1>{{currentActivity.title}}</h1>
            <h3>{{currentActivity.act_date_string}}（{{currentActivity.act_start_time}} - {{currentActivity.act_end_time}})</h3>
        </div>

        <div class="mt-5">
            <h2 class="text-center mb-3" style="color: #f07818;">簽退 <i class="mdi mdi-logout"></i></h2>
            <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [width]="500" [margin]="3" [value]="valueSignOut" Class="bshadow"></ngx-qrcode>
        </div>
    </div>
</div>
<div id="download" style="display: none;">
    <img #canvas>
    <a #downloadLink></a>
</div>
