import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngxs/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActivityService, CheckInStatusRecord } from 'src/app/core/activity.service';
import { ImdbService } from 'src/app/core/imdb.service';
import { UserService } from 'src/app/core/user.service';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { borderTopRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';
import { PageEvent } from '@angular/material/paginator';

import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { ViewportScroller } from '@angular/common';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-manual-signout',
  templateUrl: './manual-signout.component.html',
  styleUrls: ['./manual-signout.component.scss']
})
export class ManualSignoutComponent implements OnInit {

  @Input() checkInList: CheckInStatusRecord[];
  @Input() currentDate = '';
  @Input() actUuid = '';
  @Output() closed = new EventEmitter<void>();

  currentRole: 'teacher' | 'student' | 'parent' = 'teacher';
  currentRoleText = '教師';
  currentSchoolCode = '0';
  arySchools = [];    //  所有報名者的學校清單
  filteredSchools = [];    //  autocomplete 選項的學校清單
  dicSchools: { [schoolCode: string]: string} = {};   // 學校代碼與校名的對應

  allRecords: SignoutCheckInStatus[] = [];
  uiRecords: SignoutCheckInStatus[] = [];
  pagedUiRecords: SignoutCheckInStatus[] = [];

  // dicCheckInStatusBySignupID: {[signupID: string]: CheckInStatusRecord} = {}; // 用來查詢某筆報名資料是否已經報到？
  aryTeacherCheckIn: SignoutCheckInStatus[] = [];   // 教師的報到清單，按照學校與姓名排序
  dicCheckInTeachertBySchool: { [schoolCode: string]: SignoutCheckInStatus[]} = {} ;   // 已報到的教師清單，按照學校分成不同包
  dicCheckInStudentBySchool: { [schoolCode: string]: SignoutCheckInStatus[]} = {} ;   // 已報到的學生清單，按照學校分成不同包
  dicCheckInParentBySchool: { [schoolCode: string]: SignoutCheckInStatus[]} = {} ;   // 已報到的家長清單，按照學校分成不同包

  isBatchCheckIn = false ;
  isCheckAllTeachers: false ;
  isSchoolAllStudChecked: { [schoolCode: string]: boolean} = {};  // 用來判斷畫面上某個學校全選學生的方塊是否被選取？

  searchString = '';

  myControl = new FormControl('');
  options: any[] = [];
  filteredOptions: Observable<any[]>;

   // pagination
  //  length = 0;
  //  pageSize = 10;
  //  pageSizeOptions: number[] = [5, 10, 25, 100];
  //  currentPageIndex = 0;
   // MatPaginator Output
  //  pageEvent: PageEvent;

   dispose$ = new Subject();
   beforeScrollY = 0;
   page = 0;
   limit = 20;
   theEnd = false;

  constructor(
    private actService: ActivityService,
    // private modalRef: BsModalRef,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private imdbService: ImdbService,
    private store: Store,
    private scrollDispatcher: ScrollDispatcher,
    private viewportScroller: ViewportScroller,
    private change: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.parseSchools();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        // console.log({ value });
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );

    // 偵測是否捲到頁面最底下？
    this.scrollDispatcher.scrolled(500).pipe(
      takeUntil(this.dispose$)
    ).subscribe(async (ev) => {
      const scroll = this.viewportScroller.getScrollPosition();
      // console.log({ scroll });
      if (
        (scroll[1] > this.beforeScrollY) &&
        ((window.innerHeight + scroll[1]) >= (document.body.scrollHeight - (window.innerHeight / 3)))
      ) {
        this.beforeScrollY = scroll[1];
        if (!this.theEnd) {
          await this.loadUIRecords();
        } else {
          this.dispose$.next();
        }
      }
    });
  }

  private _filter(value: string): string[] {
    // console.log({value});
    if (!value) {
      return this.arySchools ;
    }

    const filterValue = value.toLowerCase();
    return this.arySchools.filter(sch => sch.school_name.toLowerCase().includes(filterValue));
  }

  displayFn(sch: any): string {
    return sch && sch.school_name ? sch.school_name : '';
  }

  query() {
    // console.log({ value : this.myControl.value});
    const tempRecords: SignoutCheckInStatus[] = [];
    // 針對所有紀錄，找出符合： a. 身份 b. 學校 c. 姓名關鍵字的所有紀錄，分頁顯示在畫面上。
    this.allRecords.forEach( sr => {
      // a.檢查身份
      if ( sr.checkin.role === this.currentRole ) {
        // b. 檢查學校，如果沒有學校條件， 或是有學校條件，且是這所學校的
        if ((!this.myControl.value) ||
              (this.myControl.value && sr.checkin.school_code === this.myControl.value.school_code)) {
          // c. 如果沒有搜尋字串，或是有搜尋字串，且姓名包含關鍵字
          if ((!this.searchString) ||
              (this.searchString && sr.checkin.name.indexOf(this.searchString) > -1)) {
            tempRecords.push(sr);
          }
        }
      }
    });

    this.uiRecords = tempRecords ;
    // this.length = this.uiRecords.length ;

    // this.showPagedRecords();
    this.loadUIRecords();

  }
  // filterItems(evt) {
  //   console.log(this.searchString);
  //   if (!this.searchString) {
  //     this.filteredSchools = this.arySchools ;
  //   } else {
  //     this.filteredSchools =  this.arySchools.filter(sch => sch.toLowerCase().includes(this.searchString));
  //   }
  // }


  // 從已報到名單中解析出學校清單
  parseSchools() {
    // console.log(' parseSchools() ....');
    this.arySchools = [];
    this.dicSchools = {};
    this.aryTeacherCheckIn = [];
    this.dicCheckInTeachertBySchool = {};
    this.dicCheckInStudentBySchool = {};
    this.dicCheckInParentBySchool = {};

    const tempSchools = [];
    const tempTeachers = [];

    const tempSignoutRecords: SignoutCheckInStatus[] = [];

    // 對於所有已經報到此活動的名單
    this.checkInList.forEach( checkin => {
      // 找出每一筆報到紀錄的學校
      const { school_code, school_name } = checkin ;
      if (!this.dicSchools[school_code]) {
        this.dicSchools[school_code] = school_name ;
        tempSchools.push( {school_code, school_name});
      }

      const rec = new SignoutCheckInStatus();
      rec.checkin = checkin ;
      rec.isSignout =  !! checkin.signout_time ;  // 是否已經簽退
      tempSignoutRecords.push(rec);
    });

    this.allRecords = tempSignoutRecords ;
    // this.aryTeacherCheckIn = tempTeachers ;

    // 排序學校名稱
    this.arySchools = tempSchools.sort( (schX, schY): number => {
      let result = 0;
      if (schX.school_name.toString() > schY.school_name.toString()){
        result = 1;
      } else if (schX.school_name.toString() < schY.school_name.toString()){
        result = -1;
      }
      return result ;
    });

    this.query();
    // console.log(this.aryTeacherCheckIn);
  }


  // 重新取得此活動在指定日期的報到紀錄
  async refreshCheckStatusRecords() {
    // this.CheckInStatusRecord = await this.checkinStatusService.getCheckInStatus(this.actUuid).toPromise();
    this.checkInList = await this.actService.getCheckinStatus(this.actUuid, this.currentDate).toPromise();

    // this.dicCheckInStatusRecords = new Map(); // 用來判斷報名者是否已經報到了。
    // this.dicCheckInStatusByStudKey = {};      // 用來判斷這位學生是否已經報到。

    // 處理要呈現的文字
    this.checkInList.forEach(statusRec => {
      statusRec.role_text = this.userService.convertRoleText(statusRec.role);
      if (statusRec.checkin_time) { statusRec.checkin_time_string = moment(statusRec.checkin_time).format('YYYY-MM-DD HH:mm:ss'); }
      if (statusRec.signout_time) { statusRec.signout_time_string = moment(statusRec.signout_time).format('YYYY-MM-DD HH:mm:ss'); }

      // 這個 dictionary 是用來判斷報名者是否已經報到了。
      // if (statusRec.signup_id) {
      //   if (!this.dicCheckInStatusRecords[statusRec.signup_id.toString()]) {
      //     this.dicCheckInStatusRecords[statusRec.signup_id.toString()] = statusRec ;
      //   }
      // }

      // 這個 dictionary 用來判斷這位學生是否報到過了？
      // const studKey = `${statusRec.school_code}_${statusRec.school_type}_${statusRec.role}_${statusRec.id_no}`;
      // console.log(studKey);
      // if (!this.dicCheckInStatusByStudKey[studKey]) {
      //   this.dicCheckInStatusByStudKey[studKey] = statusRec ;
      // }

      // 轉換報到方式為中文字
      statusRec.checkin_type_text = this.getCheckInTypeText(statusRec.checkin_type);
      // console.log(this.dicCheckInStatusRecords);

    });
  }

  // changeSchool() {
  //   this.parseSchools();
  // }

  getCheckInTypeText( checkinType: string ): string {
    if (checkinType === 'qrcode') { return checkinType; }
    if (checkinType === 'form') { return '填表'; }
    if (checkinType === 'manual') { return '人工'; }
    return checkinType ;
  }

  closeDialog() {
    // this.modalRef.hide();
    this.closed.emit();
  }

  searchStuds(isFromUI: true) {
    // console.log({ searchString: this.searchString });
    // this.currentPageIndex = 0;  // 搜尋身份，跳回第一頁。
    this.resetScroll();
    this.query();
  }

  loadUIRecords() {
    // console.log(' loadUIRecords .... ');
    const beginIndex = this.page * this.limit ;
    const endIndex = (this.page + 1) * this.limit ;
    this.page += 1;
    const tempRecords = this.uiRecords.slice(beginIndex, endIndex);
    tempRecords.forEach( rec => {
      this.pagedUiRecords.push(rec);
    });
    // this.pagedCheckInStatusRecords = this.pagedCheckInStatusRecords.concat(tempRecords);
    // console.log( { page: this.page, recs: this.pagedUiRecords });
    this.change.detectChanges();
  }


  resetScroll() {
    this.page = 0;  // reset to the first page.
    this.pagedUiRecords = [];
    this.beforeScrollY = 0;
  }


  // 按下批次簽退按鈕
  async onSaveBatchCheckIn() {
    const batchRecs = [];
    // 找出被勾選的教師
    const teachers = this.aryTeacherCheckIn.forEach( rec => {
      if (rec.isSelected) {
        batchRecs.push(rec);
      }
    });

    this.arySchools.forEach( sch => {
      // 找出被勾選的學生
      const studs = this.dicCheckInStudentBySchool[sch.school_code];
      if (studs) {
        studs.forEach( stud => {
          if (stud.isSelected) {
            batchRecs.push(stud);
          }
        });
      }

      // 找出被勾選的家長紀錄
      const parents = this.dicCheckInStudentBySchool[sch.school_code];
      if (parents) {
        parents.forEach( parent => {
          if (parent.isSelected) {
            batchRecs.push(parent);
          }
        });
      }
    });

    // console.log(batchRecs);

    await this.SignoutBatchRec(batchRecs);
    this.onCancelBatchCheckIn();
    this.snackBar.open(`提醒`, `已手動完成 ${batchRecs.length} 人的簽退作業。`, {
      duration: 2000,
    });
  }

  onCancelBatchCheckIn() {
    const teachers = this.aryTeacherCheckIn.forEach( rec => {
      rec.isSelected = false ;
    });

    this.arySchools.forEach( sch => {
      // 找出被勾選的學生
      const studs = this.dicCheckInStudentBySchool[sch.school_code];
      if (studs) {
        studs.forEach( stud => {
          stud.isSelected = false ;
        });
      }

      // 找出被勾選的家長紀錄
      const parents = this.dicCheckInParentBySchool[sch.school_code];
      if (parents) {
        parents.forEach( parent => {
          parent.isSelected = false ;
        });
      }

      this.isBatchCheckIn = false ;
    });

    this.isCheckAllTeachers = false;
  }

  SelectAllTeachers() {
    // console.log(this.isCheckAllTeachers);
    this.aryTeacherCheckIn.forEach( rec => {
      if (!rec.isSignout) {
        rec.isSelected = this.isCheckAllTeachers;
      } else {
        rec.isSelected = false ;
      }
    });
  }

  // 對一筆資料做簽退
  async SignoutSingleRec(rec: SignoutCheckInStatus) {

    if (this.isBatchCheckIn) {
      this.snackBar.open(`提醒`, '在批次簽退模式下，不能進行個別簽退', {
        duration: 2000,
      });
      return ;
    }
    // console.log(rec);
    await this.SignoutBatchRec([rec]);

  }

  // 批次人工簽退
  async SignoutBatchRec(recs: SignoutCheckInStatus[]) {
    if (recs.length < 1) { return ; }

    const data = [];
    const signoutIDs = recs.map( rec => rec.checkin.id);
    // 批次人工簽退
    await this.actService.manualSignout(this.actUuid, this.currentDate, signoutIDs).toPromise();

    recs.forEach( rec => {
      rec.isSignout = true ;
      rec.checkin.signout_type = 'manual';
    });

    // 重新取得活動報到清單
    await this.refreshCheckStatusRecords();
    // 重新找出畫面所需資料
    // this.parseSchools();   // 更新畫面
  }

  // 對一筆資料取消報到，只有針對人工報到的紀錄才能取消報到。
  async CancelSignoutSingleRec(rec: SignoutCheckInStatus) {
    await this.actService.manualCancelSignout(this.actUuid, [rec.checkin.id]).toPromise();

    rec.isSignout = false ;
    rec.checkin.signout_type = '';

    await this.refreshCheckStatusRecords();

    // 重新找出畫面所需資料
    // this.parseSchools();   // 更新畫面
  }

  // SelectSchoolStudents(schoolCode: string) {
  //   // console.log(this.isSchoolAllStudChecked[schoolCode], schoolCode);
  //   if (!this.dicCheckInStudentBySchool[schoolCode]) { return ; }
  //   // console.log('select all students');
  //   this.dicCheckInStudentBySchool[schoolCode].forEach( rec => {
  //     if (!rec.isSignout) {
  //       rec.isSelected = this.isSchoolAllStudChecked[schoolCode];
  //     } else {
  //       rec.isSelected = false ;
  //     }
  //     // console.log(rec.isSelected);
  //   });
  // }

  selectSchool() {
    // this.currentPageIndex = 0;  // 切換學校，跳回第一頁。
    this.resetScroll();
    this.query();
  }

  // 切換角色
  changeRole(evt) {
    this.currentRole = evt.value ;
    // console.log(this.currentRole);
    this.currentRoleText = this.convertRoleText(this.currentRole);
    // this.currentPageIndex = 0;  // 切換角色，跳回第一頁。
    this.resetScroll();
    this.query();
  }

  convertRoleText(role) {
    if (role === 'teacher') {
      return '教師';
    } else if (role === 'student') {
      return '學生';
    } else if (role === 'parent') {
      return '家長';
    }
  }

  // changePage(evt) {
  //   this.pageEvent = evt ;
  //   this.currentPageIndex = (this.pageEvent ? this.pageEvent.pageIndex : 0 ) ;

  //   // console.log({ evt });
  //   this.showPagedRecords();
  // }

  // showPagedRecords() {
  //   // this.currentPageIndex = (this.pageEvent ? this.pageEvent.pageIndex : 0 ) ;
  //   const currentPageSize = ( this.pageEvent ? this.pageEvent.pageSize : this.pageSize );
  //   const beginIndex = this.currentPageIndex * currentPageSize ;
  //   const endIndex = (this.currentPageIndex + 1) * currentPageSize ;
  //   // console.log({ currentPageIndex: this.currentPageIndex, currentPageSize, beginIndex, endIndex});
  //   this.pagedUiRecords = this.uiRecords.slice(beginIndex, endIndex);
  // }

}

class SignoutCheckInStatus {
  checkin: CheckInStatusRecord ;
  isSignout: boolean; // 是否已經簽退
  isSelected: boolean;
}
