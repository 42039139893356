import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CheckInHistory } from '../data/checkin-history';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckinService {

  constructor(
    private http: HttpClient
  ) { }

  /** 取得指定 access token 的報到歷程 */
  getCheckinHistory(accessToken: string): Observable<CheckInHistory[]> {
    return this.http.get(`/service/checkin/getHistory?access_token=${accessToken}`).pipe(
      map(v => v as CheckInHistory[])
    );
  }

  /** 取得我的報到歷程 */
  getMyCheckinHistory(): Observable<CheckInHistory[]> {
    return this.http.get(`/service/checkin/getMyHistory`).pipe(
      map(v => v as CheckInHistory[])
    );
  }

  /** 報到 */
  checkin(actUuid: string,
          accessToken: string,
          schoolName: string,
          schoolCode: string,
          schoolType: string,
          roleType: string,
          uuid: string,
          mealType: string,
          childName: string,
          studNumber: string,
          seatNo: string,
          className: string): Observable<any> {
    return this.http.get(`/service/checkin/${actUuid}?access_token=${accessToken}&school_name=${schoolName}&school_code=${schoolCode}&school_type=${schoolType}&role_type=${roleType}&uuid=${uuid}&meal_type=${mealType}&childName=${childName}&studNumber=${studNumber}&seatNo=${seatNo}&className=${className}`);
  }

  /** 簽退 */
  signout(actUuid: string,
          accessToken: string,
          schoolName: string,
          schoolCode: string,
          schoolType: string,
          roleType: string,
          uuid: string): Observable<any> {
    return this.http.get(`/service/signout/${actUuid}?access_token=${accessToken}&school_name=${schoolName}&school_code=${schoolCode}&school_type=${schoolType}&role_type=${roleType}&uuid=${uuid}`);
  }

  /** 簽退 */
  registration(regForm: any): Observable<any> {
    return this.http.post(`/service/registration`, regForm);
  }

  /** 簽退 */
  getServerTime(): Observable<any> {
    return this.http.get(`/service/server_time`);
  }

  /** 檢查報到時間 */
  isRightTimeToCheckin(actUuid: string): Observable<any> {
    return this.http.get(`/service/checkin/is_right_time/${actUuid}`);
  }

  /** 檢查簽退時間 */
  isRightTimeToSignout(actUuid: string, accessToken: string, schoolCode: string,
                       schoolType: string, roleType: string, studNumber: string): Observable<any> {
    return this.http.get(`/service/signout/is_right_time/${actUuid}?access_token=${accessToken}&school_code=${schoolCode}&school_type=${schoolType}&role_type=${roleType}&stud_number=${studNumber || ''}`);
  }

  downloadCert(actUuid: string) {
    return this.http.get(`/service/certification/download_pdf?act_uuid=${actUuid}`);
  }

  hasCert(actUuid: string) {
    return this.http.get(`/service/certification/has_certificate?act_uuid=${actUuid}`);
  }

  /** 檢查報到時間 */
  findCheckInRecord(actUuid: string, accessToken: string, schoolCode: string,
                    schoolType: string, roleType: string, studNumber: string, actDate): Observable<any> {
    return this.http.get(`/service/checkin/find_checkin_record/${actUuid}?access_token=${accessToken}&school_code=${schoolCode}&school_type=${schoolType}&role_type=${roleType}&stud_number=${studNumber || ''}&act_date=${actDate || ''}`);
  }

}

