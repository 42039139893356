<div *ngIf="mode === 'checkin_status'">
  <div class="d-flex flex-column flex-md-row justify-content-around align-items-baseline checkinpeo text-muted" style="margin-top: 32px;"
    role="alert">
    <div class="">目前報到人數<strong class="text-dark mx-2">{{totalCount}}</strong>人</div>
    <div class="py-2">預先報名<strong class="text-dark mx-2">{{ signupCount }} / {{ namelist.length }}</strong>人</div>
    <div class="">現場報名<strong class="text-dark mx-2">{{ totalCount - signupCount }}</strong>人</div>
    <div class="">預設時數<strong class="text-dark mx-2">{{ this.currentPeriod ? this.currentPeriod.issued_hours : '' }}</strong>小時</div>
    <div>

    </div>
    <div>
      <span>
        <button class="btn btn-outline-cyan px-3  mr-2 mr-md-3" style="max-height: 40px;"
        [disabled]="isInReCalculating"
        (click)="openConfirmForm(actConfirmReCalcHours)">重新計算研習時數</button>
      </span>
      <span>{{ lastReCalculateTime }}</span>
    </div>
  </div>
  <div class="d-flex flex-column flex-md-row justify-content-between my-3 align-items-baseline">
    <div>
      <mat-form-field appearance="fill" class="mr-3" id="selrole">
        <mat-label>篩選身分：</mat-label>
        <mat-select [(value)]="currentRole" (selectionChange)="changeRole($event)">
          <mat-option value="0">全部</mat-option>
          <mat-option value="teacher">教師</mat-option>
          <mat-option value="student">學生</mat-option>
          <mat-option value="parent">家長</mat-option>
          <mat-option value="others">填表</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" id="seldate">
        <mat-label>活動日期：</mat-label>
        <mat-select [(value)]="currentDate" (selectionChange)="changeDate($event)">
          <mat-option *ngFor="let dt of actPeriodDates; let i = index" [value]="dt" [id]="i">{{ dt }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <span style ="display: inline-block" class="ml-4">
        <input class="form-control day-of-week" id="nnsearch" placeholder="姓名關鍵字"  (keyup.enter)='searchStuds($event)' [(ngModel)]="searchString" >
      </span> -->
    </div>
    <div>
      <!-- <button class="btn btn-outline-cyan mr-2 mr-sm-3 px-3 px-sm-4" (click)="editPeriod()">
        分場時數設定
      </button> -->
      <button class="btn btn-outline-cyan px-3 mb-3 mr-2 mr-md-3" style="max-height: 40px;"
        *ngIf="currentActivity.dept_code"
        [disabled]="!curRole.deptCode"
        (click)="openINSC( currentActivity)">教研網認證</button> <!-- 處室建立的活動才可上傳教研網 -->
      <button class="btn btn-outline-cyan px-3 mb-3 mr-2 mr-md-3" style="max-height: 40px;"
        (click)="openCheckIn( currentActivity)">人工報到</button>
      <button class="btn btn-outline-cyan px-3 mb-3 mr-2 mr-md-3" style="max-height: 40px;"
        (click)="openCheckOut(currentActivity)">人工簽退</button>
      <button class="btn btn-cyan mb-3" style="max-height: 40px;" [disabled]="tempCheckInStatusRecords.length < 1"  (click)="downloadCheckinData()">下載</button>
    </div>
  </div>

  <table class="table table-rwd text-nowrap">
    <thead>
      <tr>
        <th>單位(學校)</th>
        <th>姓名</th>
        <th *ngIf="currentRole !== 'others'">身分</th>
        <th *ngIf="currentRole === 'others'">職稱</th>
        <th *ngIf="currentRole === 'others'">電話</th>
        <th *ngIf="currentRole === 'parent' || currentRole === 'student'">班級</th>
        <th *ngIf="currentRole === 'parent' || currentRole === 'student'">座號</th>
        <th *ngIf="currentRole === 'parent' || currentRole === 'student'">學號</th>
        <th *ngIf="currentRole === 'parent'">子女姓名</th>
        <th>報到方式</th>
        <th>餐點</th>
        <th *ngIf="currentRole === 'teacher' || currentRole === 'student'">實際獲得時數</th>
        <th>報到／簽退</th>
        <!-- <th>備註</th> -->
      </tr>
    </thead>
    <tbody>
      <tr data-toggle="modal" data-backdrop="static" *ngFor="let cis of pagedCheckInStatusRecords">
        <td data-th="單位(學校)">
          <div style="white-space: normal;">{{cis.school_name}} <span *ngIf="!!cis.school_type">({{cis.school_type}})</span></div>
        </td>
        <td data-th="姓名">
          <span class="badge badge-success mr-2 " *ngIf="!!cis.signup_id">預</span>{{cis.name}}
        </td>
        <td data-th="身分" *ngIf="currentRole !== 'others'">
          {{ cis.role_text }}
        </td>
        <td data-th="職稱" *ngIf="currentRole === 'others'">
          {{ cis.role_text }}
        </td>
        <td data-th="電話" *ngIf="currentRole === 'others'">
          {{ cis.tel_no }}
        </td>
        <td data-th="班級" *ngIf="currentRole === 'parent' || currentRole === 'student'">
          {{ cis.class_name }}
        </td>
        <td data-th="座號" *ngIf="currentRole === 'parent' || currentRole === 'student'">
          {{ cis.seat_no }}
        </td>
        <td data-th="學號" *ngIf="currentRole === 'parent' || currentRole === 'student'">
          {{ cis.stud_number }}
        </td>
        <td data-th="子女姓名" *ngIf="currentRole === 'parent'">
          {{ cis.child_name }}
        </td>
        <td data-th="報到方式">
          {{ cis.checkin_type_text }}
          <!-- {{ cis.checkin_type ? ( cis.checkin_type === 'qrcode' ? '掃碼' : '填表') : '' }} -->
        </td>
        <td data-th="餐點">
          {{ cis.meal_type ? (cis.meal_type === 'undefined' ? '' : cis.meal_type ) : '' }}
        </td>
        <td data-th="時數" class="issued_hours"  *ngIf="currentRole === 'teacher' || currentRole === 'student'">
          <span (click)="editIssuedHours(cis);" *ngIf="!!cis.checkin_time">{{ cis.issued_hours || '0' }}</span><!-- 如果有報到-->
          <span (click)="editIssuedHours(cis);" *ngIf="!cis.checkin_time">{{ cis.signup_id ? '-' : '' }}</span><!-- 如果沒有報到，但有預先報名-->
        </td>
        <td data-th="報到／簽退">
          {{ (cis.checkin_type === 'manual') ? '人工報到' : cis.checkin_time_string }}<br>
          {{ (cis.signout_type === 'manual') ? '人工簽退' : cis.signout_time_string }}
        </td>
        <!-- <td data-th="備註">
                  {{cis.child_name}}<br> {{cis.stud_number}}
              </td> -->
        <!-- <td data-th="刪除副管理員身分" style="width: 20%">
        <button mat-icon-button (click)="removeSubAdmins(tea.IDNumber)">
          <mat-icon aria-label="add">delete</mat-icon>
        </button>
      </td> -->
      </tr>
    </tbody>
  </table>


  <table class="table rwd-table" style="display: none;" #checkinTable>
    <thead class="thead">
      <tr class="tr">
        <td>單位（學校）</td>
        <td>姓名</td>
        <!-- <td>身分證字號</td> -->
        <td>學制</td>
        <td>身分</td>
        <td>班級</td>
        <td>報到方式</td>
        <td>報到</td>
        <td>簽退</td>
      </tr>
    </thead>
    <tbody class="tbody">
      <tr class="tr" *ngFor="let cis of pagedCheckInStatusRecords">
        <td data-title="單位（學校）">{{cis.school_name}}</td>
        <td data-title="姓名"><span class="badge badge-success mr-2"
            style="width:auto;text-align: center;">(預)</span>{{cis.name}}</td>
        <!-- <td data-title="身分證字號">{{cis.id_no}}</td> -->
        <td data-title="學制">{{ cis.school_type }}</td>
        <td data-title="身分">{{cis.role}}</td>
        <td data-title="班級">{{cis.school_type}}</td>
        <td data-title="報到方式">{{ (cis.checkin_type === 'qrcode' ? '掃碼' : (cis.checkin_type === 'form' ? '填表' : '手動')) }}</td>
        <td data-title="報到時間">{{ (cis.checkin_type === 'manual') ? '人工報到' : cis.checkin_time_string}}</td>
        <td data-title="簽退時間">{{ (cis.signout_type === 'manual') ? '人工簽退' : cis.signout_time_string}}</td>
      </tr>
    </tbody>
  </table>

  <!-- <ng-template #templateEditIssuedHours>
    <div class="modal-content">
      <div class="modal-header pb-2">
        <div class="d-flex justify-content-between w-100">
          <div></div>
          <h4 class="modal-title">調整核發時數</h4>
          <button type="button" class="close" (click)="closeDialog()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body px-4">
        <div class="d-flex justify-content-center py-4 align-items-center"> -->
          <!-- <span>{{ selectedCIS.school_name }}</span> -->
          <!-- <span>{{ selectedCIS.name }}</span> 在 {{ selectedCIS.checkin_time.toLocaleString().split('T')[0] }} 的核發時數：
        </div>
        <div class="d-flex justify-content-center py-4 align-items-center">
          從 <span> {{ selectedCIS.issued_hours || '0' }} 小時</span> 改成
          <input type="number" class="form-control mx-2" style="width: 50px;" id="" placeholder=""
              min="0"  max="24" [(ngModel)]="newIssuedHours" >
          小時
        </div>

      </div>
      <div class="modal-footer justify-content-center py-4">
        <div class="btn btn-outline-orange px-3 mb-3 mr-2 mr-md-3" (click)="closeDialog();" >取消</div>
        <div class="btn btn-outline-cyan px-3 mb-3 mr-2 mr-md-3" (click)="saveIssuedHours()" >確定</div>
      </div>
    </div>
  </ng-template> -->

  <!-- 分頁元件 -->
  <!-- <mat-paginator [length]="length"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="changePage($event)"
  aria-label="Select page">
  </mat-paginator> -->
</div>


<app-manual-checkin
  [nameList]="namelist"
  [checkInList]="rawCheckStatusRecords"
  [actUuid]="actUuid"
  [currentDate]="currentDate"
  (closed)="onManualCheckInClosed($event)"
  *ngIf="mode === 'manual_checkin'"></app-manual-checkin>

  <app-manual-signout
  [checkInList]="rawCheckStatusRecords"
  [actUuid]="actUuid"
  [currentDate]="currentDate"
  (closed)="onManualCheckInClosed($event)"
  *ngIf="mode === 'manual_signout'"></app-manual-signout>


  <ng-template #actConfirmReCalcHours>
    <div class="modal-content">
      <div class="modal-header justify-content-between">
        <div></div>
        <h4 class="modal-title">提醒</h4>
        <button type="button" class="close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="my-2"><b>當您重新結算時，會以預設的規則進行計算，您設定的人工修改時數的部分將會被覆蓋，您是否確定要重新結算時數？</b></p>
      </div>
      <div class="modal-footer justify-content-center mb-4">
        <button type="button" class="btn btn-outline-cyan mr-3" style="width: 120px;" (click)="reCalcHours( currentActivity)">確認</button>
        <button type="button" class="btn btn-cyan" style="width: 120px;" (click)="modalRef.hide()">取消</button>
      </div>
    </div>
  </ng-template>
