<div class="d-flex flex-column">
  <!-- 清單-->
  <div *ngIf="!this.isAddMode">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <span>副管理員清單：</span>
      <div>
        <button mat-raised-button color="primary" (click)="toAddAgentMode()">
          <mat-icon>person_add</mat-icon>
          增加
        </button>
      </div>
    </div>

    <ul class="rwd-table" style="margin-top: -1px">
      <li class="thead">
        <ol class="tr">
          <li>姓名</li>
          <li style="width: 200px">識別：</li>
          <li>移除</li>
        </ol>
      </li>
      <li class="tbody point">
        <!-- onclick="toggle(event, 'scanPage')" -->
        <ol class="tr" *ngFor="let agent of agents">
          <li data-title="姓名">{{ agent.name }}</li>
          <li data-title="識別">{{ agent.role_text }}</li>
          <li data-title="移除">
            <button mat-stroked-button (click)="removeAgent(agent)">
            <mat-icon style="color: red;">remove_circle</mat-icon>
          </button>
          </li>
        </ol>
      </li>
    </ul>
  </div>

  <!-- 選擇畫面-->
  <div *ngIf="this.isAddMode">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <button
        mat-button
        class="d-flex flex-row align-items-center"
        (click)="back()"
      >
        <mat-icon>arrow_back_ios</mat-icon>
        <span style="font-size: 1.2rem; display: inline-block" class="mb-2"
          >返回</span
        >
      </button>

      <!-- <span>選擇副管理員：</span> -->
      <mat-form-field class="width: 50%;">
        <mat-label>關鍵字</mat-label>
        <!-- <span matPrefix>+1 &nbsp;</span> -->
        <input type="text" matInput placeholder="教師姓名關鍵字" [(ngModel)]="keyword" (ngModelChange)="filterTeachers($event)" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
      <!-- 清單-->
      <ul class="rwd-table" style="margin-top: -1px">
        <li class="thead">
          <ol class="tr">
            <li>姓名</li>
            <li style="width: 200px">識別：</li>
            <li>設定</li>
          </ol>
        </li>
        <li class="tbody point">
          <!-- onclick="toggle(event, 'scanPage')" -->
          <ol class="tr" *ngFor="let tea of tempTeachers">
            <li data-title="姓名">{{ tea.TeacherName }}</li>
            <li data-title="識別">{{ tea.roleString }}</li>
            <li data-title="設定">
              <button mat-Stroked-button color="primary">
                <mat-icon (click)="addAgent(tea);">person_add</mat-icon>
              </button>
            </li>
          </ol>
        </li>
      </ul>
  </div>
</div>
