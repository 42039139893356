import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImdbService } from './core/imdb.service';
import { Store, Select } from '@ngxs/store';
import { UserRec, UserRoleRec } from './data';
import { InitUser } from './state/user.action';
import { SetCurRole } from './state/role.action';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  // userRoleList: UserRoleRec[] = [];
  // curRole: UserRoleRec = {} as UserRoleRec;
  // unsubscribe$ = new Subject();

  constructor(
    private imdbSrv: ImdbService,
    private store: Store,
    private router: Router
  ) { }

  async ngOnInit() {
    // await this.getTeacherInfo();
  }

  ngOnDestroy(): void {
    // this.unsubscribe$.next();
    // this.unsubscribe$.complete();
  }

  // async getTeacherInfo() {
  //   try {
  //     const rsp = await this.imdbSrv.getTeacherInfo();
  //     [].concat(rsp || []).forEach(data => {
  //       const { schoolName, schoolCode, schoolType, roleList } = data;
  //       const dataList: UserRoleRec[] = [].concat(roleList || []).map(roleRec => {
  //         const { dept, deptID, title, role } = roleRec;
  //         return { schoolCode, schoolName, schoolType, dept, deptID, title, role};
  //       });
  //       this.userRoleList = this.userRoleList.concat(dataList || []);
  //     });

  //     console.log(`userRoleList : ${this.userRoleList}`);

  //     const user: UserRec = {
  //       roleList: this.userRoleList
  //     };

  //     if (this.userRoleList.length < 1) {
  //       throw '沒有教師身份';
  //     }

  //     this.curRole = (this.userRoleList.length > 0 ?  this.userRoleList[0] : {} as UserRoleRec);
  //     console.log(`dispatch curRole .... ${this.curRole}`);
  //     // this.store.dispatch(new InitUser(user));
  //     this.store.dispatch(new SetCurRole(this.curRole));

  //     console.log(this.curRole);
  //   } catch (error) {
  //     console.log(error);
  //     // window.alert('很抱歉，此系統僅開放給教師或行政人員使用！');
  //     this.router.navigate(['signin']);
  //   }
  // }

  // setCurRole(role: UserRoleRec) {
  //   this.curRole = role;
  //   this.store.dispatch(new SetCurRole(role));
  // }
}
