<div class="row" *ngIf="selectedCount > 0">
    <div class="col-md-6 mb-3">「{{ this.currentActivity.title }}」的報名名單如下：</div>
    <!-- <div class="d-flex flex-row align-items-center"> -->
    <div class="col-md-6 d-flex flex-row mb-3">
        <div class="">選取 {{ selectedCount}} 筆</div>
        <button (click)="removeSelectedReg()" class="btn btn-cyan ml-auto">刪除</button>
    </div>
    <!-- </div> -->
</div>
<div class="row" *ngIf="namelist.length > 0">
    <div class="col-12 text-right selall">
        <mat-checkbox [(ngModel)]="isAllSelected" (change)="selectAllChange($event)"></mat-checkbox>全部選取
    </div>
</div>

<table class="table-rwd table my-3" style="width:100%;">
    <thead>
        <tr>
            <th style="width: 5%">
                <mat-checkbox [(ngModel)]="isAllSelected" (change)="selectAllChange($event)"></mat-checkbox>
            </th>
            <th style="width: 10%">類型</th>
            <th style="width: 10%">姓名</th>
            <th style="width: 25%">學校</th>
            <th style="width: 10%">學制</th>
            <th>班級</th>
            <th>學號</th>
            <th>電話</th>
        </tr>
    </thead>
    <tbody>
        <tr data-toggle="modal" data-backdrop="static" *ngFor="let mem of namelist">
            <td data-th="選取">
                <mat-checkbox [(ngModel)]="mem.checked" (change)="selectedChange($event)"></mat-checkbox>
            </td>
            <td data-th="類型">
                {{ mem.role_text }}
            </td>
            <td data-th="姓名">
                {{ mem.name }}
            </td>
            <td data-th="學校">
                {{ mem.school_name }}
            </td>
            <td data-th="學制">
                {{ mem.school_type }}
            </td>
            <td data-th="班級">
                {{ mem.class_name }}
            </td>
            <td data-th="學號">
                {{ mem.stud_no }}
            </td>
            <td data-th="電話">
                {{ mem.tel_no }}
            </td>
            <!-- <td data-th="刪除副管理員身分" style="width: 20%">
        <button mat-icon-button (click)="removeSubAdmins(tea.IDNumber)">
          <mat-icon aria-label="add">delete</mat-icon>
        </button>
      </td> -->
        </tr>
    </tbody>
</table>
