import { IMDBTeacherInfo, ImdbService, AP2IMDBTeacherInfo } from './../../core/imdb.service';
import { Component, Input, OnInit } from '@angular/core';
import { AdminAgentInfo, PermissionService } from 'src/app/core/permission.service';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit {

  agents: AdminAgentInfo[] = [];
  dicAgents: { [identity: string]: AdminAgentInfo } = {};

  teachers: IMDBTeacherInfo[] = [];
  dicTeachers: { [id: number]: IMDBTeacherInfo } = {};

  ap2Teachers: AP2IMDBTeacherInfo[] = [];
  dicAp2Teachers: { [id: number]: AP2IMDBTeacherInfo[] } = {};

  tempTeachers: IMDBTeacherInfo[] = [];
  isAddMode = false;
  keyword = '';

  private _schoolCode: string;
  private _schoolType: string;

  @Input()
  set schoolCode(schCode: string) {
    this._schoolCode = schCode;
    // this.reloadData().then();
  }

  @Input()
  set schoolType(schType: string) {
    this._schoolType = schType;
  }

  @Input()
  set schoolTeachers(teachers: AP2IMDBTeacherInfo[]) {
    this.ap2Teachers = teachers;
    // put to a dictionary.
    this.dicAp2Teachers = {};
    this.ap2Teachers.forEach(ap2Tea => {
      if (!this.dicAp2Teachers[ap2Tea.TeacherId]) {
        this.dicAp2Teachers[ap2Tea.TeacherId] = [];
      }
      this.dicAp2Teachers[ap2Tea.TeacherId].push(ap2Tea);
    });
    // reload data.
    this.reloadData().then();
  }

  constructor(
    private imdbService: ImdbService,
    private permissionService: PermissionService
  ) { }

  async ngOnInit(): Promise<void> {
    // await this.reloadData();
  }

  async reloadData() {
    if (this._schoolCode && this._schoolType) {
      await this.loadAllTeachers();
      await this.loadAgents();
    }
  }

  async loadAllTeachers() {
    this.teachers = await this.imdbService.getSchoolTeachers(this._schoolCode, this._schoolType).toPromise();
    this.dicTeachers = {};
    this.teachers.forEach(tea => {
      this.dicTeachers[tea.Identity] = tea;
    });
  }

  async loadAgents() {
    // console.log('loadAgent()---', this.dicAp2Teachers);
    this.agents = await this.permissionService.getAdminAgents(this._schoolCode, this._schoolType).toPromise();
    this.dicAgents = {};
    this.agents.forEach(agent => {
      if (!this.dicAgents[agent.identity]) {
        this.dicAgents[agent.identity] = agent;
      }
      const tea: IMDBTeacherInfo = this.dicTeachers[agent.identity];
      if (tea) {
        // console.log(tea);
        const ap2TeacherRoles = this.dicAp2Teachers[tea.TeacherID];
        // console.log(ap2TeacherRoles);
        if (ap2TeacherRoles && ap2TeacherRoles.length > 0) {
          agent.role_text = ap2TeacherRoles.map(role => `${role.Department}${role.Unit}${role.Title}`).join(',');
        }
      }
    });
  }

  toAddAgentMode() {
    this.isAddMode = true;
  }

  back() {
    this.isAddMode = false;
  }

  async addAgent(tea: IMDBTeacherInfo) {
    // console.log(tea);
    try {
      await this.permissionService.addAdminAgents(this._schoolCode, this._schoolType, tea.Identity, tea.TeacherName, tea.Position).toPromise();
      await this.reloadData();
      this.filterTeachers(undefined);
    } catch (error) {

    }
  }

  filterTeachers(evt) {
    // console.log(evt);
    const temp = [];
    if (this.keyword) {
      this.teachers.forEach(tea => {
        tea.isAdminAgent = !!this.dicAgents[tea.Identity];  // 判斷這位教師是否已是 副管理者。如果是，則不需列入清單中。
        if (tea.TeacherName.indexOf(this.keyword) > -1) {
          if (!tea.isAdminAgent) {  // 如果不是副管理者，才列入清單中。
            const ap2TeacherRoles = this.dicAp2Teachers[tea.TeacherID];
            // console.log(ap2TeacherRoles);
            if (ap2TeacherRoles && ap2TeacherRoles.length > 0) {
              tea.roleString = ap2TeacherRoles.map(role => `${role.Department}${role.Unit}${role.Title}`).join(',');
            }
            temp.push(tea);
          }
        }
      });
    }
    this.tempTeachers = temp;
  }

  async removeAgent(agent: AdminAgentInfo) {
    // console.log(agent);
    try {
      await this.permissionService.removeAdminAgents(this._schoolCode, this._schoolType, agent.identity).toPromise();
      await this.reloadData();
      this.filterTeachers(undefined);
    } catch (error) {

    }
  }
}
