import { EditIssueHoursComponent } from './edit-issue-hours/edit-issue-hours.component';
import { ManualSignoutComponent } from './manual-signout/manual-signout.component';
import { ManualCheckinComponent } from './manual-checkin/manual-checkin.component';
import { UserService } from 'src/app/core/user.service';
import { CheckinService } from './../../core/checkin.service';
import { CheckInStatusService } from './../../core/checkinstatus.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ɵflushModuleScopingQueueAsMuchAsPossible } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { ActivityRecord, ActivityService, CheckInStatusRecord, ActivityPeriod } from 'src/app/core/activity.service';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { SignupRecord, SignupService } from 'src/app/core/signup.service';
import { ActStateService } from '../act-state.service';
import { filter, take } from 'rxjs/operators';
import { UploadInscComponent } from '../upload-insc/upload-insc.component';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { ViewportScroller } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { PermissionService } from 'src/app/core/permission.service';
// import { borderTopRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';

// import { ConsoleReporter } from 'jasmine';

@Component({
  selector: 'app-checkin-status',
  templateUrl: './checkin-status.component.html',
  styleUrls: ['./checkin-status.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckinStatusComponent implements OnInit {
  currentActivity: ActivityRecord = {} as ActivityRecord;
  rawCheckStatusRecords: CheckInStatusRecord[] = []; // 從資料來源讀取的原始紀錄
  dicCheckInStatusRecords: Map<string, CheckInStatusRecord> = new Map();
  tempCheckInStatusRecords: CheckInStatusRecord[] = []; // 從 rawCheckStatusRecords 經過篩選後的紀錄
  pagedCheckInStatusRecords: CheckInStatusRecord[] = []; // 從 tempCheckInStatusRecords 經過分頁挑選出來要 binding 到畫面的 dataset.
  subscription: Subscription;
  actID: number;
  actUuid = '';
  totalCount = 0;
  signupCount = 0;
  currentRole = '0';
  currentDate = '';
  actPeriodDates: string[] = [];  // 活動日期清單
  namelist: SignupRecord[] = [];
  // modalRef: BsModalRef;
  selectedCIS: CheckInStatusRecord ;
  dialog: BsModalRef ;
  newIssuedHours = 0;

  periods: ActivityPeriod[] = [];
  periodSE = ''; // 下載用，對應節次

  currentPeriod: ActivityPeriod ;
  startPeriod = '';
  endPeriod = '';

  mode: 'checkin_status' | 'manual_checkin' | 'manual_signout' | 'upload_insc' = 'checkin_status' ;

  searchString = '';  // 關鍵字搜尋字串

  curRole ; // 使用者選擇自己目前的身份。

  modalRef: BsModalRef;

  // pagination
  // length = 0;
  // pageSize = 10;
  // pageSizeOptions: number[] = [5, 10, 25, 100];
  // MatPaginator Output
  // pageEvent: PageEvent;




  @ViewChild('checkinTable', { static: false }) checkinTable: ElementRef;

  @ViewChild('templateEditIssuedHours')
  tplEditIssuedHours: TemplateRef<any>;

  isInApp = false ;
  dispose$ = new Subject();
  beforeScrollY = 0;
  page = 0;
  limit = 20;
  theEnd = false;

  isInReCalculating = false;  // 正在重新計算時數中
  lastReCalculateTime = '';   // 最後計算時數的時間

  constructor(
    // private checkinSerevice: CheckinService,
    // private router: Router,
    // private modal: BsModalService,
    private route: ActivatedRoute,
    private activityService: ActivityService,
    // private actStateSrv: ActStateService,
    private userService: UserService,
    private signupService: SignupService,
    private bsModalSrv: BsModalService,
    private snackBar: MatSnackBar,
    private checkinStatusService: CheckInStatusService,
    private scrollDispatcher: ScrollDispatcher,
    private viewportScroller: ViewportScroller,
    private change: ChangeDetectorRef,
    private store: Store,
    private permissionService: PermissionService,
  ) { }

  async ngOnInit() {
    // this.actUuid = '283d816a-faa4-4dc6-8878-66da703f18c5';
    // this.CheckInStatusRecord = await this.checkinStatusService.getCheckInStatus(this.actUuid).toPromise();
    // console.log('ngOnInit() ...');

    this.subscription = this.route.paramMap.subscribe(async params => {
      // console.log('subscription .....');
      this.actID = +params.get('id');
      // console.log({ actID : this.actID });
      this.currentActivity = await this.activityService.getActByID(this.actID).toPromise();
      // console.log({ currentActivity : this.currentActivity });
      this.actUuid = this.currentActivity.act_uuid;
      const tempPeriods = await this.activityService.getActPeriodsByActID(this.actID).toPromise();
      this.actPeriodDates = tempPeriods.map( pd =>  pd.act_date_string );
      this.currentDate = this.currentPeriod ? this.currentPeriod.act_date_string : this.actPeriodDates[0];
      this.periodSE = this.currentPeriod ? this.currentPeriod.start_period + '~' + this.currentPeriod.end_period : '';

      this.currentPeriod = tempPeriods.filter( pd => pd.act_date_string === this.currentDate)[0];
      this.periods = tempPeriods ;

      // this.periods = await this.activityService.getActPeriodsByActID(this.actID).toPromise();
      // console.log(this.currentDate);

      await this.getSignupRecords();  // 取得預先報名名單

      await this.refreshCheckStatusRecords(); // 取得報到紀錄

      this.makeFinalDataSet();  //

      this.filterByRole() ;

      await this.getLastReCalcTime(this.currentActivity);

    });

    // 偵測是否捲到頁面最底下？
    this.scrollDispatcher.scrolled(500).pipe(
      takeUntil(this.dispose$)
    ).subscribe(async (ev) => {
      const scroll = this.viewportScroller.getScrollPosition();
      // console.log({ scroll });
      if (
        (scroll[1] > this.beforeScrollY) &&
        ((window.innerHeight + scroll[1]) >= (document.body.scrollHeight - (window.innerHeight / 3)))
      ) {
        this.beforeScrollY = scroll[1];
        if (!this.theEnd) {
          await this.loadUIRecords();
        } else {
          this.dispose$.next();
        }
      }
    });

    // console.log(' activity-list.ngOnInit() ....');
    const y = this.store.snapshot();

    // 當使用者切換角色時
    this.subscription = this.store.subscribe(x => {
      // console.log('activity list -- currentRole', x);
      // 找出此角色對應的處室清單
      // const depts = this.permissionService.getMyDepts();
      // console.log(depts);
      // depts.forEach(dept => {
      //   this.dicDepts[dept.deptCode] = dept ;
      // });
      // 根據角色取得活動清單
      this.curRole = x.role;
      // console.log({ curRole: this.curRole });
      // if (this.curRole && this.curRole.schoolCode) {
      // }
    });
    // @ts-ignore
    this.isInApp = !!window.ReactNativeWebView;

  }

  // 取得報名名單
  async getSignupRecords() {
    // console.log('getSignupRecords() ...');
    this.namelist = await this.signupService.getSignupRecords(this.currentActivity.act_uuid).toPromise() ;
  }

  // 更新報到狀況紀錄
  async refreshCheckStatusRecords() {
    // console.log('refreshCheckStatusRecords() ...');
    // this.CheckInStatusRecord = await this.checkinStatusService.getCheckInStatus(this.actUuid).toPromise();
    // 1. 取得指定活動，在指定日期的報到紀錄。
    this.rawCheckStatusRecords = await this.activityService.getCheckinStatus(this.actUuid, this.currentDate).toPromise();

    this.dicCheckInStatusRecords = new Map(); // 用來判斷報名者是否已經報到了。

    // 處理要呈現的文字
    this.rawCheckStatusRecords.forEach(statusRec => {
      statusRec.role_text = this.userService.convertRoleText(statusRec.role);
      if (statusRec.checkin_time) { statusRec.checkin_time_string = moment(statusRec.checkin_time).format('YYYY-MM-DD HH:mm:ss'); }
      if (statusRec.signout_time) { statusRec.signout_time_string = moment(statusRec.signout_time).format('YYYY-MM-DD HH:mm:ss'); }

      // 用來判斷報名者是否已經報到了。
      if (statusRec.signup_id) {  // 如果已有預先報名
        if (!this.dicCheckInStatusRecords[statusRec.signup_id.toString()]) {
          this.dicCheckInStatusRecords[statusRec.signup_id.toString()] = statusRec ;  // 預先報名編號對應報到紀錄
        }
      }

      statusRec.checkin_type_text = this.getCheckInTypeText(statusRec.checkin_type);

      // console.log(this.dicCheckInStatusRecords);

    });

    this.totalCount = this.rawCheckStatusRecords.length;
  }

  getCheckInTypeText( checkinType: string ): string {
    if (checkinType === 'qrcode') { return checkinType; }
    if (checkinType === 'form') { return '填表'; }
    if (checkinType === 'manual') { return '人工'; }
    return checkinType ;
  }

  makeFinalDataSet() {

  }

  // 篩選出符合指定角色的紀錄
  filterByRole() {
    // console.log('filterByRole() ...');
    this.resetScroll();
    let tempDataSet: CheckInStatusRecord[] = [];

    // 找出符合的紀錄
    if (this.currentRole === '0') { // 顯示全部
      tempDataSet = this.rawCheckStatusRecords.filter( raw => true );
    } else if (this.currentRole === 'others') { // 如果是其他
      // console.log('filter by others ...');
      // const teacherRoleText = this.userService.convertRoleText('teacher');
      // const studentRoleText = this.userService.convertRoleText('student');
      // const parentRoleText = this.userService.convertRoleText('parent');

      this.rawCheckStatusRecords.forEach(statusRec => {
        // console.log(statusRec.role_text);
        // if ((statusRec.role_text !== teacherRoleText) &&
        //     (statusRec.role_text !== studentRoleText) &&
        //     (statusRec.role_text !== parentRoleText)) {
        //   tempDataSet.push(statusRec);
        // }
        if (statusRec.checkin_type === 'form') {
          tempDataSet.push(statusRec);
        }
      });
    } else {  // 如果是老師學生家長
      const targetRoleText = this.userService.convertRoleText(this.currentRole);
      this.rawCheckStatusRecords.forEach(statusRec => {
        if (statusRec.role_text === targetRoleText && (statusRec.checkin_type !== 'form')) {
          tempDataSet.push(statusRec);
        }
      });
    }

    // 計算數量
    this.totalCount = tempDataSet.length;
    let signupCount = 0;
    tempDataSet.forEach(statusRec => {
      if (statusRec.signup_id) {
        signupCount += 1;
      }
    });
    this.signupCount = signupCount;

    //
    const currentRoleText = this.userService.convertRoleText(this.currentRole);
    // 對於每個已經報名的人員, 倘若尚未報到，則也要加入顯示清單中 ... (此畫面除了顯示已報到清單外，也要顯示已報名但尚未報到的名單)
    this.namelist.forEach(signup => {
      const signupRoleText = this.userService.convertRoleText( signup.role) ;
      // console.log(`${this.currentRole} , ${currentRoleText} === ${signupRoleText}`);
      // 如果這位報名者尚未報到，且身份與指定顯示的身份相符，則加入清單中 ...
      if (!this.dicCheckInStatusRecords[signup.id]) {
        if (this.currentRole === '0' || signupRoleText === currentRoleText) {

          const ck = new CheckInStatusRecord();
          ck.name = signup.name ;
          ck.school_name = signup.school_name ;
          ck.school_type = signup.school_type ;
          ck.school_code = signup.school_code ;
          ck.role = signup.role ;
          ck.role_text = this.userService.convertRoleText(signup.role);
          ck.signup_id = parseInt(signup.id, 10) ;
          tempDataSet.push(ck);

        }
      }
    });

    // this.length = tempDataSet.length ;

    this.tempCheckInStatusRecords = tempDataSet ;

    this.loadUIRecords();  // 顯示此頁內容

  }

  resetScroll() {
    this.page = 0;  // reset to the first page.
    this.pagedCheckInStatusRecords = [];
    this.beforeScrollY = 0;
  }

  // changePage(evt) {
  //   // console.log('changePage() ...');
  //   this.pageEvent = evt ;

  //   // console.log({ evt });
  //   this.showPagedRecords();
  // }

  // showPagedRecords() {
  //   const currentPageIndex = (this.pageEvent ? this.pageEvent.pageIndex : 0 ) ;
  //   const currentPageSize = ( this.pageEvent ? this.pageEvent.pageSize : this.pageSize );
  //   const beginIndex = currentPageIndex * currentPageSize ;
  //   const endIndex = (currentPageIndex + 1) * currentPageSize ;
  //   this.pagedCheckInStatusRecords = this.tempCheckInStatusRecords.slice(beginIndex, endIndex);
  // }

  /** 每次呼叫就加入下一頁的紀錄 */
  loadUIRecords() {
    // console.log(' loadUIRecords .... ');
    const beginIndex = this.page * this.limit ;
    const endIndex = (this.page + 1) * this.limit ;
    this.page += 1;
    const tempRecords = this.tempCheckInStatusRecords.slice(beginIndex, endIndex);
    tempRecords.forEach( rec => {
      this.pagedCheckInStatusRecords.push(rec);
    });
    // this.pagedCheckInStatusRecords = this.pagedCheckInStatusRecords.concat(tempRecords);
    // console.log( { page: this.page, recs: this.pagedCheckInStatusRecords });
    this.change.detectChanges();
  }

  // 關鍵字搜尋
  // searchStuds(evt) {
  //   // console.log( { searchString: this.searchString, evt });
  //   let result: CheckInStatusRecord[] = [];
  //   if (!this.searchString) {
  //     result = this.tempCheckInStatusRecords;
  //   }

  //   this.tempCheckInStatusRecords.forEach( r => {
  //     if (r.name.indexOf(this.searchString) > -1) {
  //       result.push(r);
  //     }
  //   });
  //   this.pagedCheckInStatusRecords = result ;

  // }



  // downloadCheckin() {
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.checkinTable.nativeElement, { raw: false, dateNF: 'yyyy/mm/dd HH:mm' });
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   XLSX.writeFile(wb, this.currentActivity.title + '(報到狀況).xlsx');
  // }

  async downloadCheckinData() {
    // 參考資料：https://www.mdeditor.tw/pl/2tnQ/zh-tw
    // 1. 將資料切割成不同的角色，要放到不同的 worksheet;

    try {
    //   // const keyInfo: any = await this.checkinService.downloadCert(this.currentHistory.act_uuid).toPromise();
      const keyInfo = await this.checkinStatusService.createDownloadFile(
        // this.tempCheckInStatusRecords,
        this.rawCheckStatusRecords,
        this.currentRole,
        this.currentPeriod.start_period ? this.currentPeriod.start_period.toString() : '',
        this.currentPeriod.end_period ? this.currentPeriod.end_period.toString() : '').toPromise();
      // console.log(keyInfo.key);
      // console.log('------- open this file --------- ');
      window.location.href = `/service/checkinstatus/download_excel?key=${keyInfo.key}`;  // 不能使用 this.router.navigateByUrl[]
    } catch (error) {
      // 尚未登入，顯示訊息後，轉到登入頁面
      window.alert(error.error.error);
    }


    return ;
/*
    if (this.isInApp) {
      window.alert('請使用電腦版下載報到紀錄檔案');
      return ;
    }

    const roles = ['teacher', 'student', 'parent'];
    const tempCollection: {[role: string]: CheckInStatusRecord[]} = {} ;
    const targetSheets = [];
    this.tempCheckInStatusRecords.forEach(statusRec => {
      // if (roles.indexOf(statusRec.role) > -1) {   // 如過是在四種角色中
      // let roleText = (!!statusRec.role_text ? statusRec.role_text : '無');
      let sheetName = ((roles.indexOf(statusRec.role) > -1) ? statusRec.role_text : '其它');
      // 要符合 excel worksheet 的 naming rules : https://www.excelcodex.com/2012/06/worksheets-naming-conventions/
      const aryExpChars = ['\\' , '/' , '*' , '?' , ':' , ',', '[' , ']'];  // 去掉特殊字元，以符合 worksheet name 規則
      aryExpChars.forEach( chr => {
        sheetName = sheetName.replace(chr, '_');
      });

      if (!tempCollection[sheetName]) {
        tempCollection[sheetName] = [];
        targetSheets.push(sheetName);
      }
      tempCollection[sheetName].push(statusRec);
      // }
    });
    // 2. 對於每一種角色，建立一個 worksheet
    const sheets = {};

    const all = '全部';
    sheets[all] = this.createSheet(this.tempCheckInStatusRecords, all);

    targetSheets.forEach( sheetName => {
      // console.log({ role });
      const targetRecords = tempCollection[sheetName];
      const sheet = this.createSheet( tempCollection[sheetName], sheetName );
      sheets[sheetName] = sheet ;
    });

    targetSheets.push(all);

    const workbook = {
      SheetNames: targetSheets ,
      Sheets: sheets
    };

    // console.log(workbook);

    XLSX.writeFile(workbook, this.currentActivity.title + '(報到狀況).xlsx');
*/
  }
/*
  createSheet(data: CheckInStatusRecord[], sheetName: string) {

    const result = [];
    if (sheetName === '教師') {
      result.push(['姓名', '單位(學校)', '學制', '班級', '報到方式', '電話', '角色', '報到時間', '簽退時間', '節次開始', '節次結束']);
      data.forEach( item => {
        if (item.checkin_time_string) {
          result.push([ item.name, item.school_name, item.school_type, item.class_name,
                      (item.checkin_type_text)
                      , item.tel_no, item.role_text, item.checkin_time_string, item.signout_time_string
                      , this.currentPeriod.start_period, this.currentPeriod.end_period]);
        }
      });
    } else if (sheetName === '其它') {
      result.push(['姓名', '單位(學校)', '報到方式', '電話', '角色', '報到時間', '簽退時間', '節次開始', '節次結束']);
      data.forEach( item => {
        if (item.checkin_time_string) {
          result.push([ item.name, item.school_name,
                      (item.checkin_type_text)
                      , item.tel_no, item.role_text, item.checkin_time_string, item.signout_time_string
                      , this.currentPeriod.start_period, this.currentPeriod.end_period]);
        }
      });
    } else  {
      result.push(['姓名', '單位(學校)', '學制', '班級', '座號', '報到方式', '電話', '角色', '報到時間', '簽退時間', '小孩姓名', '學號', '節次開始', '節次結束']);
      data.forEach( item => {
        if (item.checkin_time_string) {
          result.push([ item.name, item.school_name, item.school_type, item.class_name, item.seat_no,
                      (item.checkin_type_text)
                      , item.tel_no, item.role_text, item.checkin_time_string, item.signout_time_string
                      , item.child_name, item.stud_number, this.currentPeriod.start_period, this.currentPeriod.end_period]);
        }
      });
    }
    return XLSX.utils.aoa_to_sheet(result);
  }
*/
  changeRole(evt) {
    // console.log(this.currentRole);
    this.filterByRole();
  }

  async changeDate(evt) {
    // console.log({ evt });

    await this.refreshCheckStatusRecords();
    this.filterByRole() ;
    try {
      this.currentPeriod = this.periods[evt.source._selectionModel._selected[0].id];
      console.log({ period : this.currentPeriod });
      this.periodSE = this.currentPeriod.start_period.toString() + '~' + this.currentPeriod.end_period.toString();
    }
    catch (error) {
      this.periodSE = '';
    }
  }

   // 開啟確認重新計算時數的對話視窗
   async openConfirmForm(templateRef: TemplateRef<any>) {
    this.modalRef = this.bsModalSrv.show(templateRef);
  }

  async reCalcHours( act: ActivityRecord) {
    // console.log( { act });

    this.isInReCalculating = true ;
    try {
      const result = await this.checkinStatusService.reCalcHours(act.act_uuid).toPromise();
      await this.refreshCheckStatusRecords(); // 重新取得報到紀錄
      this.makeFinalDataSet();  //
      this.filterByRole() ;
      await this.getLastReCalcTime(this.currentActivity);
      this.modalRef.hide();
    } catch (ex) {
      console.log({ex});
    } finally {
      this.isInReCalculating = false ;
    }
  }

  async getLastReCalcTime( act: ActivityRecord) {
    this.lastReCalculateTime = '';
    try {
      const lastCalcTime = await this.checkinStatusService.getLastReCalcTime(act.act_uuid).toPromise();
      if (lastCalcTime) {
        this.lastReCalculateTime = lastCalcTime.create_time ;
      }
    } catch (ex) {
      console.log( { ex });
    }
  }

  // 上傳教師研習網
  openINSC( act: ActivityRecord) {

    // 只有處室建立的活動才能上傳教研網
    if (!this.currentActivity.dept_code) {
      this.snackBar.open(`提醒`, `請輸入研習編號只有處室建立的活動才能上傳教研網。`, {
        duration: 2000,
      });
      return ;
    }

    const dialog = this.bsModalSrv.show(UploadInscComponent, {
      class: 'mw-460',
      initialState: {
        currentActivity: act,
        currentDate: this.currentDate,
        currentPeriod: this.currentPeriod
      },
    });

    this.bsModalSrv.onHide.pipe(take(1), filter(reason => true)).subscribe(() => {
      // Do anything here, this will get called only once
      console.log('data saved and refresh actviity .');
      this.refreshCheckStatusRecords().then( () => {
        this.makeFinalDataSet();
        this.filterByRole() ;
      });
    });
  }

  // 人工報到
  openCheckIn( act: ActivityRecord) {
    // this.parseManulaCheckinRecords();
    // this.modalRef = this.bsModalSrv.show(templateRef);

    // console.log( ' openChechIn ');
    // console.log( { nameList : this.namelist});
    this.mode = 'manual_checkin';

    // const dialog = this.bsModalSrv.show(ManualCheckinComponent, {
    //   initialState: {
    //     nameList: this.namelist,
    //     checkInList: this.rawCheckStatusRecords,
    //     currentDate: this.currentDate,
    //     actUuid: this.actUuid
    //   },

    // });

    // this.bsModalSrv.onHide.pipe(take(1), filter(reason => true)).subscribe(() => {
    //   // Do anything here, this will get called only once
    //   console.log( ' closeChechIn ');
    //   console.log('data saved and refresh actviity .');
    //   // this.refreshCheckStatusRecords().then( () => {
    //   //   this.makeFinalDataSet();
    //   //   this.filterByRole() ;
    //   // });
    // });
  }
  async onManualCheckInClosed(evt) {
    // console.log( ' closeChechIn ');
    // console.log('data saved and refresh actviity .');
    this.mode = 'checkin_status';
    await this.reCalcHours(this.currentActivity);
    this.refreshCheckStatusRecords().then( () => {
      this.makeFinalDataSet();
      this.filterByRole() ;
    });
  }

  // 人工簽退
  openCheckOut(act: ActivityRecord) {
    // console.log( ' openCheckOut ');
    // console.log( { checkInList : this.rawCheckStatusRecords});
    this.mode = 'manual_signout';

    // const dialog = this.bsModalSrv.show(ManualSignoutComponent, {
    //   initialState: {
    //     checkInList: this.rawCheckStatusRecords,
    //     currentDate: this.currentDate,
    //     actUuid: this.actUuid
    //   },
    // });

    // this.bsModalSrv.onHide.pipe(take(1), filter(reason => true)).subscribe(() => {
    //   // Do anything here, this will get called only once
    //   console.log('data saved and refresh actviity .');
    //   this.refreshCheckStatusRecords().then( () => {
    //     this.makeFinalDataSet();
    //     this.filterByRole() ;
    //   });
    // });
  }

  editIssuedHours(cis: CheckInStatusRecord) {
    // console.log({cis});
    this.selectedCIS = cis ;
    this.newIssuedHours = cis.issued_hours || 0 ;
    // console.log( { selectedCIS : this.selectedCIS, newIssuedHours: this.newIssuedHours });

    // this.dialog = this.bsModalSrv.show(this.tplEditIssuedHours, {
    //   initialState: {
    //   },
    // });

    const dialog = this.bsModalSrv.show(EditIssueHoursComponent, {
      class: 'mw-460',
      initialState: {
        selectedCIS: cis,
        actUuid: this.actUuid
        // currentActivity: act,
        // currentDate: this.currentDate,
        // currentPeriod: this.currentPeriod
      },
    });

    this.bsModalSrv.onHide.pipe(take(1), filter(reason => true)).subscribe(() => {
      // Do anything here, this will get called only once
      // console.log('data saved and refresh actviity .');
      this.refreshCheckStatusRecords().then( () => {
        this.makeFinalDataSet();
        this.filterByRole() ;
      });

      // this.refreshCheckStatusRecords().then( () => {
      //   this.makeFinalDataSet();
      //   this.filterByRole() ;
      // });
    });
  }

  // closeDialog() {
  //   this.dialog.hide();
  // }

  // async saveIssuedHours() {
  //   console.log(` from : ${this.selectedCIS.issued_hours} to ${this.newIssuedHours}`);
  //   if (this.selectedCIS.issued_hours !== this.newIssuedHours) {
  //     // 進行變動
  //     await this.activityService.updateIssuedHours( this.actUuid, this.selectedCIS.id,
  //                                                   this.selectedCIS.issued_hours, this.newIssuedHours).toPromise();
  //     await this.refreshCheckStatusRecords();
  //     this.makeFinalDataSet();
  //     this.filterByRole() ;
  //   }

  //   this.closeDialog();
  // }


  /**
   * 解析手動報到畫面所需要的資料
   * 1. 所有教師一包，按照學校與姓名排序
   * 2. 學生，按照每個學校一包
   * 3. 家長，按照每個學校一包
   */
  parseManulaCheckinRecords() {

  }


}
