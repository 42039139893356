import { AP2IMDBTeacherInfo, ImdbService, TeacherRoleInfo } from './../core/imdb.service';
import { Component, OnInit } from '@angular/core';
import { DeptInfo, PermissionService } from '../core/permission.service';
import { Router } from '@angular/router';
import { UserService } from '../core/user.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {

  userInfo: any = {} ;
  curRole: TeacherRoleInfo = {} as TeacherRoleInfo;
  myRoles: TeacherRoleInfo[] = [];
  isLoading = true;  // 預設開啟時，狀態是載入中
  allSchoolDepts: DeptInfo[]; // 學校的所有處室清單，來自AP2 中介
  ap2Teachers: AP2IMDBTeacherInfo[] = []; // 學校的所有教師職稱，來自AP2 中介
  isAdmin = false ;    // 是不是校管理者。每切換身份時都要確認

  constructor(
    private router: Router,
    private userService: UserService,
    private imdbService: ImdbService,
    private permissoinService: PermissionService
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      this.userInfo = await this.userService.getMyInfo().toPromise();
      const roles = await this.imdbService.getTeacherInfo().toPromise() || [];
      this.parseUserInfo(roles);
      await this.loadTeacherTitles(); // 從 AP2 中介取得全校教師職稱，要給下層兩個元件使用。
    } catch {

    } finally {
      this.isLoading = false;
    }

  }

  async loadTeacherTitles() {
    this.ap2Teachers = await this.imdbService.getSchoolTeachersFromAP2(this.curRole.schoolCode, this.curRole.schoolType).toPromise();
    this.extractAllDepts();
  }

  /** 從 AP2 中介取回的教師清單，解析出所有處室清單 */
  extractAllDepts() {
    const dicDept = {};  // 這個 dictionary 確保不會重複
    this.allSchoolDepts = [];
    this.ap2Teachers.forEach(tea => {
      if (tea.DepartmentCode) {
        if (!dicDept[tea.DepartmentCode]) {
          dicDept[tea.DepartmentCode] = tea.Department;
          const dept = new DeptInfo();
          dept.deptCode = tea.DepartmentCode;
          dept.deptName = tea.Department;
          this.allSchoolDepts.push(dept);
        }
      }
    });
  }


  parseUserInfo(roles) {
    this.myRoles = [];
    // console.log(roles);
    roles.forEach(schoolRole => {
      if (schoolRole.isAdmin || schoolRole.isAgent) {
        this.myRoles.push(schoolRole);
      }
    });
    if (this.myRoles.length === 0) {
      window.alert('您沒有管理權限！');
      this.goBack();
      return ;
    }
    this.curRole = this.myRoles[0];
    this.isAdmin = this.curRole.isAdmin ;
    console.log(this.curRole);
  }

  async setCurRole(role) {
    this.curRole = role;
    this.isAdmin = this.curRole.isAdmin ;
    await this.loadTeacherTitles();
  }

  async logout() {
    try {
      await this.userService.logout().toPromise();
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      // this.router.navigateByUrl('/manage');
      window.location.reload();
    } catch (error) {

    }
  }

  async reLogin() {
    await this.userService.logout().toPromise();
    const nextUrl = `${window.location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}/auth/login_manage`;
    const logoutUrl = `https://sso.tp.edu.tw/oauth2/logout.do?redirect_uri=${encodeURIComponent(nextUrl)}`;
    // console.log(logoutUrl);
    window.location.href = logoutUrl;
    // this.router.navigate(['/']);
  }

  goBack() {
    window.location.href = '/main';
  }
}

