import { TeacherRoleInfo } from './../core/imdb.service';
import { DeptInfo, PermissionService } from './../core/permission.service';
import { UserService } from 'src/app/core/user.service';
import { ActivatedRoute, NavigationExtras, Router, NavigationEnd } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImdbService } from '../core/imdb.service';
import { Store, Select } from '@ngxs/store';
import { UserRec, UserRoleRec } from '../data';
import { SetCurRole } from '../state/role.action';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  currentSchoolCode: string;
  currentSchoolType: string;
  currentRoleType: string;
  currentUUID: string;
  currentAccessToken: string;
  currentRoute = '';

  userInfo: any = {};
  userRoleList: UserRoleRec[] = [];
  curRole: UserRoleRec = {} as UserRoleRec;
  unsubscribe$ = new Subject();
  // ap2RoleList: AP2RoleRec[] = [] ;
  isScIT: false;  // 是否資訊組長
  isTeacher = false;   // 是否是教師身份。
  isInApp = false;   // 是否在 App 中開啟
  viewMode: 'checkinHisotry' | 'manage' = 'checkinHisotry';
  isMainMenu = true ;

  curTab = '';

  constructor(
    private imdbSrv: ImdbService,
    private store: Store,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    // private imdbService: ImdbService ,
    // private permissionService: PermissionService
  ) {

    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        console.log(event.url);
        this.isMainMenu = (event.url === '/main' || event.url === '/');
        console.log( { isMainMenu: this.isMainMenu});
      }
    });
  }

  async ngOnInit() {
    // @ts-ignore
    this.isInApp = !!window.ReactNativeWebView;
    console.log(` isInApp: ${this.isInApp}`);

    this.viewMode = this.isInApp ? 'checkinHisotry' : 'manage';

    const { school_type, school_code, role_type, uuid, access_token } = this.route.snapshot.queryParams;
    this.currentSchoolCode = school_code;
    this.currentSchoolType = school_type;
    this.currentRoleType = role_type;
    this.currentUUID = uuid;
    this.currentAccessToken = access_token;

    console.log(this.currentAccessToken);

    // 如果有 access token，代表是在 mobile 開啟的（可能是掃碼完後開啟，或是掃碼取消後開啟，或是 icon直接開啟）
    // 不論哪一種方式開啟，就到 server 以 access token 跑一次登入流程後，會再 redirect 回這個畫面。
    if (this.currentAccessToken) {
      console.log('redirect to token_signin 1234...');
      const resp = await this.userService.token_signin(this.currentAccessToken).toPromise();
      // console.log(resp);

      // window.location.href = `/auth/callback/token_signin?accessToken=${this.currentAccessToken}&reload_page=false`;
      // return ;
    }

    // 取得使用者的教師身份
    // console.log(' get Teacher Info ...');
    await this.getTeacherInfo();
    // await this.getSchoolDepts();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async getTeacherInfo() {
    let isAdminOrNot = false;
    try {
      this.userInfo = await this.userService.getMyInfo().toPromise();

      const rsp = await this.imdbSrv.getTeacherInfo().toPromise();

      const teacherRoles = [];
      // console.log(rsp);
      // 對於使用者在每個學校的角色清單
      [].concat(rsp || []).forEach(data => {
        const { schoolName, schoolCode, schoolType, roleList, rawRoles, isAdmin } = data;
        // 把來自酷課雲的每個學校的身份角色儲存起來，作為使用者切換身份用
        // const dataList: UserRoleRec[] = [].concat(roleList || []).map(roleRec => {
        //   const { dept, deptID, title, role } = roleRec;
        //   return { schoolCode, schoolName, schoolType, dept, deptID, title, role};
        // });
        isAdminOrNot = isAdminOrNot || isAdmin;

        const dataList: UserRoleRec[] = [].concat(roleList || []).map(roleRec => {
          const { DeptCode, Dept, TitleCode, Title, UnitCode, Unit } = roleRec;
          return {
            schoolCode, schoolName, schoolType,
            dept: Dept, deptCode: DeptCode,
            unitCode: UnitCode, unit: Unit,
            titleCode: TitleCode, title: (roleRec.role === 'teacher' ? '教師' : Title)
          };
        });
        // const dataList: UserRoleRec[] = [].concat(rawRoles || []).map(roleRec => {
        //   const { DeptCode, Dept, TitleCode, Title, UnitCode, Unit } = roleRec;
        //   return { schoolCode, schoolName, schoolType,
        //             dept: Dept, deptCode: DeptCode,
        //             unitCode: UnitCode, unit: Unit,
        //             titleCode: TitleCode,  title:  Title };
        // });
        this.userRoleList = this.userRoleList.concat(dataList || []);

        // 把來自 ap2 的每個學校的身份角色儲存起來, 作為使用者切換身份時，所具有的處室清單。
        // const ap2Roles: AP2RoleRec[] = [].concat(rawRoles || []).map(roleRec => {
        //   const { DeptCode, Dept, TitleCode, Title, UnitCode, Unit } = roleRec;
        //   return { schoolCode, schoolName, schoolType, DeptCode, Dept, TitleCode, Title, UnitCode, Unit};
        // });
        // this.ap2RoleList = this.ap2RoleList.concat(ap2Roles || []);
      });

      // console.log(`userRoleList : ${JSON.stringify(this.userRoleList)}`);
      // console.log(`ap2RoleList : ${JSON.stringify(this.ap2RoleList) }`);

      const user: UserRec = {
        roleList: this.userRoleList
      };

      this.isTeacher = (this.userRoleList.length > 0);

      console.log( { isTeacher: this.isTeacher});

      if (!this.isTeacher && !this.isInApp) {
        // throw '沒有教師身分';
        this.viewMode = 'checkinHisotry';
        console.log(this.viewMode);
      }

      this.curRole = (this.userRoleList.length > 0 ? this.userRoleList[0] : {} as UserRoleRec);
      // this.updateMyDeptsByRole();
      // console.log(`dispatch curRole .... ${this.curRole}`);
      // this.store.dispatch(new InitUser(user));
      this.store.dispatch(new SetCurRole(this.curRole));

      this.isScIT = isAdminOrNot;

      // console.log(this.curRole);
    } catch (error) {
      console.log(error);
      // window.alert('很抱歉，此系統僅開放給教師或行政人員使用！');
      this.router.navigate(['signin']);
    }
  }

  /** 根據使用者目前身份，找出對應的處室職稱, 好讓顯示活動的所屬處室 */
  // updateMyDeptsByRole() {
  //   const depts: DeptInfo[] = [];
  //   const dicDepts: { [code: string]: DeptInfo} = {};
  //   this.ap2RoleList.forEach( ap2Role => {
  //     if (ap2Role.schoolCode === this.curRole.schoolCode &&
  //         ap2Role.schoolType === this.curRole.schoolType )
  //     {
  //           if (!dicDepts[ap2Role.DeptCode]) {
  //             const dept = new DeptInfo();
  //             dept.deptCode = ap2Role.DeptCode ;
  //             dept.deptName = ap2Role.Dept ;
  //             dicDepts[ap2Role.DeptCode] = dept ;
  //             depts.push(dept);
  //           }
  //     }
  //   });

  //   this.permissionService.setMyDepts(depts);
  // }

  setCurRole(role: UserRoleRec) {
    this.curRole = role;
    this.store.dispatch(new SetCurRole(role));
  }

  async logout() {
    try {
      // await this.userService.logout().toPromise();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      // this.router.navigateByUrl('/');
      // const navigationExtras: NavigationExtras = {
      //   queryParams: { next : 'https://cooc.tp.edu.tw' },
      //   fragment: 'anchor'
      // };
      // this.router.navigate(['logout']);
      // this.router.navigate(['auth', 'logout'], navigationExtras);
      window.location.href = '/auth/logout?next=https://cooc.tp.edu.tw';
    } catch (error) {

    }
  }

  setViewMode(mode) {
    this.viewMode = mode;
    console.log(this.viewMode);
  }

  ShowManage() {
    this.router.navigate(['manage']);
  }



  setTab(tab) {

  }
}
