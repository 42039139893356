import { UserService } from './../user.service';
import { ImdbService, IMDBStudentInfo } from './../imdb.service';
import { Observable } from 'rxjs';


export class ParentImporter {

  rawData: any ;
  matchedData = [];
  unmatchedData = [];
  schoolStudents: any;

  _schoolCode: string;
  _schoolType: string ;

  constructor(
    data: any,
    schoolCode: string,
    schoolType: string,
    private imdbService: ImdbService,
    private userService: UserService
    ) {

    this.rawData = data ;
    this._schoolCode = schoolCode ;
    this._schoolType = schoolType ;
  }

  /** 根據匯入學生資料的學號，比對中介裡的學生資料 */
  public match(): Observable<any> {

    const result = new Observable((observer) => {
      // 1. 讀取中介的學校學生資料
      this.imdbService.getSchoolStudents(this._schoolCode, this._schoolType).subscribe( {
        next: (students) => {
          // console.log(students);
          // 2. 比對目前報名學生與學校資料，找出身分證號，班級等資料，也清出找不到的學生
          this.compareStudents(students);
          // 3. 找到後，回傳學生資料
          observer.next({
            matched_data: this.matchedData ,  // 有比對到的學生資料
            unmatched_data: this.unmatchedData  // 沒有比對到的學生資料
          });
        },
        error: (error) => {
          // console.log(error) ;
          observer.error(error);
        }
      });

    });

    return result ;
  }

  // /** 取得中介的學生資料 */
  // public getSchoolStudents() {
  //   return this.schoolStudents ;
  // }

  /** 根據匯入資料的學號比對中介的學生資料 */
  private compareStudents(students: IMDBStudentInfo[]) {
    // 1.把所有中介學生放到 dictionary 中
    const dicStudents: {[studnumber: string]: IMDBStudentInfo} = {};
    students.forEach( stud => {
      if (!dicStudents[stud.StudentNumber]) {
        dicStudents[stud.StudentNumber] = stud ;
      }
    });
    // 2. 比對 Excel 中的學生資料
    this.matchedData = [];
    this.unmatchedData = [];
    this.rawData.forEach( row => {
      // console.log(row);
      const studNo = row['小孩學號'];
      const targetStud = dicStudents[studNo];
      row.name = row['小孩姓名'];
      row.studNo = studNo ;
      row.type = 'parent'; // 類型是學生
      row.role_type_text = this.userService.convertRoleText(row.type);
      row.isSaved = false;
      if (targetStud) {
        row.targetStud = targetStud;
        this.matchedData.push(row);
      } else {
        this.unmatchedData.push(row);
      }
    });
  }

}
