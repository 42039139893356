import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class INSCService {

  constructor(
    private http: HttpClient
  ) { }

  /** 送出, 由 server 送給教研網 */
  lastRec(actUuid: string): Observable<any> {
    return this.http.get(`/service/insc/${actUuid}/last`);
  }

  /** 找出指定活動，可核發時數的教師人數 */
  teacherCount(actUuid: string, actPeriodId: number): Observable<any> {
    return this.http.get(`/service/insc/${actUuid}/teacher_count?act_period_id=${actPeriodId}`);
  }

  createUploadBatch(actUuid: string, inscCode: string, actPeriodId: number): Observable<any> {
    return this.http.get(`/service/insc/${actUuid}/create_batch?insc_code=${inscCode}&act_period_id=${actPeriodId}`);
  }

  /** 送出, 由 server 送給教研網 */
  send(batchUuid: string, tea: any): Observable<any> {
    return this.http.post(`/service/insc/${batchUuid}/send`, tea);
  }



}
