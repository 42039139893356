<div>
  <div class="d-flex mt-3">
    <div class="ml-auto">
      <!-- <button *ngIf="showButton" class="btn btn-outline-cyan" (click)="EditPeri(editP)">
        分場時數設定
      </button> -->
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-rwd">
      <thead class="thead-light">
        <tr>
          <th>活動日期</th>
          <!-- <th>星期</th> -->
          <th>開始時間</th>
          <th>結束時間</th>
          <th>報到</th>
          <th>簽退</th>
          <th>葷食</th>
          <th>素食</th>
          <th *ngIf="hasSections">校務<br/>對應節次</th>
          <th *ngIf="hasIssuedHours">時數</th>
          <th>查看</th>
          <!-- <th class="text-center" style="width: 90px;">功能</th> -->
          <!-- <th>調整</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pd of periods">
          <td data-th="活動日期">{{ pd.act_date_mmdd }} ({{ pd.weekday_string }})</td>
          <!-- <td data-th="星期"></td> -->
          <td data-th="開始時間">{{ pd.act_start_time }}</td>
          <td data-th="結束時間">{{ pd.act_end_time }}</td>
          <td data-th="報到">{{ (dicPeriodStatistics[pd.act_date_string] ?
            dicPeriodStatistics[pd.act_date_string].checkin_count : '' ) }}</td>
          <td data-th="簽退">{{ (dicPeriodStatistics[pd.act_date_string] ?
            dicPeriodStatistics[pd.act_date_string].signout_count : '' ) }}</td>
          <td data-th="葷食">{{ (dicPeriodStatistics[pd.act_date_string] ?
            dicPeriodStatistics[pd.act_date_string].meat_count : '' ) }}</td>
          <td data-th="素食">{{ (dicPeriodStatistics[pd.act_date_string] ?
            dicPeriodStatistics[pd.act_date_string].veg_count : '' ) }}</td>
          <td data-th="校務對應節次"  *ngIf="hasSections">
            <span *ngIf="pd.will_set_periods">{{ pd.start_period }} ~ {{ pd.end_period}}</span>
          </td>
          <td data-th="時數"  *ngIf="hasIssuedHours">
            <span *ngIf="pd.will_issue_hours">{{ pd.issued_hours }}</span>
          </td>
          <td data-th="查看">
            <span class="btn-outline-custom" (click)="showCheckInStatus(pd)">查看</span>
          </td>
          <!-- <td data-th="功能">
            <span class="btn-outline-custom mr-3" (click)="EditPeri(editP, pd)">編輯</span>
            <span class="btn-outline-custom" (click)="CheckInState(pd)">詳細</span>
          </td> -->
          <!-- <td data-th="調整">
            <button class="btn btn-light mr-2" (click)="deletePeriod(pd)">
              <i class="mdi mdi-delete"></i>
            </button>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>

  <!--  -->
  <!-- <mat-paginator
    [length]="pageLength"
    [pageSize]="pageSize"
    [pageIndex]="0"
    [pageSizeOptions]="pageSizeOptions"
    (page)="changePage($event)"
  >
  </mat-paginator> -->
</div>

<!-- <ng-template #editP>
  <div class="modal-content">
    <div class="modal-header pb-2">
      <div class="d-flex justify-content-between w-100">
        <div></div>
        <h4 class="modal-title">分場時數設定</h4>
        <button type="button" class="close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div class="modal-body px-3 px-md-4 mt-4">
      <table class="dtable w-100">
        <tr>
          <td>日期</td>
          <td>時間</td>
          <td>校務對應節次</td>
          <td>核發時數</td>
        </tr>
        <tr *ngFor="let pd of periods">
          <td>{{pd.act_date_mmdd}} ({{ pd.weekday_string }})</td>
          <td class="">{{pd.act_start_time}}-{{pd.act_end_time}}</td>
          <td>
            <div class="d-flex align-items-center">
            <select class="form-select form-control" style="width: 45px;" [(ngModel)]="pd.start_period" >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </select>
            <div class="mx-2">至</div>
            <select class="form-select form-control" style="width: 45px;" [(ngModel)]="pd.end_period">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </select>
          </div>
          </td>
          <td class="text-nowrap">
            <div class="d-flex align-items-center">
            <input type="number" min=0 max="" id="" placeholder="" class="form-control" style="width: 48px;" [(ngModel)]="pd.issued_hours">
            小時
            </div>
          </td>
        </tr>
      </table> -->
      <!-- <div class="py-3">對應節次</div>
      <div class="row px-3">
        <div class="form-check col-3 col-sm">
          <input type="checkbox" class="form-check-input ng-untouched ng-pristine ng-valid" id="chk1">
          <label class="form-check-label" for="chk1">1</label>
        </div>
        <div class="form-check col-3 col-sm">
          <input type="checkbox" class="form-check-input ng-untouched ng-pristine ng-valid" id="chk2">
          <label class="form-check-label" for="chk2">2</label>
        </div>
        <div class="form-check col-3 col-sm">
          <input type="checkbox" class="form-check-input ng-untouched ng-pristine ng-valid" id="chk3">
          <label class="form-check-label" for="chk3">3</label>
        </div>
        <div class="form-check col-3 col-sm">
          <input type="checkbox" class="form-check-input ng-untouched ng-pristine ng-valid" id="chk4">
          <label class="form-check-label" for="chk4">4</label>
        </div>
        <div class="form-check col-3 col-sm">
          <input type="checkbox" class="form-check-input ng-untouched ng-pristine ng-valid" id="chk5">
          <label class="form-check-label" for="chk5">5</label>
        </div>
        <div class="form-check col-3 col-sm">
          <input type="checkbox" class="form-check-input ng-untouched ng-pristine ng-valid" id="chk6">
          <label class="form-check-label" for="chk6">6</label>
        </div>
        <div class="form-check col-3 col-sm">
          <input type="checkbox" class="form-check-input ng-untouched ng-pristine ng-valid" id="chk7">
          <label class="form-check-label" for="chk7">7</label>
        </div>
        <div class="form-check col-3 col-sm">
          <input type="checkbox" class="form-check-input ng-untouched ng-pristine ng-valid" id="chk8">
          <label class="form-check-label" for="chk8">8</label>
        </div>
      </div> -->
      <!--bindings={
  "ng-reflect-ng-for-of": "[object Object],[object Object"
}-->

    <!-- </div>
    <div class="modal-footer justify-content-center py-4">
      <button type="button" class="btn btn-outline-cyan mr-3" style="width: 120px;"
        (click)="modalRef.hide()">關閉</button>
      <button type="button" class="btn btn-cyan" style="width: 120px;" (click)="savePeriods()">儲存</button>
    </div>
  </div>
</ng-template> -->
