import { ActivityPeriod, ActivityService } from './../../../core/activity.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import * as moment from 'moment';

@Component({
  selector: 'app-add-activity-dates',
  templateUrl: './add-activity-dates.component.html',
  styleUrls: ['./add-activity-dates.component.scss']
})
export class AddActivityDatesComponent implements OnInit {

  mode: 'list' | 'add' = 'list';
  repeatMode = "0";
  repeat_start_date: Date;
  repeat_end_date: Date;
  minDate = new Date();
  act_start_time: string;
  act_end_time: string;
  map_school_periods: number[] = [];

  includeSat = false;  // 含週六
  includeSun = false;  // 含週日

  actID: any ;
  periods: ActivityPeriod[] = [];
  dicPeriods: {[actDate: string]: ActivityPeriod} = {};
  startPeriod: ActivityPeriod;

  weekdays = [
    { day: '一', value: 1, isSelected: false },
    { day: '二', value: 2, isSelected: false },
    { day: '三', value: 3, isSelected: false },
    { day: '四', value: 4, isSelected: false },
    { day: '五', value: 5, isSelected: false },
    { day: '六', value: 6, isSelected: false },
    { day: '日', value: 0, isSelected: false },
  ];

  fgActEditor: FormGroup;

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private actService: ActivityService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    if (this.periods && this.periods.length) {
      this.startPeriod = this.periods[0] ;
      this.repeat_start_date = this.startPeriod.act_date;
      this.act_start_time = this.startPeriod.act_start_time;
      this.act_end_time = this.startPeriod.act_end_time;
    }

    this.initFormGroup();
  }

  initFormGroup() {
    this.fgActEditor = new FormGroup({
      repeat_start_date: new FormControl(new Date(), Validators.required),   // 活動日期，第一天
      act_start_time: new FormControl(this.act_start_time, Validators.required), // 活動開始時間
      act_end_time: new FormControl(this.act_end_time, Validators.required),     // 活動結束時間
      repeat_mode: new FormControl('0'),                        // 重複模式： 0：不重複 , 1：每天, 2: 每週
      includeSat: new FormControl(false),
      includeSun: new FormControl(false),
      Mon: new FormControl(false),
      Tue: new FormControl(false),
      Wed: new FormControl(false),
      Thr: new FormControl(false),
      Fri: new FormControl(false),
      Sat: new FormControl(false),
      Sun: new FormControl(false),
      repeat_end_date: new FormControl(new Date(), Validators.required),                // 重複截止日期
      map_school_periods: new FormControl([]),
    });

  }

  selectRepeatMode($event) {
    this.repeatMode = this.fgActEditor.value.repeat_mode;
    console.log(this.repeatMode);
  }

  cancelAddPeriod() {
    this.modalRef.hide();
  }

  AfterChoosingEndDate() {

  }

  async saveAddPeriod() {
    // 1.
    if (!this.fgActEditor.valid) { return; }

    this.fgActEditor.controls['repeat_end_date'].setErrors(null); // clear errors

    const startDate = this.fgActEditor.value.repeat_start_date as Date;
    const endDate = this.fgActEditor.value.repeat_end_date as Date;

    // 2. check if the end date is valid
    startDate.setHours(12, 0, 0, 0);
    endDate.setHours(12, 0, 0, 0);
    if (this.repeatMode === '1' || this.repeatMode === '2') {
      if (endDate < startDate) {
        this.fgActEditor.controls['repeat_end_date'].setErrors({ 'incorrect': true });
        return;
      }
    }

    // 2. find all matched dates ....
    const conditions = this.fgActEditor.value;
    const dates = this.calculateDates(startDate, endDate, conditions);

    // 3. check duplicated dates
    if (!this.checkDuplicateDates(dates)) {
      return ;
    }
    console.log(dates);

    // 4. make request docs :
    const body: ActivityPeriod[] = [];
    dates.forEach(dt => {
      const dtString = moment(dt).format('YYYY-MM-DD');
      const ap: ActivityPeriod = (this.dicPeriods[dtString] || new ActivityPeriod());
      ap.act_date = dt ;
      ap.act_start_time = this.fgActEditor.value.act_start_time ;
      ap.act_end_time = this.fgActEditor.value.act_end_time ;
      // ap.map_school_periods = this.fgActEditor.value.map_school_periods ;
      ap.ref_activity_id = this.actID ;
      ap.act_date_string = dtString ;
      body.push(ap);
    });

    try {
      await this.actService.setActPeriods(this.actID, body).toPromise();
      this.modalService.setDismissReason('data saved');
      this.modalRef.hide();
    }
    catch (error) {
      let msg = '';
      if (error.message === 'error_start_time') {
        msg = '報到開始時間不可大於結束時間';
      }
      if (error.message === 'error_date') {
        msg = '報到日期有誤';
      }
      this.snackBar.open(`設定日期發生錯誤`, msg, {
        duration: 2000,
      });
    }

  }

  // 檢查此活動是否有已存在的日期？如果有，則提醒使用者會覆蓋
  checkDuplicateDates(dates) {
    this.dicPeriods = {};
    this.periods.forEach(period => {
      const dtString = moment(period.act_date).format('YYYY-MM-DD');
      this.dicPeriods[dtString] = period ;
    });
    const duplicateDates = [];
    dates.forEach(dt => {
      const dtString = moment(dt).format('YYYY-MM-DD');
      if (this.dicPeriods[dtString]) {
        duplicateDates.push(dtString);
      }
    });
    if (duplicateDates.length > 0) {
      const msg = `以下日期已經有活動，您要以這資訊覆蓋原有活動內容嗎？ \r\n ${duplicateDates.join(',')}`;
      return (window.confirm(msg)) ;
    } else {
      return true ;
    }

  }




  // 計算出符合條件的所有日期
  calculateDates(startDate: Date, endDate: Date, conditions) {
    console.log(`from date : ${startDate} ~ to : ${endDate}`);

    const resultDates = [];
    let currentDate = startDate;
    if (this.repeatMode === '0') {  // 1.0 不重複
      resultDates.push(currentDate);
    } else if (this.repeatMode === '1') {  // 1.1 如果 repeatMode == '1' 每天，則一直加一天到結束日為止，要注意是否含六日。
      const includeSat = this.fgActEditor.value.includeSat;  // 是否包含週六
      const includeSun = this.fgActEditor.value.includeSun;  // 是否包含週日

      // 1. 從開始日期到結束日期，檢查每一天的星期
      // resultDates.push(startDate);
      while (currentDate <= endDate) {
        const tempDate = moment(currentDate);
        // 判斷是否含六日
        if (tempDate.day() === 0 || tempDate.day() === 6) {
          if ((includeSun && tempDate.day() === 0) ||
            (includeSat && tempDate.day() === 6)) {
            resultDates.push(currentDate);
          }
        }
        else {  // 如果是平日，直接加入
          resultDates.push(currentDate);
        }

        currentDate = tempDate.add(1, 'd').toDate();
        console.log(currentDate);
      }
    } else {  // 1.2 如果 repeatMode == '2' 每週，則到結束日為止，判斷每一天的星期是否符合被選取的星期清單。
      const hasMon = this.fgActEditor.value.Mon;  // 是否包含週一
      const hasTue = this.fgActEditor.value.Tue;  // 是否包含週一
      const hasWed = this.fgActEditor.value.Wed;  // 是否包含週一
      const hasThr = this.fgActEditor.value.Thr;  // 是否包含週一
      const hasFri = this.fgActEditor.value.Fri;  // 是否包含週一
      const hasSat = this.fgActEditor.value.Sat;  // 是否包含週一
      const hasSun = this.fgActEditor.value.Sun;  // 是否包含週一

      // 1. 從開始日期到結束日期，檢查每一天的星期
      while (currentDate <= endDate) {
        const tempDate = moment(currentDate);
        // 判斷
        if ((hasMon && tempDate.day() === 1) ||
          (hasTue && tempDate.day() === 2) ||
          (hasWed && tempDate.day() === 3) ||
          (hasThr && tempDate.day() === 4) ||
          (hasFri && tempDate.day() === 5) ||
          (hasSat && tempDate.day() === 6) ||
          (hasSun && tempDate.day() === 0)) {
          console.log(` add date : ${currentDate} for mode: ${this.repeatMode}`);
          resultDates.push(currentDate);
        }

        currentDate = tempDate.add(1, 'd').toDate();
        console.log(currentDate);
      }
    }
    return resultDates;
  }

  selectSchoolPeriods() {

  }
}
