import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  getUserInfo(accessToken: string): Observable<any> {
    return this.http.get(`/service/checkin/getUserInfo?access_token=${accessToken}`);
  }

  /** 取得存在 session 的使用者帳號 */
  getMyInfo(): Observable<any> {
    return this.http.get(`/service/user/myInfo`);
  }

  logout(): Observable<any> {
    return this.http.get(`/auth/logout`);
  }

  token_signin(accessToken: string): Observable<any> {
    return this.http.get(`/auth/callback/token_signin?accessToken=${accessToken}&reload_page=false`);
  }


  convertRoleText(role: string) {
    let result = '';
    if (!role) {
      return result ;
    }
    else {
      result = role;    // default value
    }
    if (role === 'teacher') { result = '教師'; }
    if (role === 'student') { result = '學生'; }
    if (role === 'parent') { result = '家長'; }

    return result ;
  }
}
