import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActivityService, CheckInStatusRecord } from 'src/app/core/activity.service';

@Component({
  selector: 'app-edit-issue-hours',
  templateUrl: './edit-issue-hours.component.html',
  styleUrls: ['./edit-issue-hours.component.scss']
})
export class EditIssueHoursComponent implements OnInit {

  selectedCIS: CheckInStatusRecord ;
  // dialog: BsModalRef ;
  newIssuedHours = 0;
  actUuid = '';

  constructor(
    private activityService: ActivityService,
    private dialog: BsModalRef
  ) { }

  ngOnInit(): void {
    console.log({ selectedCIS : this.selectedCIS });
    this.newIssuedHours = this.selectedCIS.issued_hours ;
  }

  closeDialog() {
    // this.dialog.hide();
    this.dialog.hide();
  }

  async saveIssuedHours() {
    console.log(` from : ${this.selectedCIS.issued_hours} to ${this.newIssuedHours}`);
    if (this.selectedCIS.issued_hours !== this.newIssuedHours) {
      // 進行變動
      await this.activityService.updateIssuedHours( this.actUuid, this.selectedCIS.id,
                                                    this.selectedCIS.issued_hours, this.newIssuedHours).toPromise();
      // await this.refreshCheckStatusRecords();
      // this.makeFinalDataSet();
      // this.filterByRole() ;
    }

    this.closeDialog();
  }

}
