import { UserService } from 'src/app/core/user.service';
import { ActivityRecord } from './../../core/activity.service';
import { ActStateService } from './../act-state.service';
import { SignupService, SignupRecord } from './../../core/signup.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-namelist',
  templateUrl: './namelist.component.html',
  styleUrls: ['./namelist.component.scss']
})
export class NamelistComponent implements OnInit {

  namelist: SignupRecord[] = [];
  currentActivity: ActivityRecord = {} as ActivityRecord ;
  selectedCount = 0;  // 使用者選取的筆數
  isAllSelected = false ;
  errMsg = '';

  constructor(
    private actStateService: ActStateService,
    private signupService: SignupService,
    private userService: UserService
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentActivity = this.actStateService.getCurrentAct();
    this.reloadData();
  }

  reloadData() {
    this.signupService.getSignupRecords(this.currentActivity.act_uuid).subscribe({
      next: (recs) => {
        this.namelist = recs ;
        this.namelist.forEach( li => {
          li.role_text = this.userService.convertRoleText(li.role);
        })
        this.calculateSelectedCount();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  selectAllChange(evt) {
    this.namelist.forEach( mem => {
      mem.checked = this.isAllSelected ;
    });
    this.calculateSelectedCount();
  }

  selectedChange(evt) {
    console.log(evt);
    this.calculateSelectedCount();
  }

  calculateSelectedCount() {
    // 計算被選取的數量
    let result = 0;
    this.namelist.forEach( mem => {
      if (mem.checked) { result += 1; }
    });
    this.selectedCount = result ;
  }

  async removeSelectedReg() {

    const objs = this.namelist.filter( mem => mem.checked );
    console.log(objs);

    if (!window.confirm(`確定要刪除這 ${objs.length} 筆紀錄嗎？`)) {
      return false;
    }

    let resultIDs: any[] = [];
    try {
      for( let i=0; i < objs.length; i++) {
        const mem = objs[i];
        resultIDs.push(mem.id);
        if (resultIDs.length > 50) {
          await this.signupService.removeSignupRecords(this.currentActivity.act_uuid, resultIDs).toPromise();
          resultIDs = [];
        }
      }

      if (resultIDs.length > 0) {
        await this.signupService.removeSignupRecords(this.currentActivity.act_uuid, resultIDs).toPromise();
      }
      this.reloadData();
    } catch (error) {
      console.log(error.error.error);
      this.errMsg = error.error.error ;
      window.alert(this.errMsg);
    }
  }
}
