<div class="modal-header d-flex justify-content-between">
  <div></div>
  <h4 class="modal-title pull-left">{{ editorMode }}活動</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body px-0 ml-sm-4" style="overflow-y: auto;">
  <div class="container-md">

    <!-- 1. 活動名稱 -->
    <div class="d-flex align-items-center mt-3">
      <label class="col-form-label">
        <h4 class="pr-2" style="white-space: nowrap;"
          [ngStyle]="{ 'color': currentAct.is_from_api ? 'rgba(0,0,0,.38)' : 'rgba(0,0,0,.87)'}">1. 活動名稱</h4>
      </label>
      <input class="form-control w-100" style="max-width: 400px;" id="act_name" placeholder="活動名稱"
        [disabled]="currentAct.is_from_api" [(ngModel)]="currentAct.title">
    </div>

    <!-- 2. 活動日期 -->
    <div class="d-flex flex-wrap align-items-center">
      <div class="">
        <label class="col-form-label">
          <h4 class="pr-2" [ngStyle]="{ 'color': currentAct.is_from_api ? 'rgba(0,0,0,.38)' : 'rgba(0,0,0,.87)'}">2.
            活動日期</h4>
        </label>
      </div>
      <div class="">
        <input type="text" class="form-control" style="width: 150px" #dp="bsDatepicker"
          [disabled]="currentAct.is_from_api" bsDatepicker [(bsValue)]="act_start_date_new"
          [bsConfig]="{ isAnimated: true, dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-default', showWeekNumbers: false }">

        <!-- <input type="date" class="form-control" id="act_begin_date" name="act_date" [disabled]="currentAct.is_from_api"  [(ngModel)]="act_start_date"> -->
      </div>
    </div>
    <div class="d-flex flex-wrap align-items-center ml-3 mb-3">
      <div class="pr-2">
        <input class="form-check-input" type="checkbox" id="flexCheckDefault" [disabled]="currentAct.is_from_api"
          [(ngModel)]="currentAct.is_repeat">
        <label class="form-check-label" for="flexCheckDefault">
          <div>結束日期</div>
        </label>
      </div>
      <div class="" *ngIf="currentAct.is_repeat">
        <input type="text" class="form-control" style="width: 150px" #dp="bsDatepicker"
          [disabled]="currentAct.is_from_api" bsDatepicker [(bsValue)]="act_end_date_new"
          [bsConfig]="{ isAnimated: true, dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-default', showWeekNumbers: false  }">

        <!-- <input type="date" class="form-control" id="act_end_name" [disabled]="currentAct.is_from_api" [(ngModel)]="act_end_date"> -->
      </div>
    </div>


    <!-- 3. 活動時間 -->
    <div class="d-flex align-items-start">
      <div class="">
        <label class="col-form-label p-0">
          <h4 class="pr-2" [ngStyle]="{ 'color': currentAct.is_from_api ? 'rgba(0,0,0,.38)' : 'rgba(0,0,0,.87)'}">3.
            活動時間</h4>
        </label>
      </div>
      <div class="">
        <button class="btn btn-outline-orange" (click)="addActTime()"
          [disabled]="!currentAct.is_repeat || currentAct.is_from_api"
          style="border: 1px solid;border-radius: 6px; padding: 6px 16px;">
          <div class="fs-14">增加時段</div>
        </button>
      </div>
    </div>
    <!-- 時間區段，可重複 , 使用 ngFor .....-->
    <div *ngFor="let actPeriodMeta of currentAct.act_periods_meta; let i = index">
      <div *ngIf="currentAct.is_repeat || (!currentAct.is_repeat && i < 1)">
        <!-- 如果只有一個活動日期，則僅顯示第一個項目 -->
        <!-- 選時間-->
        <div class="d-flex align-items-center">
          <div class="ml-3">
            <input type="time" id="appt" name="appt" min="09:00" max="18:00" required
              class="form-control text-center color-8" style="width:124px; border-color: #828282;"
              [disabled]="currentAct.is_from_api" [(ngModel)]="actPeriodMeta.start_time">
            <!-- <input placeholder="開始時間" aria-label="開始時間" [ngxTimepicker]="fullTime" [format]="24" readonly
              class="form-control text-center color-8" style="width:85px; border-color: #828282;" [(ngModel)]="actPeriodMeta.start_time">
            <ngx-material-timepicker #fullTime></ngx-material-timepicker> -->
          </div>
          <div class="mx-2" [ngStyle]="{ 'color': currentAct.is_from_api ? 'rgba(0,0,0,.38)' : 'rgba(0,0,0,.87)'}">到
          </div>
          <div class="">
            <input type="time" id="appt" name="appt" min="09:00" max="18:00" required
              class="form-control text-center color-8" style="width:124px; border-color: #828282;"
              [disabled]="currentAct.is_from_api" [(ngModel)]="actPeriodMeta.end_time">
            <!-- <input placeholder="結束時間" aria-label="結束時間" [ngxTimepicker]="fullTime2" [format]="24" readonly
              class="form-control text-center color-8" style="width:85px; border-color: #828282;" [(ngModel)]="actPeriodMeta.end_time">
            <ngx-material-timepicker #fullTime2></ngx-material-timepicker> -->
          </div>
        </div>

        <div class="mb-3">
          <div class="d-flex align-items-center ml-3 my-2">
            <input class="form-check-input" type="checkbox" [(ngModel)]="actPeriodMeta.have_issued_hours"
              [disabled]="currentAct.is_from_api" id="hissuedHours{{i}}" (change)="changeIssueHours(actPeriodMeta)">
            <label class="form-check-label" for="hissuedHours{{i}}">核發時數</label>
            <div class="d-flex align-items-center" *ngIf="actPeriodMeta.have_issued_hours">
              <input type="number" class="form-control mx-2" style="width: 50px;" id="" placeholder="" min="0"
                [disabled]="currentAct.is_from_api" [(ngModel)]="actPeriodMeta.issued_hours">
              <span>小時</span>
            </div>
          </div>

          <div class="d-flex align-items-center ml-3">
            <input class="form-check-input" type="checkbox" [(ngModel)]="actPeriodMeta.have_section"
              [disabled]="currentAct.is_from_api" id="hsection{{i}}" (change)="changeMapPeriods(actPeriodMeta)">
            <label class="form-check-label" for="hsection{{i}}">設定對應校務節次</label>
            <div *ngIf="actPeriodMeta.have_section" class="mr-2 d-flex align-items-center">
              <select class="form-select form-control" style="width: 45px;" [(ngModel)]="actPeriodMeta.start_period">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
              </select>
              <div class="mx-2">至</div>
              <select class="form-select form-control" style="width: 45px;" [(ngModel)]="actPeriodMeta.end_period">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
              </select>
            </div>
          </div>
        </div>

        <!-- 選星期 -->
        <div class="d-flex align-items-center ml-3 mb-4 mr-2" *ngIf="currentAct.is_repeat && !currentAct.is_from_api">
          <div class="d-flex align-items-center">
            <div class="" (click)="deleteActivityPeriodMeta(actPeriodMeta)"
              *ngIf="currentAct.act_periods_meta.length > 1">
              <!-- 只剩一個項目時，不能刪除-->
              <img src="/assets/img/mdi-delete.svg" alt="" style="width: 24px;">
            </div>
            <div class="text-nowrap">每週重複</div>
          </div>
          <div class="d-flex flex-wrap align-items-center">
            <div class="form-check mb-0" *ngFor="let wkday of weekdays">
              <input class="form-check-input" type="checkbox" [(ngModel)]="actPeriodMeta.weekdays[wkday.name]"
                id="chk{{wkday.name}}{{i}}">
              <label class="form-check-label" for="chk{{wkday.name}}{{i}}">
                <div>{{ wkday.text }}</div>
              </label>
            </div>
          </div>
        </div>

      </div>
    </div>


    <!-- 4. 活動對象設定 -->
    <label class="col-form-label p-0">
      <h4 class="mb-0">4. 活動對象設定</h4>
    </label>
    <div class="ml-3 mb-2">
      <div class="d-flex flex-row">
        <div class="mt-2 ml-2">
          <input class="form-check-input" type="checkbox" [(ngModel)]="currentAct.role_permitted.teacher"
            id="chkRoleTeacher">
          <label class="form-check-label" for="chkRoleTeacher">
            <div>教師</div>
          </label>
        </div>

        <div class="mt-2 ml-2">
          <input class="form-check-input" type="checkbox" [(ngModel)]="currentAct.role_permitted.parent"
            id="chkRoleParent">
          <label class="form-check-label" for="chkRoleParent">
            <div>家長</div>
          </label>
        </div>

        <div class="mt-2 ml-2">
          <input class="form-check-input" type="checkbox" [(ngModel)]="currentAct.role_permitted.student"
            id="chkRoleStudent">
          <label class="form-check-label" for="chkRoleStudent">
            <div>學生</div>
          </label>
        </div>

      </div>

    </div>


    <!-- 5. 研習證明設定 -->
    <div class="d-flex flex-wrap justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <label class="col-form-label">
          <h4 class="pr-2 mb-0">5. 研習證明設定</h4>
        </label>
        <span class="material-icons-outlined text-warning fs-22" style="cursor: pointer;" mat-raised-button
          matTooltip="1.由其他系統轉入之活動，請先設定各場次研習時數。 &#13; 2.教師研習時數介接在職網，時數依據在職網核定。" matTooltipClass="multiline-tooltip"
          matTooltipPosition="right">help_outline</span>
      </div>
      <button class="btn btn-outline-cyan mr-4 mr-md-5 px-3 px-md-4 pr-4 ml-auto" (click)="editPeriods()"
        *ngIf="this.editorMode === '修改'">
        分場時數設定
      </button>
    </div>
    <div class="ml-3 mb-3">
      <div class="color-8">
        <span>時數計算方式(擇一)</span>
        <span *ngIf="currentAct.is_from_api" class="ml-2 text-warning"
          style="font-size: 0.6rem;">修改設定請務必於報到狀況中點擊「重新計算時數」，確保資料正確。</span>
      </div>
      <div class="mt-2">
        <input class="form-check-input" type="checkbox" [(ngModel)]="currentAct.allow_accumulated_hours" id="totalHours"
          (change)="changeAllowAccumulateHours(currentAct)">
        <label class="form-check-label" for="totalHours">
          <div>依參加場次，累計時數</div>
        </label>
      </div>
      <div class="mt-2">
        <input class="form-check-input" type="checkbox" [(ngModel)]="currentAct.cannot_accumulated_hours" id="onceHours"
          (change)="changeCanNotAccumulateHours(currentAct)">
        <label class="form-check-label" for="onceHours">
          <div>參與多場不累計，僅核發一場時數</div>
        </label>
      </div>
    </div>
    <div class="ml-3 mb-3">
      <div class="color-8">特殊規定(報到以外須達成項目，可複選)</div>
      <div class="mt-2">
        <input class="form-check-input" type="checkbox" [(ngModel)]="currentAct.cert_need_signout" id="haveCheckout">
        <label class="form-check-label" for="haveCheckout">
          <div>完成簽退才核發</div>
        </label>
      </div>
      <div class="mt-2">
        <input class="form-check-input" type="checkbox" [(ngModel)]="currentAct.cert_need_full_attendance"
          id="allCheckin" (change)="changeNeedFullAttendance(currentAct)" />
        <label class="form-check-label" for="allCheckin">
          <div>全數場次均參加才核發</div>
        </label>
      </div>
      <div class="mt-2">
        <input class="form-check-input" type="checkbox" [(ngModel)]="currentAct.cert_allow_absence" id="lessCheckin"
          (change)="changeAllowAbsence(currentAct)" />
        <label class="form-check-label" for="lessCheckin">
          <div class="d-flex align-items-center">
            缺席場次
            <input type="number" class="form-control" style="width: 50px;" min="1"
              [(ngModel)]="currentAct.cert_absence_limit" [disabled]="!currentAct.cert_allow_absence" />
            場(含)以下才核發
          </div>
        </label>
      </div>
    </div>

    <!-- 6. 簽到退設定 -->
    <div class="d-flex flex-wrap align-items-center">
      <div class="">
        <label class="col-form-label p-0">
          <h4 class="mb-0">6. 報到簽退設定</h4>
        </label>
      </div>
    </div>
    <div class="ml-3">

      <div class="d-flex flex-wrap align-items-center">
        <div>報到時間：活動開始前</div>
        <select class="form-select form-control mx-2" aria-label="" style="width: 100px;"
          [(ngModel)]="currentAct.checkin_before_start" (ngModelChange)="selectCheckinBeforeStart()">
          <option value="30">30 分鐘</option>
          <option value="60">60 分鐘</option>
          <option value="90">90 分鐘</option>
          <option value="-1">當日</option>
          <option value="-999">自訂</option>
        </select>
        <span class="d-flex align-items-center"
          *ngIf="currentAct.checkin_before_start.toString() === custom_checkin_before_start_tag.toString()">
          <input type="number" style="width: 60px;" class="form-control mr-2" min="0" max="240"
            [(ngModel)]="custom_checkin_before_start">
          分鐘
        </span>
      </div>

      <div class="">
        <div class="form-check mb-0 mt-2 pl-0">
          <input class="form-check-input" type="checkbox" [(ngModel)]="currentAct.need_sign_out" id="needCheckout">
          <label class="form-check-label" for="needCheckout">
            <div>簽退設定 <span class="text-warning">簽退時間開始即不得補報到</span></div>
          </label>
        </div>
      </div>

      <div class="" *ngIf="currentAct.need_sign_out">
        <div class="d-flex flex-wrap align-items-center">
          <div>簽退時間：活動結束前</div>
          <select class="form-select form-control mx-2" aria-label="" style="width: 100px;"
            [(ngModel)]="currentAct.signout_before_end">
            <option value="10">10 分鐘</option>
            <option value="20">20 分鐘</option>
            <option value="30">30 分鐘</option>
            <option value="-1">當日</option>
            <option value="-999">自訂</option>
          </select>
          <span class="d-flex align-items-center"
            *ngIf="currentAct.signout_before_end.toString() === custom_signout_before_end_tag.toString()">
            <input type="number" style="width: 60px;" class="form-control mr-2" min="0" max="240"
              [(ngModel)]="custom_signout_before_end">
            分鐘
          </span>
        </div>
      </div>
    </div>

    <!-- 7. 其他設定 -->
    <label class="col-form-label p-0 mt-2">
      <h4 class="mb-0">7. 其他設定</h4>
    </label>
    <div class="ml-3">
      <div class="">
        <div class="mt-2">
          <input class="form-check-input" type="checkbox" [(ngModel)]="currentAct.has_meal" id="chkMeal">
          <label class="form-check-label" for="chkMeal">
            <div>提供餐食</div>
          </label>
        </div>

        <div class="mt-2" *ngIf="curRole.schoolCode !== '399999'">
          <input class="form-check-input" type="checkbox" [(ngModel)]="currentAct.limited_my_school"
            id="chkLimitMySchool">
          <label class="form-check-label" for="chkLimitMySchool">
            <div>限校內人員參加</div>
          </label>
        </div>

        <div class="mt-2">
          <input class="form-check-input" type="checkbox" [(ngModel)]="currentAct.scene_registration" id="chkSceneReg">
          <label class="form-check-label" for="chkSceneReg">
            <div>開放現場報名</div>
          </label>
        </div>
      </div>
    </div>

    <!-- 8. 活動驗證碼 -->
    <div class="d-flex align-items-center mt-2" *ngIf="curRole.schoolCode === '399999'">
      <label class="col-form-label">
        <h4 class="pr-2" style="white-space: nowrap;"
          [ngStyle]="{ 'color': currentAct.is_from_api ? 'rgba(0,0,0,.38)' : 'rgba(0,0,0,.87)'}">8. 活動驗證碼</h4>
      </label>
      <input class="form-control w-100" style="max-width: 400px;" id="act_verification_code" placeholder="活動驗證碼"
        [disabled]="currentAct.is_from_api" [(ngModel)]="currentAct.act_verification_code">
    </div>

    <!--
    <div class="example-container">


        <form [formGroup]="fgActEditor">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>活動名稱</mat-label>
                <input matInput placeholder="請輸入活動名稱" autocomplete="off" required formControlName="title">
                <mat-error *ngIf="title.invalid">{{ '*活動名稱為必填欄位'}}</mat-error>
            </mat-form-field>
            <br />
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>活動日期</mat-label>
                <input matInput [min]="minDate" [matDatepicker]="pickerStart" formControlName="act_date">
                <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
            </mat-form-field>
            <br>
            <div class="d-flex mb-3">
                <div appearance="fill" class="ngx-timepicker-sel">
                    <div class="ngx-label">活動開始時間</div>
                    <ngx-timepicker-field formControlName="act_start_time" [format]="24" [defaultTime]="'12:30'"></ngx-timepicker-field>
                </div>
                <div appearance="fill" class="ngx-timepicker-sel" style="margin-left: auto;">
                    <div class="ngx-label">活動結束時間</div>
                    <ngx-timepicker-field formControlName="act_end_time" [format]="24" [defaultTime]="'12:30'"></ngx-timepicker-field>
                </div>
            </div>

            <mat-grid-list cols="2" rowHeight="5:2">
              <mat-grid-tile>
                <mat-form-field appearance="fill" style="width: 100%; margin-right: 5px;">
                  <mat-label>可報到: 活動開始前</mat-label>
                  <mat-select  formControlName="checkin_before_start" >
                    <mat-option [value]="30">30 分鐘</mat-option>
                    <mat-option [value]="60">60 分鐘</mat-option>
                    <mat-option [value]="90">90 分鐘</mat-option>
                    <mat-option [value]="120">120 分鐘</mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field appearance="fill" style="width: 100%; margin-left:5px;">
                  <mat-label>可簽退：活動結束前</mat-label>
                  <mat-select  formControlName="signout_before_end" >
                    <mat-option [value]="-1">不需簽退</mat-option>
                    <mat-option [value]="30">30 分鐘</mat-option>
                    <mat-option [value]="60">60 分鐘</mat-option>
                    <mat-option [value]="90">90 分鐘</mat-option>
                    <mat-option [value]="120">120 分鐘</mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-grid-tile>
            </mat-grid-list>


            <div class="container">
              <div class="row">
                <div class="col-sm-6" style="padding: 0px;">
                  <mat-form-field appearance="fill" style="width: 100%;margin-right: 10px;">
                    <mat-label>對照學校公假節次：</mat-label>
                    <mat-select  formControlName="map_school_periods" multiple (selectionChange)="selectSchoolPeriods($event);"  >
                      <mat-option [value]="1">1</mat-option>
                      <mat-option [value]="2">2</mat-option>
                      <mat-option [value]="3">3</mat-option>
                      <mat-option [value]="4">4</mat-option>
                      <mat-option [value]="5">5</mat-option>
                      <mat-option [value]="6">6</mat-option>
                      <mat-option [value]="7">7</mat-option>
                      <mat-option [value]="8">8</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
                <div class="form-group col-sm-6">
                  <div class="mb-1">
                    <input type="checkbox" id="lms" class="mr-1" formControlName="limited_my_school">
                    <label for="lms" style="color: #000;font-size: inherit;">限校內人員參加</label>
                  </div>
                  <div  class="mb-1">
                    <input type="checkbox" id="sr" class="mr-1" formControlName="scene_registration">
                    <label for="sr" style="color: #000;font-size: inherit;">開放現場報名</label>
                  </div>
                  <div class="mb-1">
                    <input type="checkbox" id="hm" class="mr-1" formControlName="has_meal">
                  <label for="hm" style="color: #000;font-size: inherit;">提供餐點</label>
                  </div>
                </div>
              </div>
            </div>
        </form>

    </div>
       -->
  </div>
</div>
<div class="modal-footer justify-content-center p-3 pb-4">
  <button type="button" class="btn btn-outline-cyan mr-3" style="width: 120px;color: #4BA9AB;"
    (click)="closeModal()">取消</button>
  <button type="submit" class="btn btn-cyan" (click)="saveAct();"
    style="background-color: #4BA9AB;width: 120px">儲存</button>
</div>

<!-- <ng-template #tplDoc>
      <div>
        <h4 class="modal-title pull-left">研習證明設定說明</h4>
        <button type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul>
          <li>
            個人基本資料：
            <ul>
              <li>全名</li>
              <li>系統識別碼</li>
            </ul>
          </li>
          <li>
            可匯出之學習歷程欄位：
            <ul>
              <li>資源數量</li>
              <li>收藏次數</li>
              <li>點讚次數</li>
              <li>查看次數</li>
              <li>下載次數</li>
            </ul>
          </li>
        </ul>
      </div>
    </ng-template> -->
