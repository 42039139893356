<mat-toolbar color="primary">
  <mat-toolbar-row class="d-flex flex-row justify-content-between" style="background-color: #fff; color: #000;">
    <a href="javascript:void(0)" onclick="window.ReactNativeWebView.postMessage('SCAN_QRCODE')">
      <mat-icon aria-hidden="false"
      class="mr-2"
      style="color:#000;"
      >filter_center_focus</mat-icon>
    </a>
    <span style="font-weight: 600;">掃碼登記</span>
    <!-- <span class="example-spacer"></span> -->
    <a href="https://ptsapp.tp.edu.tw/close">
      <mat-icon aria-hidden="false" style="color:#000;">close</mat-icon>
    </a>
  </mat-toolbar-row>
</mat-toolbar>

<app-check-history></app-check-history>

<!-- 報到紀錄清單
<div class="p-4" style="font-size: 1.1rem;" *ngIf="this.currentMode === 'list'">
  <span>您一年內成功報到紀錄如下：</span>
  <table class="table table-bordered table-striped my-3 text-md-center" style="width:100%;font-size:1.5rem;">
    <thead>
      <tr>
        <th style="width: 60%">活動</th>
        <th style="width: 40%">日期</th>
      </tr>
    </thead>
    <tbody>
      <tr data-toggle="modal" data-backdrop="static" *ngFor="let history of histories" (click)="showHistory(history)" style="cursor:pointer">
        <td data-th="活動" class="pl-3 pr-1">
          {{ history.title }}
        </td>

        <td data-th="日期" class="px-1 text-center">
          {{ history.checkin_date }}<i class="mdi mdi-chevron-double-right"></i>
        </td>
      </tr>
    </tbody>
    </table>
</div> -->

<!-- 某一筆報到紀錄明細 Detail -->
<!-- <div class="p-4" style="font-size: 1.1rem;" *ngIf="this.currentMode === 'detail'">
  <div class="d-flex flex-row justify-content-between">
    <div><mat-icon style="padding-top: 4px" (click)="back()" >keyboard_backspace</mat-icon> <span class="ml-4">報到紀錄</span> </div>
    <div></div>
    <div></div>
  </div>
  <table class="table table-bordered table-striped my-3 text-md-center" style="width:100%;">
    <thead>
      <tr>
        <th style="width: 60%" colspan="2">報到資訊</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>活動</td>
        <td>{{ currentHistory.title }}</td>
      </tr>
      <tr>
        <td>主辦學校</td>
        <td>{{ currentHistory.act_school_name }}</td>
      </tr>
      <tr>
        <td>報到身分</td>
        <td>{{ currentHistory.role_type_text }}</td>
      </tr>
      <tr>
        <td>報到日期</td>
        <td>{{ currentHistory.checkin_date }}</td>
      </tr>
      <tr>
        <td>報到時間</td>
        <td>{{ currentHistory.checkin_time }}</td>
      </tr>
      <tr>
        <td>簽退時間</td>
        <td>{{ currentHistory.signout_time }}</td>
      </tr>
    </tbody>
    </table>
</div> -->
