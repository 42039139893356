<!-- <mat-toolbar color="primary">
    <mat-toolbar-row class="d-flex flex-row justify-content-between" style="background-color: #3ea2a2;">
        <span>掃碼登記</span>
    </mat-toolbar-row>
</mat-toolbar> -->
<mat-toolbar color="primary">
  <mat-toolbar-row class="d-flex flex-row justify-content-between" style="background-color: #fff; color: #000;">
    <!-- <a href="javascript:void(0)">
      <mat-icon aria-hidden="false"
      class="mr-2"
      style="color:#000;"
      >filter_center_focus</mat-icon>
    </a> -->
    <div class="app-menu-icon app-icon5" onclick="window.ReactNativeWebView.postMessage('SCAN_QRCODE')">
    </div>
    <span style="font-weight: 600;">掃碼登記</span>
    <!-- <span class="example-spacer"></span> -->
    <a href="https://ptsapp.tp.edu.tw/close">
      <mat-icon aria-hidden="false" style="color:#000;">close</mat-icon>
    </a>
  </mat-toolbar-row>
</mat-toolbar>

<div *ngIf="isLoadingPage">
  載入中 ...
</div>

<div *ngIf="!isLoadingPage">
  <!-- 報到確認 -->
  <div class="d-flex flex-column align-items-center mt-4 px-4" *ngIf=" !hadCheckedIn && !finishCheckedIn && !hadSignout && !finishSignout && !this.errMsg">
    <div style="max-width: 372px;">
      <div style="background: #d9d9d9; height: 24px; border-radius: 24px 24px 0 0;"></div>
      <div class="" style="background: #d9d9d9;">
        <div class="text-center pt-2 fw-600" style="font-size: 30px; letter-spacing: 12px;">{{ this.currentAction }}確認</div>
        <div class="fs-22 p-4 fw-500" style="color: #7f7f7f; line-height: 28px;">{{ this.currentActivity.title }}</div>
        <div class="fs-22 px-4" style="font-weight: 800;">
          <span>{{ serverTime }}</span>
          <span class="fw-600 float-right">登記{{ this.currentAction }}</span>
        </div>
        <div style="margin-top: 46px;"><img src="assets/img/permit2.png" alt="" class="w-100"></div>
        <div class="fs-20 px-3 pt-1 fw-500" style="color: #7f7f7f;">{{ this.currentAction }}身分：
          <span>{{targetRole.roleTypeText}} {{ targetRole.roleType === 'parent' ? '(' + targetRole.childName + ')' : '' }}</span>
          <span class="float-right">
            <div dropdown #dropdown="bs-dropdown" [autoClose]="true" [dropup]="true">

              <img src="/assets/img/pen.png" alt="" dropdownToggle aria-controls="dropdown-role"
                style="width: 24px; vertical-align: baseline;">
              <div id="dropdown-role" *dropdownMenu role="menu" aria-labelledby="button-basic"
                class="dropdown-menu dropdown-menu-right up rounded-xl border-0">

                <button class="dropdown-item px-3 py-0" *ngFor="let role of userRoles; let i = index">
                  <input type="radio" class="form-check-input" name="sizeSel2" id="sizel{{i}}" [checked]="(targetRole.schoolCode === role.schoolCode && targetRole.schoolType === role.schoolType && targetRole.roleType === role.roleType && targetRole.childIdentity === role.childIdentity)">
                  <label for="sizel{{i}}" class="dropdown-item d-flex align-items-center cursor-point" (click)="changeRole($event, role)">
                    <span class="pr-3"></span>
                    <div>
                      <div class="fs-18" style="width: 160px; white-space: pre-line; word-break: keep-all;">
                        {{role.roleType === 'parent' ? role.childName : '' }}{{role.roleTypeText}}
                      </div>
                      <div class="fs-14 color-8 text-wrap">{{role.schoolName}}</div>
                    </div>
                  </label>
                </button>

              </div>
            </div>

          </span>
        </div>
        <div class="fs-16 fw-300 px-3" style="color: #7f7f7f; margin-top: -4px;">{{ targetRole.schoolName }}</div>
      </div>

      <div style="background: #d9d9d9; height: 24px; border-radius: 0 0 24px 24px;"></div>
    </div>

    <div class="w-100 pl-3 mt-3" style="max-width: 372px;" *ngIf="currentActivity.has_meal && (currentAction !== '簽退')">
      <div class="fs-20 fw-500">本活動提供餐食</div>
      <div class="d-flex align-items-center text-nowrap mt-2">
        <label for="item-type fw-300" class="fs-20 col-form-label mr-2">選擇餐食：</label>
        <div class="p-1" style="word-break: keep-all; border: 1px solid #b2b2b2!important; border-radius: 8px">
          <div class="btn-group" role="group" style="color: #7f7f7f; box-shadow: none;" id="item-type">
            <!-- <button type="button" class="fs-18 fw-300 btn rounded btn-custom" style="padding: 8px 36px;" (click)="setMeal(true)">葷</button>
            <button type="button" class="fs-18 fw-300 btn rounded" style="padding: 8px 36px;" (click)="setMeal(true)">素</button> -->
            <button type="button" [ngClass]="{'fs-18 fw-300 btn rounded': true,  'btn-custom': (mealType === '葷')}" style="padding: 8px 36px;" (click)="setMeal(true)">葷</button>
            <button type="button" [ngClass]="{'fs-18 fw-300 btn rounded': true,  'btn-custom': (mealType === '素')}" style="padding: 8px 36px;" (click)="setMeal(false)">素</button>
          </div>
        </div>

      </div>
    </div>
    <button mat-raised-button class="fw-600 text-white mb-5"
      style="background-color: #1DB7BD; font-size: 1.25rem; border-radius: 12px; margin-top: 42px; padding: 4px 60px; box-shadow: none;"
      (click)="confirmCheckIn()">確定</button>
  </div>


  <!-- 報到完成 -->
  <div class="d-flex flex-column align-items-center mt-4 px-4" *ngIf=" (hadCheckedIn || hadSignout || finishSignout) && !this.errMsg">
    <div style="max-width: 372px;">
      <div style="background: #d9d9d9; height: 24px; border-radius: 24px 24px 0 0;"></div>
      <div class="" style="background: #d9d9d9;">
        <div class="text-center pt-2 fw-600" style="font-size: 30px; letter-spacing: 12px;">{{ this.currentAction }}完成</div>
        <div class="fs-22 p-4 fw-500" style="color: #7f7f7f; line-height: 28px;">{{ this.currentActivity.title }}</div>
        <div class="fs-22 px-4" style="font-weight: 800;">
          <span>{{ getCheckInSignoutTime() }}</span>
          <span class="fw-600 float-right">已{{ this.currentAction }}</span>
        </div>
        <div style="margin-top: 46px;"><img src="assets/img/permit2.png" alt="" class="w-100"></div>
        <div class="fs-20 px-3 pt-1 fw-500" style="color: #7f7f7f;">{{ this.currentAction }}身分：
          <span>{{targetRole.roleTypeText}} {{ targetRole.roleType === 'parent' ? '(' + targetRole.childName + ')' : '' }}</span>
        </div>
        <div class="fs-16 fw-300 px-3" style="color: #7f7f7f; margin-top: -4px;">{{ targetRole.schoolName }}</div>
      </div>
      <div style="background: #d9d9d9; height: 24px; border-radius: 0 0 24px 24px;"></div>
    </div>

    <div class="w-100 pl-3 mt-3" style="max-width: 372px;" *ngIf="currentActivity.has_meal && (currentAction !== '簽退')">
      <div class="fs-20 fw-500">本活動提供餐食</div>
      <div class="d-flex align-items-center text-nowrap mt-2">
        <label for="item-type fw-300" class="fs-20 col-form-label mr-2">選擇餐食：</label>
        <div class="p-1" style="word-break: keep-all; border: 1px solid #b2b2b2!important; border-radius: 8px">
            <button type="button" [ngClass]="{'fs-18 fw-300 btn rounded': true,  'btn-custom': (checkInRec.meal_type === '葷')}" style="padding: 8px 36px;" disabled>葷</button>
            <button type="button" [ngClass]="{'fs-18 fw-300 btn rounded': true,  'btn-custom': (checkInRec.meal_type === '素')}" style="padding: 8px 36px;" disabled>素</button>
        </div>
      </div>
    </div>
    <button mat-raised-button class="fw-600 text-white mb-5"
      style="background-color: #1DB7BD; font-size: 1.25rem; border-radius: 12px; margin-top: 42px; padding: 4px 60px; box-shadow: none;"
      (click)="backToMain()">檢視紀錄</button>
  </div>

  <!-- 報到不成功 -->
  <div class="d-flex flex-column align-items-center justify-content-center p-4 mt-4" *ngIf="this.errMsg">
    <div class="mb-4 position-relative" style="max-width: 372px;">
      <div><img src="/assets/img/noentry1.png" alt="" class="w-100"></div>
      <div class="fw-600 text-white text-center pt-3" style="font-size: 30px; background-color:#f26a6d;">{{ this.currentAction }}不成功</div>
      <img src="/assets/img/noentry2.png" alt="" class="w-100" style="z-index:0;">
      <div class="fs-22 position-absolute text-justify fw-500 text-white px-3" style="z-index: 1; bottom: 12px;">
        {{ errMsgTitle }} <br/>
        {{ errMsg }}
      </div>
    </div>
    <div style="height:60px;" *ngIf="showRoleChooser && (this.userRoles.length > 0)">
      <div class="d-flex align-items-center">
        <div class="fs-20 mr-3" style="color: #f26a6d;">重選身分</div>
        <div dropdown #dropdown="bs-dropdown" [autoClose]="true" [dropup]="true"
          class="dropdown role rounded-xl py-1 px-3" style="border: 1px solid #bcbcbc; width: 200px;">
          <a dropdownToggle aria-controls="dropdown-basic"
            class="fs-18 btn-out-link dropdown-toggle d-flex justify-content-between" href="javascript:;">
            {{ targetRole.roleType === 'parent' ? targetRole.childName : this.currentUserInfo.first_name }} {{targetRole.roleTypeText}}
          </a>
          <div id="dropdown-basic" *dropdownMenu role="menu" aria-labelledby="button-basic"
            class="dropdown-menu dropdown-menu-right up rounded-xl border-0" >

            <button class="dropdown-item px-3 py-0" *ngFor="let role of userRoles; let i = index" >
              <input type="radio" class="form-check-input" name="sizeSel" id="sel{{i}}" [checked]="(targetRole.schoolCode === role.schoolCode && targetRole.schoolType === role.schoolType && targetRole.roleType === role.roleType && targetRole.childIdentity === role.childIdentity)" >
              <label for="sel{{i}}" class="dropdown-item d-flex align-items-center cursor-point"  (click)="changeRole($event, role)">
                <span class="pr-3"></span>
                <div>
                  <div class="fs-18" style="width: 160px; white-space: pre-line; word-break: keep-all;">
                    {{role.childName}}{{role.roleTypeText}}
                  </div>
                  <div class="fs-14 color-8 text-wrap">{{role.schoolName}}</div>
                </div>
              </label>
            </button>

          </div>
        </div>
      </div>

    </div>
    <button mat-raised-button class="fw-600 text-white"
      style="background-color: #1DB7BD; font-size: 1.25rem; border-radius: 12px; padding: 4px 60px; box-shadow: none;" (click)="backToMain()">檢視紀錄</button>
  </div>
</div>
