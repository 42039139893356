import { ActivityNotificationServiceService } from './../activity/activity-notification-service.service';
import { ShowQrCodeComponent } from './../activity/qrcode/show-qr-code/show-qr-code.component';
import { DeptInfo, PermissionService } from './../core/permission.service';
import { AddActivityComponent } from './../activity/add-activity/add-activity.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivityRecord, ActivityService } from '../core/activity.service';
import { filter, take } from 'rxjs/operators';
import { ActStateService } from '../activity/act-state.service';
import { Store } from '@ngxs/store';
import { UserRoleRec } from '../data';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent implements OnInit {

  allActivity: ActivityRecord[] = [];
  filteredActivities: ActivityRecord[] = [];
  currentActivity: ActivityRecord;
  years = [];
  curYear = 0;

  curRole: UserRoleRec = {} as UserRoleRec;

  isFromAPI = '0';    // 目前選擇的子服務
  // apiClients = [];

  isLoading = false;

  pageLength = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 30];

  dicDepts: { [code: string]: DeptInfo } = {};

  subscription: Subscription;

  searchString = '';


  // isAdmin = false ;


  constructor(
    private activityService: ActivityService,
    private router: Router,
    private modal: BsModalService,
    private actStateSrv: ActStateService,
    private route: ActivatedRoute,
    private store: Store,
    private snackBar: MatSnackBar,
    private permissionService: PermissionService,
    private activityNotificationService: ActivityNotificationServiceService
  ) {
    activityNotificationService.emitCheckInTabStatus(false);
    activityNotificationService.emitImportListTabStatus(false);
  }

  async ngOnInit() {
    // console.log(' activity-list.ngOnInit() ....');
    const y = this.store.snapshot();

    // 當使用者切換角色時
    this.subscription = this.store.subscribe(x => {
      // console.log('activity list -- currentRole', x);
      // 找出此角色對應的處室清單
      const depts = this.permissionService.getMyDepts();
      // console.log(depts);
      depts.forEach(dept => {
        this.dicDepts[dept.deptCode] = dept;
      });
      // 根據角色取得活動清單
      this.curRole = x.role;
      if (this.curRole && this.curRole.schoolCode) {
        // console.log('change role and refresh activity ....');
        this.refreshActivity();
      }
    });


  }


  async refreshActivity() {
    this.isLoading = true;

    try {
      const rsp = await this.activityService.getMyActivities(this.curRole.schoolCode,
        this.curRole.schoolType,
        this.curRole.deptCode).toPromise();
      // this.apiClients = [];
      // const dicApiClients = {};
      rsp.list.forEach(act => {
        if (act.dept_code) {
          act.dept_name = this.dicDepts[act.dept_code] ? this.dicDepts[act.dept_code].deptName : '';
        }
        // if (act.api_client_name) {
        //   if (!dicApiClients[act.api_client_name]) {
        //     dicApiClients[act.api_client_name] = true ;
        //     this.apiClients.push(act.api_client_name);
        //   }
        // }
      });
      // this.pageLength = rsp.length;
      this.allActivity = rsp.list;
      this.years = rsp.years.filter(yr => !!yr);

      // console.log({years: this.years});

      this.curYear = (this.years.length > 0) ? this.years[0] : 0;
      // console.log( { curYear: this.curYear });
      this.changeYear(this.curYear);

      // console.log(this.pageLength);
    } catch (error) {
      let msg = '';
      msg = error;
      this.snackBar.open('讀取資料發生錯誤', msg, { duration: 2000 });
    }

    this.isLoading = false;

  }

  changeYear(evt) {
    this.findActivities();
  }

  changeAPIClient(evt) {
    // console.log(evt);
    this.findActivities();
    return;
  }

  findActivities() {
    // console.log( { curYear: this.curYear, curAPIClient: this.curAPIClient});
    const resultActs = this.allActivity.filter(act => {
      // console.log( { act_date : act.act_date });
      let result = true;
      if (this.curYear !== 0) { // 如果不是全部年份
        if (act.act_date) {  // 如果 act.act_date is not null
          result = (new Date(act.act_date.toString()).getFullYear().toString() === this.curYear.toString());
        } else { // 如果 act.act_date is null
          result = false;
        }
      }
      if (result) {
        if (this.isFromAPI === '1') {  // 如果要篩選第三方建立的
          result = act.is_from_api;
          // if (act.api_client_name) {
          //   result = (act.api_client_name === this.curAPIClient.toString() );
          // } else {
          //   result = false ;
          // }
        }
      }
      if (result) {
        // 如果有關鍵字搜尋
        if (this.searchString) {
          result = (act.title.indexOf(this.searchString) > -1);
        }
      }

      return result;
    });

    this.filteredActivities = resultActs;
  }

  CurrentAct(act: ActivityRecord) {
    this.actStateSrv.setCurrentAct(act);
    this.router.navigate(['activity', act.id], { relativeTo: this.route });
  }

  addActivity(editorMode: string) {
    const dialog = this.modal.show(AddActivityComponent, {
      initialState: { editorMode, curRole: this.curRole },
    });

    this.modal.onHide.pipe(take(1), filter(reason => reason === 'data saved')).subscribe(() => {
      // Do anything here, this will get called only once
      console.log('data saved and refresh actviity .');
      this.refreshActivity().then();
    });
  }

  // changePage(page: PageEvent) {

  //   // this.isLoading = true;
  //   // {
  //     this.pageIndex = page.pageIndex;
  //     this.pageSize = page.pageSize;
  //     // this.getActList(this.pageIndex, this.pageSize);
  //     // console.log('change page and refresh activity ....');
  //     this.refreshActivity();
  //   // }
  //   // this.isLoading = false;
  // }

  ShowCheckInQR(act: ActivityRecord) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const dialog = this.modal.show(ShowQrCodeComponent, {
      initialState: { currentActivity: act, qrType: 'checkin' },
      class: 'qrcode-dl'
    });
  }

  ShowSignoutQR(act: ActivityRecord) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const dialog = this.modal.show(ShowQrCodeComponent, {
      initialState: { currentActivity: act, qrType: 'signout' },
      class: 'qrcode-dl'
    });
  }

  ShowStatisticsCount(act: ActivityRecord) {
    this.actStateSrv.setCurrentAct(act);
    this.router.navigate(['activity', act.id, 'info'], { relativeTo: this.route });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.subscription.unsubscribe();  // 需要 unsubscribe，否則某次開啟此 compoent，就會多 subscribe 一次。
  }


  // 篩選出畫面所需要的學生
  searchActivities(evt) {
    // console.log( this.searchString );
    this.findActivities();
  }

}
