  <div class="modal-content">
    <div class="modal-header pb-2">
      <div class="d-flex justify-content-between w-100">
        <div></div>
        <h4 class="modal-title">人工報到</h4>
        <button type="button" class="close" (click)="closeDialog();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div class="modal-body px-3 px-sm-4">
      <ul class="nav nav-pills justify-content-start mb-4 mr-auto" role="tablist">
        <li class="nav-item mr-3">
          <a class="nav-link active" id="nocheck-tab" data-toggle="pill" href="#nocheck" role="tab"
            aria-controls="nocheck" aria-selected="true">未自行報到</a>
          <span class="backg5"></span>
        </li>
        <li class="nav-item" (click)="getStuds();">
          <a class="nav-link" id="manual-tab" data-toggle="pill" href="#manual" role="tab" aria-controls="manual"
            aria-selected="false">人工新增</a>
          <span class="backg4"></span>
        </li>
      </ul>

      <div class="tab-content">
        <!-- 第一個頁籤誒, 未自行報到 -->
        <div class="tab-pane fade show active" id="nocheck" role="tabpanel" aria-labelledby="nocheck-tab">
          <div class="d-flex justify-content-between flex-wrap align-items-center w-100">
            <div class="flex-grow-1 mt-3 mr-0 mr-sm-3" style="max-width: 320px;">
              <select class="form-select form-control day-of-week w-100" style="height: 40px;"
                [(ngModel)]="currentSchoolCode"
                (ngModelChange)="changeSchool()">
                <option [ngValue]="'0'">全部學校</option>
                <option *ngFor="let sch of arySchools" [ngValue]="sch.school_code">{{ sch.school_name }}</option>
              </select>
            </div>
            <div class="btn btn-outline-orange mt-3 ml-auto"
                  *ngIf="!isBatchCheckIn && (uiStudCount + uiTeacherCount > 0)"
                  style="border: 1px solid;"
                  (click)="onBatchCheckIn(); ">批次報到</div>
            <div class="ml-auto mt-3" *ngIf="isBatchCheckIn">
              <div class="btn btn-outline-orange mr-3" style="border: 1px solid;" (click)="onSaveBatchCheckIn();">儲存</div>
              <div class="btn btn-outline-orange" style="border: 1px solid;" (click)=" onCancelBatchCheckIn();">取消</div>
            </div>
          </div>
            <div class="font-weight-bold pt-4">教師：</div>
            <table class="dtable w-100 text-nowrap">
              <tr>
                <td style="width: 24px;">
                  <input type="checkbox" *ngIf="isBatchCheckIn" id="selallt" class="" [(ngModel)]="isCheckAllTeachers" (ngModelChange)="SelectAllTeachers()"><label for="selallt"></label>
                </td>
                <td style="width: 240px;">學校</td>
                <td>姓名</td>
                <td style="width: 48px;">狀況</td>
                <!-- <td style="width: 38px;">備註</td> -->
              </tr>
              <ng-container *ngFor="let rec of aryTeacherSignup">
                <tr *ngIf="this.currentSchoolCode ==='0' || rec.signup.school_code === this.currentSchoolCode">
                  <td class="text-right">
                    <!-- 只有在批次更新模式，且尚未報到的資料，才會出現 checkbox -->
                    <span *ngIf="isBatchCheckIn && !rec.isCheckIn">
                      <input type="checkbox" id="{{rec.signup.id}}" class="" [(ngModel)]="rec.isSelected"><label for="{{rec.signup.id}}"></label>
                    </span>
                  </td>
                  <td>{{ rec.signup.school_name }}</td>
                  <td>{{ rec.signup.name }}</td>
                  <td>
                    <div *ngIf="!rec.isCheckIn" class="btn-outline-custom" (click)="CheckInSingleRec(rec)">報到</div>
                    <div *ngIf=" rec.isCheckIn && rec.checkin.checkin_type === 'manual' "  class="btn-outline-orange" (click)="CancelCheckInSingleRec(rec)">取消</div>
                    <div *ngIf=" rec.isCheckIn && rec.checkin.checkin_type !== 'manual' "  >已報到</div>
                  </td>
                </tr>
              </ng-container>
            </table>


            <ng-container *ngFor="let sch of arySchools"  >
              <div *ngIf="showSchool(sch)">
                <div class="p-2"></div>
                <div class="font-weight-bold pt-4">學生：{{ sch.school_name }}</div>
                <table class="dtable w-100 text-nowrap">
                  <tr>
                    <td style="width: 24px;">
                      <!-- 只有在批次更新模式，且尚未報到的資料，才會出現 checkbox -->
                      <input *ngIf="isBatchCheckIn" type="checkbox" id="sel_{{sch.school_code}}_all" class=""
                        [(ngModel)]="isSchoolAllStudChecked[sch.school_code]"
                        (ngModelChange)="SelectSchoolStudents(sch.school_code)"
                      ><label for="sel_{{sch.school_code}}_all"></label>
                    </td>
                    <td class="pl-0">班級</td>
                    <td>座號</td>
                    <td>學號</td>
                    <td>姓名</td>
                    <td style="width: 48px;">狀況</td>
                  </tr>
                  <tr *ngFor="let rec of dicStudSignupBySchool[sch.school_code]">
                    <td class="text-right">
                      <span *ngIf="isBatchCheckIn && !rec.isCheckIn">
                        <input type="checkbox" id="sel{{rec.signup.id}}" class="" [(ngModel)]="rec.isSelected"><label for="sel{{rec.signup.id}}"></label>
                      </span>
                    </td>
                    <td class="pl-0">{{ rec.signup.class_name}}</td>
                    <td>{{ rec.signup.seat_no }}</td>
                    <td>{{ rec.signup.stud_no }}</td>
                    <td>{{ rec.signup.name }}</td>
                    <td>
                      <div *ngIf="!rec.isCheckIn" class="btn-outline-custom" (click)="CheckInSingleRec(rec)" >報到</div>
                      <div *ngIf=" rec.isCheckIn && rec.checkin.checkin_type === 'manual' " class="btn-outline-orange" (click)="CancelCheckInSingleRec(rec)">取消</div>
                      <div *ngIf=" rec.isCheckIn && rec.checkin.checkin_type !== 'manual' "  >已報到</div>
                    </td>
                  </tr>

                </table>
              </div>
            </ng-container>

        </div>
      <!-- 第二個頁籤 -->
        <div class="tab-pane fade" id="manual" role="tabpanel" aria-labelledby="manual-tab">
          <!-- 2.1 校端專用，人工報到 -->
          <div *ngIf="curRole.schoolCode !== '399999'">
            <div class="d-flex flex-wrap justify-content-between w-100">
              <div class="mr-3 mb-2 flex-grow-1">
                <select class="form-select form-control day-of-week"
                  [(ngModel)]="selectedGradeYear"
                  (ngModelChange)="onSelectGradeYear(true);"
                >
                  <!-- <option [ngValue]="'0'">全部年級</option> -->
                  <option *ngFor="let grd of grades" [ngValue]="grd">{{ toChiGrade(grd) }}年級</option>
                  <!-- <option value="">全部年級</option>
                  <option value="">一年級</option>
                  <option value="">二年級</option>
                  <option value="">三年級</option> -->
                </select>
              </div>
              <div class="mr-0 mr-sm-3 mb-2 flex-grow-1">
                <select class="form-select form-control day-of-week"
                  [(ngModel)]="selectedClassName"
                  (ngModelChange)="onSelectClass(true);"
                >
                  <option *ngFor="let cls of filterClassNames" [ngValue]="cls">{{ cls }}</option>
                  <!-- <option value="">全部班級</option>
                  <option value="">101</option>
                  <option value="">102</option>
                  <option value="">201</option>
                  <option value="">202</option> -->
                </select>
              </div>
              <div class="mb-2 nnsearch">
                <input class="form-control day-of-week" id="nnsearch" placeholder="搜尋姓名或完整學號"  (keyup.enter)='searchStuds($event)' [(ngModel)]="searchString" >
              </div>
            </div>

            <div class="p-2"></div>

            <div class="d-flex justify-content-end">
              <div class="btn btn-outline-orange" *ngIf="!isBatchCheckIn2" style="border: 1px solid;" (click)="this.isBatchCheckIn2 = true;">批次報到</div>
              <div class="btn btn-outline-orange mr-3" *ngIf="isBatchCheckIn2" style="border: 1px solid;" (click)="onSaveBatchCheckIn2();">儲存</div>
              <div class="btn btn-outline-orange" *ngIf="isBatchCheckIn2" style="border: 1px solid;" (click)=" onCancelBatchCheckIn2();">取消</div>
            </div>

            <div class="p-2"></div>
            <table class="dtable w-100 text-nowrap">
              <tr>
                <td style="width: 24px;">
                  <input type="checkbox" *ngIf="isBatchCheckIn2" id="selallm" class=""
                    [(ngModel)]="isAllStudentsSelected"
                    (ngModelChange)="onSelectAllStudents()"
                  ><label for="selallm"></label>
                </td>
                <td class="pl-0">班級</td>
                <td>座號</td>
                <td>學號</td>
                <td>姓名</td>
                <td style="width: 38px;">狀況</td>
                <!-- <td >備註</td> -->
              </tr>
              <tr *ngFor="let rec of targetStuds">
                <td class="text-right">
                  <span *ngIf="isBatchCheckIn2 && !rec.isCheckIn">
                    <input type="checkbox" id="sel{{ rec.stud.StudentID}}" class=""
                    [(ngModel)]="rec.isSelected"
                    ><label for="sel{{rec.stud.StudentID}}"></label>
                  </span>
                </td>
                <td class="pl-0">{{ rec.stud.ClassName }}</td>
                <td>{{ rec.stud.SeatNo }}</td>
                <td>{{ rec.stud.StudentNumber }}</td>
                <td>{{ rec.stud.StudentName }}</td>
                <td>
                  <div *ngIf="!rec.isCheckIn" class="btn-outline-custom" (click)="CheckInSingleRec2(rec)" >報到</div>
                  <div *ngIf=" rec.isCheckIn && rec.checkin.checkin_type === 'manual' " class="btn-outline-orange" (click)="CancelCheckInSingleRec(rec)">取消</div>
                  <div *ngIf=" rec.isCheckIn && rec.checkin.checkin_type !== 'manual' "  >已報到</div>
                </td>
              </tr>
            </table>
          </div>

          <!-- 2.2 局端專用，人工報到 -->
          <div *ngIf="curRole.schoolCode === '399999'">
            <div class="d-flex justify-content-between flex-wrap align-items-center w-100">
              <div class="flex-grow-1 mt-3 mr-0 mr-sm-3" style="max-width: 320px;">
                <div class="d-flex align-items-center">
                  <input class="form-control day-of-week" (keyup.enter)="searchByIdNumber()" id="nnsearch" placeholder="搜尋完整身分證號"  [(ngModel)]="searchIDNumber" >
                  <img src="/assets/img/search.png" alt="" class="p-2" style="margin-left: -40px; cursor: pointer;" (click)="searchByIdNumber()">
                </div>
              </div>
              <div class="btn btn-outline-orange mt-3 ml-auto"  style="border: 1px solid;" (click)="showEduManualCheckList()">顯示人工報到名單</div>
            </div>

            <div class="table mt-3 mb-0">
              <table class="table-rwd dtable w-100 text-nowrap">
                <thead>
                  <tr>
                    <th class="pl-0">身分</th>
                    <th>姓名</th>
                    <th>學校</th>
                    <th>學層</th>
                    <th>班級</th>
                    <th>學號</th>
                    <th class="pr-0">狀況</th>
                    <!-- <td >備註</td> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let rec of targetUsers">
                    <td data-th="身分" class="pl-0">{{ rec.roleText }}</td>
                    <td data-th="姓名">{{ rec.Name }}</td>
                    <td data-th="學校" class="text-wrap">{{ rec.SchoolName }}</td>
                    <td data-th="學層">{{ rec.SchoolType }}</td>
                    <td data-th="班級">{{ rec.ClassName }}</td>
                    <td data-th="學號">{{ rec.StudentNumber }}</td>
                    <td data-th="狀況">
                      <div *ngIf="!rec.isCheckIn" class="btn-outline-custom" (click)="CheckInEduSingleRec(rec)" >報到</div>
                      <div *ngIf=" rec.isCheckIn && rec.checkin_type === 'manual' " class="btn-outline-orange" (click)="CancelCheckInEduSingleRec(rec)">取消</div>
                      <div *ngIf=" rec.isCheckIn && rec.checkin_type !== 'manual' "  >已報到</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
    <div class="modal-footer justify-content-center py-3 border-0">
      <!-- <button type="button" class="btn btn-outline-cyan mr-3" style="width: 120px;"
          (click)="modalRef.hide()">關閉</button>
        <button type="button" class="btn btn-cyan" style="width: 120px;">儲存</button> -->
    </div>
  </div>

  <!-- <ng-template #template>
    <div class="modal-body text-center">
      <p>您確定要批次儲存  <%=  %> 筆紀錄嗎？ </p>
      <button type="button" class="btn btn-default" (click)="confirm()" >Yes</button>
      <button type="button" class="btn btn-primary" (click)="decline()" >No</button>
    </div>
  </ng-template> -->
