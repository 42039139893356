import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { ActivityRecord } from 'src/app/core/activity.service';
import { Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import { QRImageService } from 'src/app/core/qrimage.service';

@Component({
  selector: 'app-show-qr-code',
  templateUrl: './show-qr-code.component.html',
  styleUrls: ['./show-qr-code.component.scss']
})
export class ShowQrCodeComponent implements OnInit {

  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  currentActivity: ActivityRecord  ;
  qrType: 'checkin' | 'signout' | undefined ;
  qrTypeText = '';
  elementType = NgxQrcodeElementTypes.URL ;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH ;
  targetValue = '';
  // host = 'https://cooc.tp.edu.tw/app_links/conference';
  appLinksPath = 'https://cooc.tp.edu.tw/app_links/conference.html';
  // appLinksPath = 'https://cooc.tp.edu.tw/app_links/conference2.html';

  constructor(
    private qrImageService: QRImageService
  ) { }

  ngOnInit(): void {
    this.checkQRType();
  }

  checkQRType() {
    if (this.qrType === 'checkin') {
      this.qrTypeText = '報到';
      this.targetValue = `${this.appLinksPath}?th=a&act_uuid=${this.currentActivity ? this.currentActivity.act_uuid : ''}`;
    } else if (this.qrType === 'signout') {
      this.qrTypeText = '簽退';
      this.targetValue = `${this.appLinksPath}?th=b&act_uuid=${this.currentActivity ? this.currentActivity.act_uuid : ''}`;
    } else {
      this.qrTypeText = '';
      this.targetValue = '';
    }
    console.log(this.targetValue);

    // this.targetValue = 'https://cooc.tp.edu.tw/app_links/act_checkin.html?act_uuid=d5be4223-e65e-4bb7-9dc4-3579586c6b19';
  }

  async downloadFile() {

    const elm = document.getElementById('downloadQrcodeSIN');
    const btn = document.getElementById('btn');
    btn.style.display='none';
    // elm.style.width = '500px';
    // elm.style.paddingTop = '20px';
    // elm.style.paddingBottom = '50px';

    html2canvas(elm).then(canvas => {
      // this.canvas.nativeElement.src = canvas.toDataURL();
      // console.log(this.currentActivity.title);
      const dataUrl = canvas.toDataURL();
      // const img = document.getElementById('imgResult');
      // img.src = dataUrl ;
      // console.log(dataUrl);
      // document.body.appendChild(canvas);

      // 1. 把圖片的 dataUrl post 到 server，建立 image file，並回傳 image key
      this.qrImageService.createImage(dataUrl).toPromise().then((v) => {

        // 2. 把 imageKey 組成一個下載 image 的 url
        window.location.href = `/service/qrimage/get?key=${v.image_key}&file_name=${encodeURIComponent(this.currentActivity.title)}_${encodeURIComponent(this.qrTypeText)}QRCode.png`;
      });
      btn.style.display='block';
    });

    // elm.style.width = 'auto';
    // elm.style.paddingTop = '0';
  }

}

/**  QRCode : https://vaiz10.medium.com/a-simple-way-to-generate-and-download-a-qrcode-with-angular-dc64969b476a */
