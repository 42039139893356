  <div class="modal-content">
    <div class="modal-header pb-2">
      <div class="d-flex justify-content-between w-100">
        <div></div>
        <h4 class="modal-title">人工簽退</h4>
        <button type="button" class="close" (click)="closeDialog();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div class="modal-body px-4">
      <!-- <ul class="nav nav-pills justify-content-start mb-4 mr-auto" role="tablist">
        <li class="nav-item mr-3">
          <a class="nav-link active" id="nocheckOut-tab" data-toggle="pill" href="#nocheckOut" role="tab"
            aria-controls="nocheckOut" aria-selected="true">未簽退</a>
          <span class="backg3"></span>
        </li>
      </ul> -->

      <div class="tab-content">
        <div class="tab-pane fade show active" id="nocheckOut" role="tabpanel" aria-labelledby="nocheckOut-tab">

          <div class="d-flex justify-content-between align-items-center w-100 mb-2">
            <mat-radio-group aria-label="Select an option" (change)="changeRole($event)">
              <mat-radio-button value="teacher" [checked]="true" >教師</mat-radio-button>
              <mat-radio-button value="student" class="ml-4">學生</mat-radio-button>
              <mat-radio-button value="parent" class="ml-4">家長</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="flex-grow-1 mr-3" style="max-width: 320px;">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>學校：</mat-label>
                <input type="text"
                       placeholder="選擇學校"
                       aria-label="學校"
                       matInput
                       [formControl]="myControl"
                       [matAutocomplete]="auto">
                <mat-autocomplete
                    autoActiveFirstOption
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (optionSelected)="selectSchool()"
                    >
                  <mat-option *ngFor="let sch of filteredOptions | async" [value]="sch"

                  >
                    {{sch.school_name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <!-- <select class="form-select form-control day-of-week w-100" style="height: 40px;"
                [(ngModel)]="currentSchoolCode"
                (ngModelChange)="changeSchool()"
              >
                <option [ngValue]="'0'">全部學校</option>
                <option *ngFor="let sch of arySchools" [ngValue]="sch.school_code">{{ sch.school_name }}</option>
              </select> -->
            </div>
            <div class="ml-2  nnsearch" style="display: inline-block;">
              <input class="form-control day-of-week" id="nnsearch" placeholder="搜尋姓名關鍵字"  (keyup.enter)='searchStuds($event)' [(ngModel)]="searchString" >
            </div>
            <!-- <div class="btn btn-outline-orange" style="border: 1px solid;">批次簽退</div> -->
            <!-- <div class="btn btn-outline-orange" *ngIf="!isBatchCheckIn"  style="border: 1px solid;" (click)=" this.isBatchCheckIn = true;">批次簽退</div> -->
            <div class="btn btn-outline-orange mr-3" *ngIf="isBatchCheckIn" style="border: 1px solid;" (click)="onSaveBatchCheckIn();">儲存</div>
            <div class="btn btn-outline-orange" *ngIf="isBatchCheckIn" style="border: 1px solid;" (click)=" onCancelBatchCheckIn();">取消</div>

          </div>


          <!-- <div class="p-2"></div> -->
          <div class="font-weight-bold pt-2">{{ currentRoleText }}：</div>
          <table class="dtable w-100 ">
            <tr>
              <td style="width: 24px;">
                <input type="checkbox" *ngIf="isBatchCheckIn" id="selallt" class="" [(ngModel)]="isCheckAllTeachers" (ngModelChange)="SelectAllTeachers()"><label for="selallt"></label>
              </td>
              <td >學校</td>
              <td *ngIf="currentRole !== 'teacher'">班級</td>
              <td *ngIf="currentRole !== 'teacher'">座號</td>
              <td>姓名</td>
              <td style="width: 48px;">狀況</td>
              <!-- <td style="width: 38px;">備註</td> -->
            </tr>
            <tr *ngFor="let rec of pagedUiRecords">
              <td class="text-right">
                <!-- 只有在批次更新模式，且尚未簽退的資料，才會出現 checkbox -->
                <span *ngIf="isBatchCheckIn && !rec.isSignout">
                  <input type="checkbox" id="{{rec.checkin.id}}" class="" [(ngModel)]="rec.isSelected"><label for="{{rec.checkin.id}}"></label>
                </span>
              </td>
              <td>{{ rec.checkin.school_name }}</td>
              <td  *ngIf="currentRole !== 'teacher'">{{ rec.checkin.class_name }}</td>
              <td  *ngIf="currentRole !== 'teacher'">{{ rec.checkin.seat_no }}</td>
              <td>{{ rec.checkin.name }}</td>
              <td>
                <div *ngIf="!rec.isSignout" class="btn-outline-custom" (click)="SignoutSingleRec(rec)">簽退</div>
                <div *ngIf=" rec.isSignout && rec.checkin.signout_type === 'manual' "  class="btn-outline-orange" (click)="CancelSignoutSingleRec(rec)">取消</div>
                <div *ngIf=" rec.isSignout && rec.checkin.signout_type !== 'manual' "  >已簽退</div>
              </td>
              <!-- <td>{{ rec.isCheckIn ? rec.checkin.checkin_type_text : '' }}</td> -->
            </tr>
          </table>

      </div>
        <!-- 分頁元件 -->
      <!-- <mat-paginator [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      [(pageIndex)]="currentPageIndex"
      (page)="changePage($event)"
      aria-label="Select page">
      </mat-paginator> -->

    </div>
    <div class="modal-footer justify-content-center py-4">
      <!-- <button type="button" class="btn btn-outline-cyan mr-3" style="width: 120px;"
          (click)="modalRef.hide()">關閉</button>
        <button type="button" class="btn btn-cyan" style="width: 120px;">儲存</button> -->
    </div>
  </div>
  </div>
