import { Injectable } from '@angular/core';
import { ActivityPeriod, ActivityRecord } from 'src/app/core/activity.service';

@Injectable({
  providedIn: 'root'
})
export class ActStateService {

  private currentAct: ActivityRecord;
  private period: ActivityPeriod;

  constructor() { }

  public setCurrentAct(act: ActivityRecord) {
    this.currentAct = act ;
  }
  public getCurrentAct() {
    return this.currentAct ;
  }

  public setPeriod(period: ActivityPeriod) {
    this.period = period ;
  }
  public getPeriod() {
    return this.period ;
  }
}
