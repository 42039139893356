import { UserService } from './../../../core/user.service';
import { TeacherImporter } from '../../../core/parser/teacher_importer';
import { ActivityRecord } from './../../../core/activity.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImdbService } from 'src/app/core/imdb.service';
import { SignupService } from 'src/app/core/signup.service';
import { ActStateService } from '../../act-state.service';


@Component({
  selector: 'app-import-teacher',
  templateUrl: './import-teacher.component.html',
  styleUrls: ['./import-teacher.component.scss']
})
export class ImportTeacherComponent implements OnInit {
  _jsonContent: any[] = [];
  matchedData = [];
  unmatchedData = [];

  @Input()
  set jsonContent(content: any[]) {
    // console.log('set jsonContent ....');
    this._jsonContent = content ;
    // console.log(this._jsonContent);
    this.compareContentWithIMDB();
  }

  @Output() afterParsed = new EventEmitter<any>();
  @Output() back = new EventEmitter<any>();


  constructor(
    private actStateService: ActStateService,
    private imdbService: ImdbService,
    private signupService: SignupService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  compareContentWithIMDB() {
    // console.log('compare Content with IMDB ....');
    const activity: ActivityRecord = this.actStateService.getCurrentAct();
    // console.log(activity);
    const importer = new TeacherImporter(this._jsonContent, activity.school_code, activity.school_type, this.imdbService, this.userService );
    importer.match().subscribe({
      next: (result) => {
        // console.log('after compare content with IMDB ...');
        // console.log(result);
        this.matchedData = result.matched_data ;
        this.unmatchedData = result.unmatched_data ;
        this.afterParsed.emit({ matchedData: this.matchedData, unmatchedData: this.unmatchedData});
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  async startImport() {
    const activity = this.actStateService.getCurrentAct();
    // const result = [];
    for(let i=0; i< this.matchedData.length; i++) {
      const tea = this.matchedData[i];
      try {
        const targetTea = tea.targetTea ;
        if (!targetTea) { throw {error: { error : ''}}; }
        await this.signupService.register(targetTea.Identity,
                                      activity.act_uuid ,
                                      tea.name,
                                      tea.type,
                                      undefined,
                                      targetTea.SchoolName,
                                      activity.school_code,
                                      activity.school_type,
                                      undefined ,
                                      undefined).toPromise();
        tea.isSaved = true ;
        tea.errMsg = '';
      } catch (error) {
        tea.isSaved = false ;
        tea.errMsg = error.error.error ;
      } finally {
        //
      }
      // result.push(tea);
    }
    // this.matchedData = result ;
  }

}
